import React from "react";
import Layout from "../../../../components/Layout/Layout";
import { Row, Col, Button } from "react-bootstrap";
import visa_601 from "../../../../images/visaImages/visa_601_1.png";

import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import "./EtaVisa_601.css";

const EtaVisa_601 = () => {
  return (
    <Layout
      title="Complete Guide to the Subclass 601 Electronic Travel Authority (ETA) Visa - Eligibility, Benefits, and Application Process"
      description="Learn about the Subclass 601 Electronic Travel Authority (ETA) Visa, including eligibility criteria, benefits, and the step-by-step application process. Discover how this visa allows you to visit Australia for tourism, business, or short-term study."
      keywords="Subclass 601 visa, Electronic Travel Authority visa, 601 visa eligibility, 601 visa benefits, 601 visa application process, visit Australia, ETA visa Australia"
    >
      <>
        <Row className="m-5">
          <Col lg={6} md={6} sm={12}>
            <img
              src={visa_601}
              alt="skilledindependent-visa-189"
              className="img-fluid subclass-189-image"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <h2 className="subclass-189-h1">
              Electronic Travel Authority Visa
              <span className="subclass-189-h1-span">(Subclass 601)</span>
            </h2>

            <p className="subclass-189-p">
              Are you planning to visit Australia for tourism, business, or to
              see family and friends?
              <a href="#" className="subclass-189-a">
                Start your journey by applying for your Visa 601 online today!
              </a>
              This visa, known as the Electronic Travel Authority (Subclass
              601), is designed for people who want to visit Australia
              temporarily. It offers them an opportunity to explore Australia,
              attend business meetings, or visit loved ones.
            </p>
            <Button href="/contact" className="mt-3 contact-us-call-button">
              Call Now
            </Button>
          </Col>
        </Row>

        {/* //tab section */}
        <div className="row m-5 ">
          <div className="col-12">
            <div className="choose-us-title-view">
              <h2 className="choose-us-title">
                Electronic Travel Authority (ETA) Visa
                <b className="span-text">(Subclass 601)</b> Overview
              </h2>
            </div>
            <p>
              The <b>Subclass 601 Electronic Travel Authority (ETA) Visa</b>
              allows eligible passport holders to visit Australia for tourism,
              business visitor activities, or short-term study. This visa is
              electronically linked to your passport, making it easy to travel
              to and from Australia.
            </p>
          </div>
          <div className="col-12 ">
            <ul className="nav nav-tabs custom-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active custom-nav-link"
                  id="success-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#success"
                  type="button"
                  role="tab"
                  aria-controls="success"
                  aria-selected="true"
                >
                  <i className="fas fa-clipboard-list fa-2x"></i>
                  <br />
                  Eligibility Criteria
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="permanent-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#permanent"
                  type="button"
                  role="tab"
                  aria-controls="permanent"
                  aria-selected="false"
                >
                  <i className="fas fa-check-circle fa-2x"></i>
                  <br />
                  Why Visa 601?
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="priority-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#priority"
                  type="button"
                  role="tab"
                  aria-controls="priority"
                  aria-selected="false"
                >
                  <i className="fas fa-gift fa-2x"></i>
                  <br />
                  Visa 601 benefits
                </button>
              </li>
            </ul>
            <div className="tab-content custom-tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active custom-tab-pane"
                id="success"
                role="tabpanel"
                aria-labelledby="success-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>Eligibility Criteria for the Subclass 601 Visa</h3>
                    <p>
                      To be eligible for the Subclass 601 visa, applicants must
                      meet the following criteria:
                    </p>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Eligible Passport Holder:</strong>
                          You must hold a passport from an eligible country.
                          Check the Department of Home Affairs website for the
                          list of eligible countries.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Genuine Visitor:</strong>
                          You must genuinely intend to visit Australia
                          temporarily for tourism, business visitor activities,
                          or short-term study.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Health and Character Requirements:</strong>
                          You must meet Australian health and character
                          requirements. This may include providing a health
                          declaration and undergoing health examinations if
                          necessary.
                        </li>
                        <li className="subclass-189-li">
                          <strong>
                            No Outstanding Debts to the Australian Government:
                          </strong>
                          You must have no outstanding debts to the Australian
                          Government or have arranged to repay any debts.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="permanent"
                role="tabpanel"
                aria-labelledby="permanent-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Why Choose the Subclass 601 Electronic Travel Authority
                      (ETA) Visa?
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>
                            Convenient and Quick Application Process:
                          </strong>
                          The ETA visa can be applied for online, offering a
                          fast and straightforward application process.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Multiple Entry Flexibility:</strong>
                          Allows multiple entries to Australia for up to 12
                          months, with each stay limited to three months.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Tourism and Business Activities:</strong>
                          Enables you to visit Australia for tourism, to visit
                          family and friends, or for business visitor activities
                          such as attending conferences and meetings.
                        </li>
                        <li className="subclass-189-li">
                          <strong>No Visa Label Required:</strong>
                          The visa is electronically linked to your passport,
                          eliminating the need for a physical visa label.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Short-Term Study:</strong>
                          Allows you to undertake short-term study or training
                          in Australia for up to three months.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="priority"
                role="tabpanel"
                aria-labelledby="priority-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Key Benefits of the Subclass 601 Electronic Travel
                      Authority (ETA) Visa
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Ease of Travel:</strong>
                          Simplifies the travel process with an electronically
                          linked visa, making it easy to enter and exit
                          Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Long Validity:</strong>
                          Valid for up to 12 months from the date of issue,
                          allowing multiple trips to Australia within this
                          period.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Short Processing Time:</strong>
                          Typically processed within minutes, enabling quick
                          travel arrangements.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Cost-Effective:</strong>
                          Generally has a lower application fee compared to
                          other visa types.
                        </li>
                        <li className="subclass-189-li">
                          <strong>
                            Flexibility for Tourists and Business Visitors:
                          </strong>
                          Suitable for both tourists and business visitors,
                          providing flexibility in travel purposes.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h3 className="subclass-189-pt-h1">Application Process</h3>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Verify that you meet all eligibility criteria, including
                  holding a passport from an eligible country.
                </li>
                <li className="subclass-189-li">
                  Passport and personal identification documents.
                </li>
                <li className="subclass-189-li">
                  If required, complete any health declarations or examinations.
                </li>
                <li className="subclass-189-li">
                  Most ETA applications are processed within minutes. You will
                  receive a notification via email once your application is
                  processed.
                </li>
                <li className="subclass-189-li">
                  Once approved, your ETA will be electronically linked to your
                  passport. You will receive a visa grant notification with
                  details about your visa conditions and validity.
                </li>
              </ul>
            </p>

            {/* .......point test............. */}
            <h3 className="subclass-189-pt-h1">Points Test</h3>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Age: 25-32 years (30 points), 18-24 & 33-39 years (25 points),
                  etc.
                </li>
                <li className="subclass-189-li">
                  English Language Ability: Superior (20 points), Proficient (10
                  points).
                </li>
                <li className="subclass-189-li">
                  Skilled Employment: In Australia or overseas, up to 20 points
                  depending on years of experience.
                </li>
                <li className="subclass-189-li">
                  Educational Qualifications: Doctorate (20 points),
                  Bachelor’s/Master’s (15 points),Diploma or trade
                  qualification: 10 points
                </li>
                <li className="subclass-189-li">
                  Other Factors: Australian study requirement, specialist
                  education qualification, accredited community language, etc.
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Related Visas</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="/visitor-visa-600"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 600 Visitor visa
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/evisitor-visa-651"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 651 eVisitor
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/skilled-graduate-485-visa"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Temporary Graduate visa subclass 485
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="/temporary-skills-shortage-visa-482"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Temporary Skill Shortage visa Subclass 482
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/skilled-employer-sponsored-regional-494-visa"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Employer Sponsored Regional (Provisional) visa 494
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>

        {/* ................visa qualify............ */}
        <Row lg={12} className="subclass-189-vq-row">
          <h3 className="mt-5">Do you think you qualify for this visa?</h3>
          <p>
            Contact us if you would like to know more about this visa or need
            immigration advice.
          </p>
          <div className="mb-5">
            <Button
              href="/contact"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Contact
            </Button>
            <Button
              href="/appointment"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Book A Consultation
            </Button>
            <Button
              href="/initial-assessment-consultation"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Our Prices
            </Button>
          </div>
        </Row>

        {/* <Row>
          <Col>
            <div className="row m-5">
              <h1 className="home-h1">Streams of the Subclass 600 Visa</h1>
              <div className="col-lg-6 col-md-12">
                <h5 className="subclass-189-pt-h1">Tourist Stream:</h5>
                <p className="subclass-189-p subclass-189-pt-col">
                  <ul>
                    <li className="subclass-189-li">
                      For people visiting Australia for holidays, recreation, or
                      to visit family and friends.
                    </li>
                    <li className="subclass-189-li">
                      Can be applied for from inside or outside Australia.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="col-lg-6 col-md-12">
                <h5 className="subclass-189-pt-h1">Business Visitor Stream:</h5>
                <p className="subclass-189-p subclass-189-pt-col">
                  <ul>
                    <li className="subclass-189-li">
                      For business people making short business visits for
                      reasons such as attending a conference, negotiating, or
                      exploring business opportunities.
                    </li>
                    <li className="subclass-189-li">
                      Must be applied for from outside Australia.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="col-lg-6 col-md-12">
                <h5 className="subclass-189-pt-h1">Sponsored Family Stream:</h5>
                <p className="subclass-189-p subclass-189-pt-col">
                  <ul>
                    <li className="subclass-189-li">
                      For people visiting family members in Australia. A family
                      member in Australia must sponsor you and provide a bond.
                    </li>
                    <li className="subclass-189-li">
                      Must be applied for from outside Australia.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="col-lg-6 col-md-12">
                <h5 className="subclass-189-pt-h1">
                  Approved Destination Status (ADS) Stream:
                </h5>
                <p className="subclass-189-p subclass-189-pt-col">
                  <ul>
                    <li className="subclass-189-li">
                      For people from the People’s Republic of China traveling
                      in an organized tour group.
                    </li>
                    <li className="subclass-189-li">
                      Must be applied for from outside Australia.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </Col>
        </Row> */}

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h5 className="subclass-189-pt-h1">
              Conditions and Obligations for Subclass 601 Electronic Travel
              Authority (ETA) Visa
            </h5>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Purpose of Visit:</strong>
                  The Subclass 601 Visa is intended for short-term stays for
                  tourism, business, or visiting family and friends.
                </li>
                <li className="subclass-189-li">
                  <strong>Length of Stay:</strong>
                  Typically allows for multiple entries into Australia for up to
                  12 months, with a maximum stay of 3 months per visit.
                </li>
                <li className="subclass-189-li">
                  <strong>Work Restrictions: </strong>
                  Work is not permitted under the ETA. However, business visitor
                  activities such as attending conferences, making business
                  inquiries, or negotiating contracts are allowed.
                </li>
                <li className="subclass-189-li">
                  <strong>Study Limitations: </strong>
                  You can study or train for up to 3 months in total while on
                  this visa.
                </li>
                <li className="subclass-189-li">
                  <strong>Health Insurance:</strong>
                  It's recommended to have adequate health insurance to cover
                  any medical costs during your stay, as you won't be covered by
                  Australia's national health scheme.
                </li>
                <li className="subclass-189-li">
                  <strong>No Paid Employment:</strong>
                  Engaging in paid employment or services is strictly prohibited
                  under this visa.
                </li>
                <li className="subclass-189-li">
                  <strong>Passport Validity:</strong>
                  Ensure your passport is valid for the duration of your stay,
                  as the visa is linked to your passport.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">
              Express Your Interest in the Subclass 601 Electronic Travel
              Authority (ETA) Visa
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Purpose of Visit:</strong>
                  Identify whether your visit is for tourism, business, or
                  visiting family and friends.
                </li>
                <li className="subclass-189-li">
                  <strong>Complete Application Form:</strong>
                  Fill out the necessary forms with detailed travel plans and
                  financial information.
                </li>
                <li className="subclass-189-li">
                  <strong>Documentation:</strong>
                  Gather and submit all required documents, ensuring they are
                  accurate and up-to-date.
                </li>
                <li className="subclass-189-li">
                  <strong>Submit Application:</strong>
                  Send your completed application for review.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">
              English Language Requirements
            </h3>
            <p>
              To meet the English language requirement, you must demonstrate
              competent English. This can be done through:
            </p>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>IELTS:</strong>A minimum score of 6 in each component
                  (Listening, Reading, Writing, and Speaking).
                </li>
                <li className="subclass-189-li">
                  <strong>TOEFL iBT:</strong>A minimum score of 12 in Listening,
                  13 in Reading, 21 in Writing, and 18 in Speaking.
                </li>
                <li className="subclass-189-li">
                  <strong>PTE Academic:</strong>A minimum score of 50 in each
                  component (Listening, Reading, Writing, and Speaking).
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Google Adds</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>

            <div className="subclass-189-rv-card-shadow mt-5">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Latest Post</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </>
      {/* faqs section */}
      <div className="row testimonial-background visa_question-acc">
        <div className="col-lg-12 cl-sm-12">
          <div className="testimonial-heading">
            {/* <h1 className="home-h1">Success Stories</h1> */}
            <h4 className="about-us-h4">
              Frequently Asked Questions (FAQs) about the Subclass 601
              Electronic Travel Authority (ETA) Visa
            </h4>
          </div>

          <>
            <Row>
              <Col lg={12} className="">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      How long can I stay in Australia on a Subclass 601 Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      The Subclass 601 Visa allows you to stay in Australia for
                      up to three months on each visit within a 12-month period.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Can I work in Australia on a Subclass 601 Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, the Subclass 601 Visa does not permit you to work in
                      Australia. It is intended for tourism, business visitor
                      activities, or short-term study only.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Can I study in Australia on a Subclass 601 Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can undertake short-term study or training in
                      Australia for up to three months.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What if my ETA application is not approved?
                    </Accordion.Header>
                    <Accordion.Body>
                      If your ETA application is not approved, you may need to
                      apply for another type of visitor visa. Check the
                      Department of Home Affairs website for other visa options.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How do I check the status of my ETA application?
                    </Accordion.Header>
                    <Accordion.Body>
                      You can check the status of your ETA application through
                      the email confirmation you receive or by contacting the
                      Department of Home Affairs.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Can I extend my stay in Australia on a Subclass 601 Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, the Subclass 601 Visa cannot be extended. If you wish
                      to stay longer, you must apply for another visa before
                      your ETA expires.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Do I need a visa label for my Subclass 601 Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, the Subclass 601 Visa is electronically linked to your
                      passport, so no physical visa label is required.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      Can I reapply for the ETA if it expires?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can reapply for the ETA if it expires, provided
                      you continue to meet the eligibility criteria.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      Is the Subclass 601 Visa suitable for attending
                      conferences or business meetings in Australia?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, the Subclass 601 Visa allows you to attend
                      conferences, business meetings, and other business visitor
                      activities in Australia.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      What should I do if I get a new passport after my ETA is
                      granted?
                    </Accordion.Header>
                    <Accordion.Body>
                      If you get a new passport, you will need to apply for a
                      new ETA, as it is linked to your passport number.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="10">
                    <Accordion.Header>
                      What happens if I overstay my ETA Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Overstaying your ETA Visa can result in penalties,
                      including a potential ban on re-entry to Australia and
                      other legal consequences.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </>
        </div>
      </div>
    </Layout>
  );
};

export default EtaVisa_601;
