import React from "react";
import Layout from "../../../../components/Layout/Layout";
import { Row, Col, Button } from "react-bootstrap";
import visa_407 from "../../../../images/visaImages/visa_407_1.png";

import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import "./TrainingVisa_407.css";

const TrainingVisa_407 = () => {
  return (
    <Layout
      title=" Comprehensive Guide to the Australia Training Visa (Subclass 407)"
      description="Learn all about the Australia Training Visa (Subclass 407), including eligibility, benefits, application process, and frequently asked questions. Your ultimate guide to training in Australia."
      keywords="Australia Training Visa, Training Visa Subclass 407, Australia training visa, subclass 407, training in Australia"
    >
      <>
        <Row className="m-5">
          <Col lg={6} md={6} sm={12}>
            <img
              src={visa_407}
              alt="skilledindependent-visa-189"
              className="img-fluid subclass-189-image"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <h2 className="subclass-189-h1">
              Training Visa
              <span className="subclass-189-h1-span">(Subclass 407)</span>
            </h2>

            <p className="subclass-189-p">
              Are you planning to travel to Australia for skill enhancement and
              professional development?
              <a href="#" className="subclass-189-a">
                Start your journey by applying for your Visa 407 online today!
              </a>
              Take the first step towards your career advancement by applying
              for your Visa 407 online today! This visa, known as the Training
              Visa (Subclass 407), is designed for individuals seeking to
              enhance their skills and gain practical experience in Australia.
              Whether you're improving your occupational expertise, engaging in
              professional development activities, or building a global network
              of peers and professionals, this visa offers a valuable and
              enriching experience in Australia.
            </p>
            <Button href="/contact" className="mt-3 contact-us-call-button">
              Call Now
            </Button>
          </Col>
        </Row>

        {/* //tab section */}
        <div className="row m-5 ">
          <div className="col-12">
            <div className="choose-us-title-view">
              <h2 className="choose-us-title">
                Australia Training Visa
                <b className="span-text">(Subclass 407)</b> Overview
              </h2>
            </div>
            <p>
              The Australia Training Visa (Subclass 407) allows individuals to
              participate in workplace-based training programs to improve their
              skills for their job, area of tertiary study, field of expertise,
              or in a professional development training program in Australia.
              This visa is designed to enhance the applicant's skills and
              experience while providing opportunities to gain valuable
              knowledge in their chosen field.
            </p>
          </div>
          <div className="col-12 ">
            <ul className="nav nav-tabs custom-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active custom-nav-link"
                  id="success-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#success"
                  type="button"
                  role="tab"
                  aria-controls="success"
                  aria-selected="true"
                >
                  <i className="fas fa-clipboard-list fa-2x"></i>
                  <br />
                  Eligibility Criteria
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="permanent-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#permanent"
                  type="button"
                  role="tab"
                  aria-controls="permanent"
                  aria-selected="false"
                >
                  <i className="fas fa-check-circle fa-2x"></i>
                  <br />
                  Why Visa 407?
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="priority-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#priority"
                  type="button"
                  role="tab"
                  aria-controls="priority"
                  aria-selected="false"
                >
                  <i className="fas fa-gift fa-2x"></i>
                  <br />
                  Visa 407 benefits
                </button>
              </li>
            </ul>
            <div className="tab-content custom-tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active custom-tab-pane"
                id="success"
                role="tabpanel"
                aria-labelledby="success-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Eligibility for the Australia Training Visa (Subclass 407)
                    </h3>
                    <p>
                      To qualify for the Australia Visa 407, applicants must
                      meet the following criteria:
                    </p>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Sponsorship:</strong>
                          You must be sponsored by an approved temporary
                          activities sponsor.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Nomination:</strong>
                          You must be nominated (unless your sponsor is a
                          Commonwealth Government agency).
                        </li>
                        <li className="subclass-189-li">
                          <strong>Purpose of Training:</strong>
                          You must demonstrate that the training is directly
                          related to your occupation, tertiary study, or
                          professional development.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Financial Capacity:</strong>
                          You need to show proof of sufficient funds to cover
                          living expenses, travel costs, and other associated
                          costs.
                        </li>
                        <li className="subclass-189-li">
                          <strong>English Proficiency:</strong>
                          Evidence of English language proficiency through tests
                          like IELTS, TOEFL, or PTE.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Health and Character Requirements:</strong>
                          You must meet specific health and character
                          requirements, including obtaining health insurance.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="permanent"
                role="tabpanel"
                aria-labelledby="permanent-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Why Choose the Australia Training Visa (Subclass 407)?
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Skill Enhancement: </strong>
                          Participate in training programs that improve your
                          occupational skills.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Work Experience:</strong>
                          Gain practical work experience in a real-world
                          environment.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Professional Development:</strong>
                          Engage in professional development activities to
                          advance your career.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Global Networking:</strong>
                          Build a global network of peers and professionals in
                          your field.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Quality of Life:</strong>
                          Enjoy Australia's high standard of living and
                          beautiful landscapes.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Cultural Exchange:</strong>
                          Experience Australia's rich and diverse culture.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Career Advancement: </strong>
                          Enhance your resume with international training and
                          experience.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="priority"
                role="tabpanel"
                aria-labelledby="priority-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Benefits of the Australia Training Visa (Subclass 407)
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Access to Specialized Training:</strong>
                          Participate in workplace-based training programs
                          tailored to your needs.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Work While Training:</strong>
                          Gain practical experience and earn money to support
                          your stay.
                        </li>
                        <li className="subclass-189-li">
                          <strong>
                            Professional Development Opportunities:
                          </strong>
                          Engage in activities that enhance your professional
                          skills and knowledge.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Family Inclusion:</strong>
                          Bring your family members to Australia on your
                          training visa.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Health Insurance:</strong>
                          Access to Australia's healthcare system through
                          Overseas Visitor Health Cover (OVHC).
                        </li>
                        <li className="subclass-189-li">
                          <strong>Career Growth: </strong>
                          Improve your employment prospects with international
                          training experience.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Networking Opportunities:</strong>
                          Build a global network of peers and professionals.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h3 className="subclass-189-pt-h1">Application Process</h3>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Ensure you have an approved temporary activities sponsor in
                  Australia.
                </li>
                <li className="subclass-189-li">
                  our sponsor must submit a nomination for your training
                  position.
                </li>
                <li className="subclass-189-li">
                  Fill out the training visa application form accurately.
                </li>
                <li className="subclass-189-li">
                  Prepare documents such as nomination approval, passport, proof
                  of funds, health insurance, and English proficiency test
                  results.
                </li>
                <li className="subclass-189-li">
                  Submit the required visa application fee.
                </li>
                <li className="subclass-189-li">
                  Upload all necessary documents and submit your application
                  online.
                </li>
              </ul>
            </p>

            {/* .......point test............. */}
            <h3 className="subclass-189-pt-h1">
              Points Test for Australia Visa 500
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              {/* <ul>
                                <li className="subclass-189-li">
                                  Age: 25-32 years (30 points), 18-24 & 33-39 years (25 points),
                                  etc.
                                </li>
                                <li className="subclass-189-li">
                                  English Language Ability: Superior (20 points), Proficient (10
                                  points).
                                </li>
                                <li className="subclass-189-li">
                                  Skilled Employment: In Australia or overseas, up to 20 points
                                  depending on years of experience.
                                </li>
                                <li className="subclass-189-li">
                                  Educational Qualifications: Doctorate (20 points),
                                  Bachelor’s/Master’s (15 points),Diploma or trade
                                  qualification: 10 points
                                </li>
                                <li className="subclass-189-li">
                                  Other Factors: Australian study requirement, specialist
                                  education qualification, accredited community language, etc.
                                </li>
                              </ul> */}
              The Australia Training Visa (Subclass 407) does not involve a
              points test. Unlike other visas, it is not based on a points-based
              system but rather on meeting specific eligibility criteria and
              requirements.
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Related Visas</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="/australia-student-visa-500"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Student Visa Subclass 500
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="/visitor-visa-600"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 600 Visitor visa
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/electronic-travel-authority-visa-601"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 601 Electronic Travel Authority
                    </a>
                  </li>

                  {/* <li className="subclass-189-li-a">
                                    <a
                                      href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/temporary-skill-shortage-482"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Temporary Skill Shortage visa Subclass 482
                                    </a>
                                  </li>
                
                                  <li className="subclass-189-li-a">
                                    <a
                                      href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/skilled-employer-sponsored-regional-494"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Skilled Employer Sponsored Regional (Provisional) visa 494
                                    </a>
                                  </li> */}
                </ul>
              </p>
            </div>
          </Col>
        </Row>

        {/* ................visa qualify............ */}
        <Row lg={12} className="subclass-189-vq-row">
          <h3 className="mt-5">Do you think you qualify for this visa?</h3>
          <p>
            Contact us if you would like to know more about this visa or need
            immigration advice.
          </p>
          <div className="mb-5">
            <Button
              href="/contact
"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Contact
            </Button>
            <Button
              href="/appointment"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Book A Consultation
            </Button>
            <Button
              href="/initial-assessment-consultation"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Our Prices
            </Button>
          </div>
        </Row>

        {/* <Row>
                              <Col>
                                <div className="row m-5">
                                  <h1 className="home-h1">Streams of the Subclass 600 Visa</h1>
                                  <div className="col-lg-6 col-md-12">
                                    <h5 className="subclass-189-pt-h1">Tourist Stream:</h5>
                                    <p className="subclass-189-p subclass-189-pt-col">
                                      <ul>
                                        <li className="subclass-189-li">
                                          For people visiting Australia for holidays, recreation, or
                                          to visit family and friends.
                                        </li>
                                        <li className="subclass-189-li">
                                          Can be applied for from inside or outside Australia.
                                        </li>
                                      </ul>
                                    </p>
                                  </div>
                                  <div className="col-lg-6 col-md-12">
                                    <h5 className="subclass-189-pt-h1">Business Visitor Stream:</h5>
                                    <p className="subclass-189-p subclass-189-pt-col">
                                      <ul>
                                        <li className="subclass-189-li">
                                          For business people making short business visits for
                                          reasons such as attending a conference, negotiating, or
                                          exploring business opportunities.
                                        </li>
                                        <li className="subclass-189-li">
                                          Must be applied for from outside Australia.
                                        </li>
                                      </ul>
                                    </p>
                                  </div>
                                  <div className="col-lg-6 col-md-12">
                                    <h5 className="subclass-189-pt-h1">Sponsored Family Stream:</h5>
                                    <p className="subclass-189-p subclass-189-pt-col">
                                      <ul>
                                        <li className="subclass-189-li">
                                          For people visiting family members in Australia. A family
                                          member in Australia must sponsor you and provide a bond.
                                        </li>
                                        <li className="subclass-189-li">
                                          Must be applied for from outside Australia.
                                        </li>
                                      </ul>
                                    </p>
                                  </div>
                                  <div className="col-lg-6 col-md-12">
                                    <h5 className="subclass-189-pt-h1">
                                      Approved Destination Status (ADS) Stream:
                                    </h5>
                                    <p className="subclass-189-p subclass-189-pt-col">
                                      <ul>
                                        <li className="subclass-189-li">
                                          For people from the People’s Republic of China traveling
                                          in an organized tour group.
                                        </li>
                                        <li className="subclass-189-li">
                                          Must be applied for from outside Australia.
                                        </li>
                                      </ul>
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            </Row> */}

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h5 className="subclass-189-pt-h1">
              Conditions and Obligations of the Australia Training Visa
              (Subclass 407)
            </h5>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Training Participation:</strong>
                  Maintain satisfactory attendance and participation in your
                  training program.
                </li>
                <li className="subclass-189-li">
                  <strong>Work Limitations:</strong>
                  Adhere to the work conditions specified in your visa grant.
                </li>
                <li className="subclass-189-li">
                  <strong>Health Insurance: </strong>
                  Maintain adequate Overseas Visitor Health Cover (OVHC) for the
                  duration of your stay.
                </li>
                <li className="subclass-189-li">
                  <strong>Leave Australia Before Visa Expiry:</strong>
                  Ensure you leave Australia before your visa expires unless you
                  apply for another visa.
                </li>
                <li className="subclass-189-li">
                  <strong>Change of Address:</strong>
                  Inform your sponsor and the Department of Home Affairs of your
                  residential address within seven days of arriving in Australia
                  and any changes thereafter.
                </li>
                <li className="subclass-189-li">
                  <strong>Obey Australian Laws:</strong>
                  Comply with all Australian laws and visa conditions.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">
              Express Your Interest in the Australia Training Visa (Subclass
              407)
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              {/* <ul>
                    <li className="subclass-189-li">
                      <strong>Research: </strong>
                      Thoroughly research the visa requirements and benefits.
                    </li>
                    <li className="subclass-189-li">
                      <strong>Preparation:</strong>
                      Prepare all necessary documents and financial proofs.
                    </li>
                    <li className="subclass-189-li">
                      <strong>Application:</strong>
                      Complete and submit the online application.
                    </li>
                    <li className="subclass-189-li">
                      <strong>Follow-up:</strong>
                      Monitor your application status through ImmiAccount.
                    </li>
                    <li className="subclass-189-li">
                      <strong>Visa Grant:</strong>
                      Upon approval, make travel and accommodation arrangements.
                    </li>
                  </ul> */}
              If you are interested in applying for the Australia Training Visa
              (Subclass 407), start by finding an approved temporary activities
              sponsor in Australia. Ensure you meet all eligibility requirements
              and gather the necessary documentation. Express your interest by
              creating an ImmiAccount and submitting your application online
              through the Australian Government's immigration website.
            </p>

            <h3 className="subclass-189-pt-h1">
              English Language Requirements
            </h3>
            <p>
              To meet the English language requirement, you must demonstrate
              competent English. This can be done through:
            </p>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>IELTS:</strong>A minimum score of 6 in each component
                  (Listening, Reading, Writing, and Speaking).
                </li>
                <li className="subclass-189-li">
                  <strong>TOEFL iBT:</strong>A minimum score of 12 in Listening,
                  13 in Reading, 21 in Writing, and 18 in Speaking.
                </li>
                <li className="subclass-189-li">
                  <strong>PTE Academic:</strong>A minimum score of 50 in each
                  component (Listening, Reading, Writing, and Speaking).
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Google Adds</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>

            <div className="subclass-189-rv-card-shadow mt-5">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Latest Post</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </>
      {/* faqs section */}
      <div className="row testimonial-background visa_question-acc">
        <div className="col-lg-12 cl-sm-12">
          <div className="testimonial-heading">
            {/* <h1 className="home-h1">Success Stories</h1> */}
            <h4 className="about-us-h4">
              Frequently Asked Questions (FAQs) about the Australia Training
              Visa (Subclass 407)
            </h4>
          </div>

          <>
            <Row>
              <Col lg={12} className="">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      What is the Australia Training Visa (Subclass 407)?
                    </Accordion.Header>
                    <Accordion.Body>
                      The Australia Training Visa (Subclass 407) allows
                      individuals to participate in workplace-based training
                      programs to enhance their skills in Australia.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Who is eligible for the Australia Training Visa (Subclass
                      407)?
                    </Accordion.Header>
                    <Accordion.Body>
                      Individuals who have an approved temporary activities
                      sponsor, meet the nomination requirement, and have
                      sufficient funds and health insurance, among other
                      criteria.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      How long can I stay in Australia on a Training Visa
                      (Subclass 407)?
                    </Accordion.Header>
                    <Accordion.Body>
                      The duration of stay depends on the length of your
                      training program and the approval of your visa.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can I work while on a Training Visa (Subclass 407)?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can work in the position specified in your
                      nomination.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Do I need health insurance for a Training Visa (Subclass
                      407)?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you must have Overseas Visitor Health Cover (OVHC)
                      for the entire duration of your stay in Australia.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Can I bring my family members on a Training Visa (Subclass
                      407)?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can include your family members in your training
                      visa application.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      What are the English language requirements for a Training
                      Visa (Subclass 407)?
                    </Accordion.Header>
                    <Accordion.Body>
                      You need to provide evidence of English proficiency
                      through tests like IELTS, TOEFL, or PTE.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      What is the role of the sponsor for a Training Visa
                      (Subclass 407)?
                    </Accordion.Header>
                    <Accordion.Body>
                      The sponsor must be an approved temporary activities
                      sponsor and must nominate you for the training position.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      How do I apply for the Australia Training Visa (Subclass
                      407)?
                    </Accordion.Header>
                    <Accordion.Body>
                      Apply online through the Australian Government's
                      immigration website by creating an ImmiAccount, completing
                      the application form, and submitting the required
                      documents and fee.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      What happens if my visa application is refused?
                    </Accordion.Header>
                    <Accordion.Body>
                      If your visa application is refused, you will receive a
                      letter explaining the reasons for the refusal and
                      information on your review rights.
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* <Accordion.Item eventKey="10">
                                    <Accordion.Header>
                                      What happens if I overstay my ETA Visa?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      Overstaying your ETA Visa can result in penalties,
                                      including a potential ban on re-entry to Australia and
                                      other legal consequences.
                                    </Accordion.Body>
                                  </Accordion.Item> */}
                </Accordion>
              </Col>
            </Row>
          </>
        </div>
      </div>
    </Layout>
  );
};

export default TrainingVisa_407;
