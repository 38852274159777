import AdminLayout from "../../../../components/AdminLayout/AdminLayout";
import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Form, Button, Container, Alert, Modal } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Calendar from "react-calendar";
import Table from "react-bootstrap/Table";
import "react-calendar/dist/Calendar.css";
import { AgentContext } from "../AgentContext";
import { useNavigate } from "react-router-dom";

const AddService = () => {
  const navigate = useNavigate();
  const { agentData } = useContext(AgentContext);
  const [formData, setFormData] = useState({
    agentId: agentData._id || "",
    agentName: agentData.agentName || "",
    visaType: "",
    timeLimit: "",
    price: "",
    details: "",
    dateTimeSlots: [],
  });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [interval, setInterval] = useState("");
  const [dateTimeSlots, setDateTimeSlots] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showChangeIntervalModal, setShowChangeIntervalModal] = useState(false);
  const [showAddMoreServiceModal, setShowAddMoreServiceModal] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [usedIntervals, setUsedIntervals] = useState([]);
  const timeIntervals = [15, 30, 45, 60].filter(
    (interval) => !usedIntervals.includes(interval)
  );

  useEffect(() => {
    if (interval) generateTimeSlots(interval);
  }, [interval, selectedDate]);

  const onDateChange = (date) => {
    setSelectedDate(date);
    setSelectedTimes([]);
    if (interval) generateTimeSlots(interval);
  };

  const generateTimeSlots = (interval) => {
    const slots = [];
    let startTime = new Date(selectedDate);
    startTime.setHours(9, 0, 0, 0);
    let endTime = new Date(selectedDate);
    endTime.setHours(17, 0, 0, 0);

    while (startTime < endTime) {
      let slotStart = startTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      startTime.setMinutes(startTime.getMinutes() + interval);
      let slotEnd = startTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      slots.push(`${slotStart} - ${slotEnd}`);
    }

    setAvailableTimes(slots);
  };

  const handleIntervalChange = (e) => {
    const newInterval = parseInt(e.target.value, 10);
    if (dateTimeSlots.length > 0 && interval !== newInterval) {
      setInterval(newInterval);
      setShowChangeIntervalModal(true);
    } else {
      setInterval(newInterval);
      setFormData({ ...formData, timeLimit: newInterval });
      if (newInterval) generateTimeSlots(newInterval);
    }
  };

  const confirmChangeInterval = () => {
    setShowChangeIntervalModal(false);
    setDateTimeSlots([]);
    setFormData({ ...formData, dateTimeSlots: [] });
    setSelectedTimes([]);
  };

  const cancelChangeInterval = () => {
    setShowChangeIntervalModal(false);
    setInterval(formData.timeLimit);
  };

  const handleTimeClick = (time) => {
    setSelectedTimes((prev) =>
      prev.includes(time) ? prev.filter((t) => t !== time) : [...prev, time]
    );
  };

  const handleAddTimeSlots = () => {
    if (selectedTimes.length === 0) return;
    const existingSlotIndex = dateTimeSlots.findIndex(
      (slot) => slot.date.toDateString() === selectedDate.toDateString()
    );
    let updatedSlots = [...dateTimeSlots];

    if (existingSlotIndex !== -1) {
      updatedSlots[existingSlotIndex] = {
        date: selectedDate,
        times: selectedTimes,
      };
    } else {
      updatedSlots.push({ date: selectedDate, times: selectedTimes });
    }

    setDateTimeSlots(updatedSlots);
    setFormData((prev) => ({
      ...prev,
      dateTimeSlots: updatedSlots,
    }));
    setSelectedTimes([]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      !formData.visaType ||
      !formData.timeLimit ||
      !formData.price ||
      !formData.details ||
      dateTimeSlots.length === 0
    ) {
      setMessage("All fields are required.");
      setError(true);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}service/addService`,
        {
          ...formData,
          dateTimeSlots: dateTimeSlots,
        }
      );

      if (response.data.success) {
        setMessage("Service added successfully!");
        setError(false);
        setUsedIntervals((prev) => [...prev, formData.timeLimit]);
        setShowAddMoreServiceModal(true);
      } else {
        setMessage(response.data.msg);
        setError(true);
      }
    } catch (error) {
      console.error("Error adding service:", error);
      setMessage(
        error.response ? error.response.data.msg : "An unknown error occurred."
      );
      setError(true);
    }
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleAddMoreService = () => {
    setShowAddMoreServiceModal(false);
    resetForm();
  };
  const handleNoMoreService = () => {
    setShowAddMoreServiceModal(false);
    resetForm();
    navigate("/dashboard/admin");
  };

  function resetForm() {
    setFormData({
      agentId: agentData._id || "",
      agentName: agentData.agentName || "",
      visaType: "",
      timeLimit: "",
      price: "",
      details: "",
      dateTimeSlots: [],
    });
    setSelectedTimes([]);
    setAvailableTimes([]);
    setDateTimeSlots([]);
    setInterval("");
  }

  return (
    <AdminLayout>
      <Container>
        <h1>Add Service for {formData.agentName}</h1>
        <Row>
          <Col md={6}>
            {message && (
              <Alert variant={error ? "danger" : "success"}>{message}</Alert>
            )}
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formAgentName">
                <Form.Label>Agent Name</Form.Label>
                <Form.Control type="text" value={formData.agentName} readOnly />
              </Form.Group>

              <Form.Group controlId="formVisaType">
                <Form.Label>Visa Type</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Visa Type"
                  value={formData.visaType}
                  onChange={(e) =>
                    setFormData({ ...formData, visaType: e.target.value })
                  }
                  required
                />
              </Form.Group>

              <Form.Group controlId="formTimeLimit">
                <Form.Label>Time Limit</Form.Label>
                <Form.Control
                  as="select"
                  onChange={handleIntervalChange}
                  value={interval}
                >
                  <option value="">Select Interval</option>
                  {timeIntervals.map((int, index) => (
                    <option key={index} value={int}>
                      {int} minutes
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="formPrice">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Price"
                  value={formData.price}
                  onChange={(e) =>
                    setFormData({ ...formData, price: e.target.value })
                  }
                  required
                />
              </Form.Group>

              <Form.Group controlId="formDetails">
                <Form.Label>Details</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter Details"
                  value={formData.details}
                  onChange={(e) =>
                    setFormData({ ...formData, details: e.target.value })
                  }
                  required
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Add Service
              </Button>
            </Form>
          </Col>

          <Col md={6}>
            <div
              style={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }}
            >
              <Calendar onChange={onDateChange} value={selectedDate} />
              <Form.Group className="mb-3">
                <Form.Label>Select Time Interval</Form.Label>
                <Form.Control
                  as="select"
                  onChange={handleIntervalChange}
                  value={interval}
                >
                  {timeIntervals.map((int, index) => (
                    <option key={index} value={int}>
                      {int} minutes
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <div style={{ marginTop: "20px" }}>
                <h3>Available Times on {selectedDate.toDateString()}:</h3>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {availableTimes.map((time, index) => (
                    <Button
                      key={index}
                      style={{
                        margin: "5px",
                        backgroundColor: selectedTimes.includes(time)
                          ? "green"
                          : "#007bff",
                        borderColor: selectedTimes.includes(time)
                          ? "green"
                          : "#007bff",
                      }}
                      onClick={() => handleTimeClick(time)}
                    >
                      {time}
                    </Button>
                  ))}
                </div>
                {selectedTimes.length > 0 && (
                  <Button
                    style={{ marginTop: "20px" }}
                    onClick={handleAddTimeSlots}
                  >
                    Add Time Slots
                  </Button>
                )}
              </div>
              <Button
                variant="primary"
                style={{ marginTop: "20px" }}
                onClick={handleShowModal}
              >
                View Selected Dates and Times
              </Button>
            </div>
          </Col>
        </Row>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Selected Dates and Times</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Times</th>
                </tr>
              </thead>
              <tbody>
                {dateTimeSlots.map((slot, index) => (
                  <tr key={index}>
                    <td>{slot.date.toDateString()}</td>
                    <td>{slot.times.join(", ")}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showChangeIntervalModal} onHide={cancelChangeInterval}>
          <Modal.Header closeButton>
            <Modal.Title>Change Time Interval</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            You have already selected time slots for the current time interval.
            Changing the interval will remove all selected slots. Do you want to
            proceed?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={cancelChangeInterval}>
              No
            </Button>
            <Button variant="primary" onClick={confirmChangeInterval}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showAddMoreServiceModal}
          onHide={() => setShowAddMoreServiceModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add More Services</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            The service has been added successfully. Do you want to add another
            service with a different time limit?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleNoMoreService}>
              No
            </Button>
            <Button variant="primary" onClick={handleAddMoreService}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </AdminLayout>
  );
};

export default AddService;
