import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layout/Layout";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const UserViewBlog = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const { id } = useParams();

  useEffect(() => {
    fetchBlogData(id);
  }, [id]);

  const fetchBlogData = async (blogId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}user/view-blog/${blogId}`
      );
      const blogData = response.data.blog[0];
      setTitle(blogData.title || "");
      setDescription(blogData.description || "");
      setImage(blogData.img || null);
    } catch (err) {
      console.error("Error fetching blog:", err);
      setErrorMessage("Failed to fetch blog. Please try again later.");
    }
  };

  const formatDescription = (desc) => {
    if (!desc) return [];
    const words = desc.split(" ");
    const lines = [];
    for (let i = 0; i < words.length; i += 15) {
      lines.push(words.slice(i, i + 15).join(" "));
    }
    return lines;
  };

  return (
    <Layout>
      <Row className="m-5">
        <Col lg={6} md={6} sm={12}>
          <h1 className="contact-us-heading">Read Your Informative Blog</h1>
          <p>
            Discover expert guidance for your Australian visa journey with our
            trusted Migration Consultants. Read our blog for insights and book
            your appointment today to simplify your migration process.
          </p>
          <div className="contact-us-email-container">
            <span>Email us at:</span>
            <a href="mailto:info@realmigration.com.au" className="email-link">
              info@realmigration.com.au
            </a>
          </div>
          <div>
            <b>OR</b>
          </div>
          <Button className="mt-3 contact-us-call-button">Call Now</Button>
        </Col>
        <Col lg={6} md={6} sm={12} className="contact-us-image-col">
          <img
            src={`${process.env.PUBLIC_URL}/uploads/${image}`}
            alt="blog"
            className="img-fluid contact-us-image"
          />
        </Col>
      </Row>

      <Row className="m-5">
        <Col lg={9} md={8} sm={12}>
          <h1>{title}</h1>
          {formatDescription(description).map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </Col>
        {/* ..........related visa card ............... */}
        <Col lg={3} md={4} sm={12}>
          <div className="subclass-189-rv-card-shadow">
            <div className="subclass-189-rv-card-head">
              <h5 className="subclass-189-rv-card-h">Google Ads</h5>
            </div>
            <p className="subclass-189-rv-card-p">
              <ul>
                <li className="subclass-189-li-a">
                  <NavLink
                    to="/skilled-nominated-visa-190"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Skilled Nominated (Subclass 190)
                  </NavLink>
                </li>
                <li className="subclass-189-li-a">
                  <NavLink
                    to="/skilled-work-visa-491"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Skilled Work Regional (Subclass 491)
                  </NavLink>
                </li>
                <li className="subclass-189-li-a">
                  <NavLink
                    to="/permanent-residence-visa-191"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Permanent Residence Regional (Subclass 191)
                  </NavLink>
                </li>
              </ul>
            </p>
          </div>
        </Col>
      </Row>
      <Row lg={12} className="subclass-189-vq-row">
        <h3 className="mt-5">
          Do you still have questions about your eligibility? Contact us for a
          personalized consultation!
        </h3>
        <p>
          Contact us if you would like to know more about the education or need
          immigration advice.
        </p>
        <div className="mb-5">
          <Button
            href="/contact"
            className="subclass-189-vq-button  subclass-189-vq-btn-margin"
          >
            Contact
          </Button>
          <Button
            href="/appointment"
            className="subclass-189-vq-button  subclass-189-vq-btn-margin"
          >
            Book A Consultation
          </Button>
          <Button
            href="/initial-assessment-consultation"
            className="subclass-189-vq-button  subclass-189-vq-btn-margin"
          >
            Our Prices
          </Button>
        </div>
      </Row>
    </Layout>
  );
};

export default UserViewBlog;
