import React from "react";
import Layout from "../../../../components/Layout/Layout";
import { Row, Col, Button } from "react-bootstrap";
import visa_103 from "../../../../images/visaImages/Visa_103_1.png";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import "./ParentVisa_103.css";

const ParentVisa_103 = () => {
  return (
    <Layout
      title="Australia Prospective Marriage Visa (Subclass 300) - Eligibility, Benefits, and Application Process"
      description="Discover everything you need to know about the Australia Prospective Marriage Visa (Subclass 300). Learn about eligibility criteria, benefits, application process, conditions, and FAQs."
      keywords="Australia Prospective Marriage Visa, Subclass 300, prospective spouse visa, marriage visa benefits, visa application process, Australian partner visa conditions, immigration to Australia"
    >
      <>
        <Row className="m-5">
          <Col lg={6} md={6} sm={12}>
            <img
              src={visa_103}
              alt="skilledindependent-visa-189"
              className="img-fluid subclass-189-image"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <h2 className="subclass-189-h1">
              Parent Visa
              <span className="subclass-189-h1-span">(Subclass 103)</span>
            </h2>

            <p className="subclass-189-p">
              Ready to join your children in Australia?
              <a href="#" className="subclass-189-a">
                Start your application today for the Parent Visa (Subclass 103)!
              </a>
              This visa is ideal for parents looking to reunite with their
              children who are Australian citizens, permanent residents, or
              eligible New Zealand citizens. Whether you dream of family
              get-togethers, exploring Australia's stunning landscapes, or
              living permanently in a diverse and welcoming society, this visa
              paves the way for your aspirations. Seize the opportunity to
              create lasting memories with your loved ones and build a promising
              future together in Australia. Apply now and take the first step
              towards a heartwarming reunion!
            </p>
            <Button href="/contact" className="mt-3 contact-us-call-button">
              Call Now
            </Button>
          </Col>
        </Row>

        {/* //tab section */}
        <div className="row m-5 ">
          <div className="col-12">
            <div className="choose-us-title-view">
              <h2 className="choose-us-title">
                Parent Visa
                <b className="span-text">(Subclass 103)</b> A Comprehensive
                Guide
              </h2>
            </div>
            <p>
              The Parent Visa (Subclass 103) allows parents of settled
              Australian citizens, Australian permanent residents, or eligible
              New Zealand citizens to live in Australia permanently. This visa
              is designed to reunite families, providing a pathway for parents
              to join their children who are settled in Australia. The visa
              process includes rigorous eligibility criteria and a lengthy
              application period, but it offers numerous benefits, including the
              ability to live, work, and study in Australia permanently.
            </p>
          </div>
          <div className="col-12 ">
            <ul className="nav nav-tabs custom-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active custom-nav-link"
                  id="success-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#success"
                  type="button"
                  role="tab"
                  aria-controls="success"
                  aria-selected="true"
                >
                  <i className="fas fa-clipboard-list fa-2x"></i>
                  <br />
                  Eligibility Criteria
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="permanent-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#permanent"
                  type="button"
                  role="tab"
                  aria-controls="permanent"
                  aria-selected="false"
                >
                  <i className="fas fa-check-circle fa-2x"></i>
                  <br />
                  Why Visa 103?
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="priority-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#priority"
                  type="button"
                  role="tab"
                  aria-controls="priority"
                  aria-selected="false"
                >
                  <i className="fas fa-gift fa-2x"></i>
                  <br />
                  Visa 103 benefits
                </button>
              </li>
            </ul>
            <div className="tab-content custom-tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active custom-tab-pane"
                id="success"
                role="tabpanel"
                aria-labelledby="success-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>Eligibility for Parent Visa (Subclass 103)</h3>
                    <p>
                      To be eligible for Parent Visa (Subclass 103), applicants
                      must meet the following criteria:
                    </p>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Sponsorship Requirement:</strong>
                          Applicants must be sponsored by their child who is an
                          Australian citizen, permanent resident, or eligible
                          New Zealand citizen settled in Australia for at least
                          two years.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Balance of Family Test:</strong>
                          At least half of the applicant’s children must live in
                          Australia, or more of their children must reside in
                          Australia than any other single country.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Health and Character Requirements: </strong>
                          Applicants must meet the health and character criteria
                          set by the Australian government, including health
                          examinations and police checks.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Assurance of Support:</strong>
                          An Assurance of Support (AoS) is required, which
                          involves a financial bond paid by the sponsor or
                          another person.
                        </li>
                        <li className="subclass-189-li">
                          <strong>No Previous Visa Cancellation: </strong>
                          Applicants must not have had a visa canceled or an
                          application refused while in Australia.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="permanent"
                role="tabpanel"
                aria-labelledby="permanent-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>Why Choose the Parent Visa (Subclass 103)?</h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Family Reunion: </strong>
                          This visa facilitates the reunion of families by
                          allowing parents to join their children permanently in
                          Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Permanent Residency:</strong>
                          Grants the ability to live, work, and study in
                          Australia indefinitely.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Healthcare Access:</strong>
                          Visa holders can enroll in Medicare, Australia’s
                          public healthcare system.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Social Security:</strong>
                          Eligibility for certain social security payments,
                          subject to waiting periods.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Pathway to Citizenship:</strong>
                          Provides a pathway to Australian citizenship for
                          eligible applicants.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Sponsor Benefits: </strong>
                          Sponsors can have their parents close, providing
                          family support and continuity.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Cultural Exchange:</strong>
                          Promotes cultural exchange and strengthens family
                          bonds across generations.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="priority"
                role="tabpanel"
                aria-labelledby="priority-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>Benefits of Parent Visa (Subclass 103)</h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Permanent Residency: </strong>
                          Enjoy the right to live in Australia permanently.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Work and Study: </strong>
                          Full access to work and study opportunities.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Healthcare Access: </strong>
                          Enrollment in Medicare, ensuring health security.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Social Benefits:</strong>
                          Eligibility for various social security benefits.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Citizenship Pathway: </strong>
                          Potential to apply for Australian citizenship.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Family Unity:</strong>
                          Strengthen family bonds by living close to your
                          children.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Travel Freedom:</strong>
                          Travel to and from Australia for five years from the
                          date the visa is granted.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h3 className="subclass-189-pt-h1">Application Process</h3>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Secure sponsorship from your eligible child.
                </li>
                <li className="subclass-189-li">
                  Collect all required documents, including identity,
                  relationship evidence, and health assessments.
                </li>
                <li className="subclass-189-li">
                  Submit your application along with the visa fee.
                </li>
                <li className="subclass-189-li">
                  Wait for processing, which can take several years due to high
                  demand.
                </li>
                <li className="subclass-189-li">
                  Complete the required health examinations and character
                  checks.
                </li>
                <li className="subclass-189-li">
                  Provide the Assurance of Support bond.
                </li>
                <li className="subclass-189-li">
                  Upon approval, receive your visa grant notice and make travel
                  arrangements to Australia.
                </li>
              </ul>
            </p>

            {/* .......point test............. */}
            <h3 className="subclass-189-pt-h1">
              Points Test for Australia Visa 500
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              {/* <ul>
                                                <li className="subclass-189-li">
                                                  Age: 25-32 years (30 points), 18-24 & 33-39 years (25 points),
                                                  etc.
                                                </li>
                                                <li className="subclass-189-li">
                                                  English Language Ability: Superior (20 points), Proficient (10
                                                  points).
                                                </li>
                                                <li className="subclass-189-li">
                                                  Skilled Employment: In Australia or overseas, up to 20 points
                                                  depending on years of experience.
                                                </li>
                                                <li className="subclass-189-li">
                                                  Educational Qualifications: Doctorate (20 points),
                                                  Bachelor’s/Master’s (15 points),Diploma or trade
                                                  qualification: 10 points
                                                </li>
                                                <li className="subclass-189-li">
                                                  Other Factors: Australian study requirement, specialist
                                                  education qualification, accredited community language, etc.
                                                </li>
                                              </ul> */}
              The Parent Visa (Subclass 103) does not involve a points test.
              Eligibility is primarily based on family relationship, balance of
              family test, sponsorship, health, and character requirements.
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Related Visas</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="/aged-parent-visa-804"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 804 Aged Parent visa
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="/contributory-parent-visa-143"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (Subclass 143) Contributory Parent visa
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/contributory-parent-visa-173"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 173 Contributory Parent (Temporary) visa
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/contributory-aged-parent-visa-884"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 884 Contributory Aged Parent (Temporary) visa
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="/contributory-aged-parent-visa-864"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (Subclass 864) Contributory Aged Parent visa
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>

        {/* ................visa qualify............ */}
        <Row lg={12} className="subclass-189-vq-row">
          <h3 className="mt-5">Do you think you qualify for this visa?</h3>
          <p>
            Contact us if you would like to know more about this visa or need
            immigration advice.
          </p>
          <div className="mb-5">
            <Button
              href="/contact
"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Contact
            </Button>
            <Button
              href="/appointment"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Book A Consultation
            </Button>
            <Button
              href="/initial-assessment-consultation"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Our Prices
            </Button>
          </div>
        </Row>

        {/* <Row>
              <Col>
                <div className="row m-5">
                  <h1 className="home-h1">
                    Offshore Partner Visa (Subclass 309/100) Two Stage:
                  </h1>
                  <div className="col-lg-6 col-md-12">
                    <h5 className="subclass-189-pt-h1">
                      Partner visa (Temporary) Visa 309
                    </h5>
                    <p className="subclass-189-p subclass-189-pt-col">
                      
                      Must be outside of Australia to apply for this visa. Prior to
                      applying, gather all required paperwork, including health,
                      character, and sponsorship information. Use the online
                      application process to apply for the visa when you are away
                      from home.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <h5 className="subclass-189-pt-h1">
                      Partner visa (Permanent) Visa 100
                    </h5>
                    <p className="subclass-189-p subclass-189-pt-col">
                      
                      There is no need to provide any more information if Subclass
                      309 of the Visa comes right after Subclass 100. However, if a
                      Visa Subclass 100 is not immediately issued, the authorities
                      must be given the necessary information. Two years following
                      application lodging, it will be assessed; submit the required
                      paperwork one month beforehand. Submission of an application
                      through an online system.
                    </p>
                  </div>
                 
                </div>
              </Col>
            </Row> */}

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h5 className="subclass-189-pt-h1">Conditions and Obligations</h5>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Obey Australian Laws:</strong>
                  Visa holders must adhere to all Australian laws.
                </li>
                <li className="subclass-189-li">
                  <strong>Health Insurance:</strong>
                  Maintain adequate health insurance.
                </li>
                <li className="subclass-189-li">
                  <strong>Reporting Obligations: </strong>
                  Inform the Department of any changes in circumstances.
                </li>
                <li className="subclass-189-li">
                  <strong>Sponsor Obligations: </strong>
                  Sponsors must fulfill financial support obligations during the
                  Assurance of Support period.
                </li>
                <li className="subclass-189-li">
                  <strong>Travel Restrictions: </strong>
                  Abide by travel conditions attached to the visa.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">Express Your Interest</h3>
            <p className="subclass-189-p subclass-189-pt-col">
              {/* <ul>
                                    <li className="subclass-189-li">
                                      <strong>Research: </strong>
                                      Thoroughly research the visa requirements and benefits.
                                    </li>
                                    <li className="subclass-189-li">
                                      <strong>Preparation:</strong>
                                      Prepare all necessary documents and financial proofs.
                                    </li>
                                    <li className="subclass-189-li">
                                      <strong>Application:</strong>
                                      Complete and submit the online application.
                                    </li>
                                    <li className="subclass-189-li">
                                      <strong>Follow-up:</strong>
                                      Monitor your application status through ImmiAccount.
                                    </li>
                                    <li className="subclass-189-li">
                                      <strong>Visa Grant:</strong>
                                      Upon approval, make travel and accommodation arrangements.
                                    </li>
                                  </ul> */}
              To express your interest in the Parent Visa (Subclass 103), ensure
              that you meet all eligibility criteria and gather the necessary
              documentation. Engage with your sponsor to begin the application
              process and prepare for a thorough and detailed submission. Due to
              high demand, it is advisable to submit your application early and
              ensure all information is accurate and complete.
            </p>

            <h3 className="subclass-189-pt-h1">
              English Language Requirements
            </h3>
            <p>
              To meet the English language requirement, you must demonstrate
              competent English. This can be done through:
            </p>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>IELTS:</strong>A minimum score of 6 in each component
                  (Listening, Reading, Writing, and Speaking).
                </li>
                <li className="subclass-189-li">
                  <strong>TOEFL iBT:</strong>A minimum score of 12 in Listening,
                  13 in Reading, 21 in Writing, and 18 in Speaking.
                </li>
                <li className="subclass-189-li">
                  <strong>PTE Academic:</strong>A minimum score of 50 in each
                  component (Listening, Reading, Writing, and Speaking).
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Google Adds</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>

            <div className="subclass-189-rv-card-shadow mt-5">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Latest Post</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </>
      {/* faqs section */}
      <div className="row testimonial-background visa_question-acc">
        <div className="col-lg-12 cl-sm-12">
          <div className="testimonial-heading">
            {/* <h1 className="home-h1">Success Stories</h1> */}
            <h4 className="about-us-h4">
              Frequently Asked Questions (FAQs) about the Parent Visa (Subclass
              103)?
            </h4>
          </div>

          <>
            <Row>
              <Col lg={12} className="">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      How long does the Parent Visa (Subclass 103) take to
                      process?
                    </Accordion.Header>
                    <Accordion.Body>
                      Processing times can vary significantly but typically take
                      several years due to high demand.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      What is the Assurance of Support (AoS) requirement?
                    </Accordion.Header>
                    <Accordion.Body>
                      The AoS is a financial bond that ensures the applicant
                      will not require welfare support from the Australian
                      government.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Can I work while holding the Parent Visa (Subclass 103)?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, visa holders can work and study in Australia without
                      restrictions.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What is the Balance of Family Test?
                    </Accordion.Header>
                    <Accordion.Body>
                      The test requires that at least half of your children live
                      in Australia, or more of your children live in Australia
                      than any other country.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Are there health requirements for this visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, applicants must undergo health examinations to meet
                      Australian health standards
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Do I need a police check for the Parent Visa (Subclass
                      103)?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, character requirements include police checks from all
                      countries where the applicant has lived.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      What happens if my application is refused?
                    </Accordion.Header>
                    <Accordion.Body>
                      You can appeal the decision, but it is crucial to ensure
                      your application is complete and meets all requirements
                      initially.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      Can I apply for Australian citizenship with this visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, after meeting the residency requirements, you may be
                      eligible to apply for citizenship.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      Is there an age limit for the Parent Visa (Subclass 103)?
                    </Accordion.Header>
                    <Accordion.Body>
                      There is no specific age limit, but applicants must meet
                      all other eligibility criteria.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      Can my sponsor be a permanent resident?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, the sponsor can be an Australian citizen, permanent
                      resident, or eligible New Zealand citizen.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </>
        </div>
      </div>
    </Layout>
  );
};

export default ParentVisa_103;
