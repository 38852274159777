import React from "react";
import Layout from "../../components/Layout/Layout";
import { Container, Row, Col, Button } from "react-bootstrap";
import tab1 from "../../images/aboutUs.jpg";
import { Link, NavLink } from "react-router-dom";
import bannerImage from "../../images/banner2.jpg";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <Layout
      title="Best Australian Migration Agents | Real Migration and Education"
      description="Real Migration and Education - Leading Australian migration agents dedicated to guiding you through every step of your migration and education journey. Achieve your dreams of permanent residency and quality education with our expert, personalized assistance."
      keywords="Australian migration agents,
  Migration and education services,
  Permanent residency Australia,
  Australian education opportunities,
  Migration guidance Australia,
  Expert migration agents,
  Professional migration support,
  Trusted migration agents Australia,
  Personalized migration assistance,
  Quality education in Australia,
  "
    >
      <Row>
        <Col>
          <div className="row m-5">
            <div className="col-lg-6 col-md-12">
              <h1 className="home-h1">
                Top Migration Agents and Education Consultants
              </h1>
              <p className="mt-3">
                Welcome to Real Migration and Education, where our seasoned team
                of MARA-registered migration agents and accredited education
                consultants is dedicated to helping you realize your dreams in
                Australia.
              </p>
              <p>
                Our deep understanding of the immigration and education
                landscapes, coupled with cutting-edge technological solutions,
                ensures a streamlined and personalized experience. Whether
                you're aiming to study, work, or settle in Australia, we're here
                to guide you every step of the way, making your journey smooth
                and successful.
              </p>
              <p>
                Discover a world of opportunities with us and start your journey
                to a bright future in Australia.
              </p>
            </div>
            <div className="col-lg-6 col-md-12">
              <img
                src={bannerImage}
                alt="Real Migration And Education BannerImage"
                className="img-fluid bannerImage"
              />
            </div>
          </div>

          <div className="row m-5">
            <div className="col-12">
              <div className="choose-us-title-view">
                <h2 className="choose-us-title">
                  <b className="span-text">About Us</b>
                </h2>
              </div>
              <p>
                At Real Migration and Education, we are the Best Australian
                Migration Agents dedicated to helping individuals achieve their
                dreams of permanent residency and quality education. We offer
                expert, personalized guidance through every step of the
                migration and education journey, adhering strictly to the latest
                legislative requirements. Our services cover a wide range of
                visas, including skilled migration, family reunification, and
                student visas. We provide comprehensive support, from assessing
                eligibility and lodging applications to navigating academic
                enrolment and residency requirements. Our commitment to
                integrity, professionalism, and excellence ensures that you
                receive reliable, tailored advice, making us your trusted
                partner in realizing your aspirations. Contact us today to start
                your journey towards a brighter future.
              </p>
            </div>
            <div className="col-12 ">
              <ul
                className="nav nav-tabs custom-tabs"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active custom-nav-link"
                    id="success-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#success"
                    type="button"
                    role="tab"
                    aria-controls="success"
                    aria-selected="true"
                  >
                    <i className="fas fa-heart fa-2x"></i>
                    <br />
                    <b>Client-Centric Approach</b>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link custom-nav-link"
                    id="permanent-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#permanent"
                    type="button"
                    role="tab"
                    aria-controls="permanent"
                    aria-selected="false"
                  >
                    <i className="fas fa-book fa-2x"></i>
                    <br />
                    <b>Expertise And Knowledge</b>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link custom-nav-link"
                    id="priority-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#priority"
                    type="button"
                    role="tab"
                    aria-controls="priority"
                    aria-selected="false"
                  >
                    <i className="fas fa-toolbox fa-2x"></i>
                    <br />
                    <b>Comprehensive Service</b>
                  </button>
                </li>
              </ul>
              <div className="tab-content custom-tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active custom-tab-pane"
                  id="success"
                  role="tabpanel"
                  aria-labelledby="success-tab"
                >
                  <div className="row">
                    <div className="col-lg-6 mt-4">
                      {/* <h6>CLIENT CENTRIC APPROACH</h6> */}
                      <p className="mt-4">
                        At Real Migration and Education, we are best Australian
                        Migration Agents prioritize your needs and aspirations.
                        Our client-centric approach ensures personalized
                        services tailored to your circumstances. Whether you’re
                        seeking permanent residency to Australia or quality
                        education in Australia, we’re here to support you. Our
                        dedicated team guides you through every step of your
                        journey toward Australia, making it smooth and
                        successful. Trust us to be your reliable partner in
                        realizing your dreams to Australia.
                      </p>
                      <Link to="/contact" className="btn btn-enquire m-2">
                        Contact Us
                      </Link>
                    </div>
                    <div className="col-lg-6 center-container">
                      <img
                        src={tab1}
                        alt="Visa Application"
                        className="img-fluid tab1-image"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade custom-tab-pane"
                  id="permanent"
                  role="tabpanel"
                  aria-labelledby="permanent-tab"
                >
                  <div className="row">
                    <div className="col-lg-6 mt-4">
                      {/* <h6>Expertise And Knowledge</h6> */}
                      <p className="mt-4">
                        At Real Migration and Education, our expertise and
                        knowledge are the cornerstones of our service. With
                        years of experience in Australian Immigration, we
                        provide informed guidance and solutions to moving Toward
                        Australia. Our team stays updated with the latest trends
                        and regulations to offer you the best advice For
                        Australia Consultancy. Whether it’s navigating complex
                        visa processes or choosing the right educational path,
                        we have the Best Australian Migration Agents expertise
                        to help you succeed. Trust us to be your knowledgeable
                        partner in your migration and education journey.
                      </p>
                      <Link to="/contact" className="btn btn-enquire m-2">
                        Contact Us
                      </Link>
                    </div>
                    <div className="col-lg-6 center-container">
                      <img
                        src={tab1}
                        alt="Visa Application"
                        className="img-fluid tab1-image"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade custom-tab-pane"
                  id="priority"
                  role="tabpanel"
                  aria-labelledby="priority-tab"
                >
                  <div className="row">
                    <div className="col-lg-6 mt-4">
                      {/* <h6>Comprehensive Services</h6> */}
                      <p className="mt-4">
                        At Real Migration and Education, we offer comprehensive
                        services tailored to your needs. From visa applications
                        For Australia to education planning, we handle every
                        aspect of your migration and education journey Toward
                        Australia. Our team ensures you have all the information
                        and support you need at every step. With our
                        comprehensive services, you can navigate the process
                        smoothly and confidently. Best Australian Migration
                        Agents Trust us to be your reliable partner in achieving
                        your migration and education goals for Australian
                        Immigration.
                      </p>
                      <Link to="/contact" className="btn btn-enquire m-2">
                        Contact Us
                      </Link>
                    </div>
                    <div className="col-lg-6 center-container">
                      <img
                        src={tab1}
                        alt="Visa Application"
                        className="img-fluid tab1-image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <div className="row testimonial-background">
        <div className="col-lg-12 col-md-12">
          <div className="testimonial-heading">
            <h1 className="home-h1">Connect With Us!</h1>
            <h4 className="about-us-h4">
              Connect with us on social media for the latest updates on visas
              and
              <b className="span-text">Immigration </b>
              NEWS
            </h4>
          </div>
          <div className="social-media-icons-aboutus">
            <NavLink
              to="https://www.youtube.com/@RealMigration"
              target="_blank"
              rel="noopener noreferrer"
              className="youtube-icon-aboutus"
            >
              <i className="fab fa-youtube"></i>
            </NavLink>
            <NavLink
              to="https://www.facebook.com/RealMigration"
              target="_blank"
              rel="noopener noreferrer"
              className="facebook-icon-aboutus"
            >
              <i className="fab fa-facebook-f"></i>
            </NavLink>
            <NavLink
              to="https://realmigration.com.au/"
              target="_blank"
              rel="noopener noreferrer"
              className="twitter-icon-aboutus"
            >
              <i className="fab fa-x-twitter"></i>
            </NavLink>
            <NavLink
              to="https://www.instagram.com/realmigrationau/"
              target="_blank"
              rel="noopener noreferrer"
              className="instagram-icon-aboutus"
            >
              <i className="fab fa-instagram"></i>
            </NavLink>
            <NavLink
              to="https://www.tiktok.com/@realmigration"
              target="_blank"
              rel="noopener noreferrer"
              className="tiktok-icon-aboutus"
            >
              <i className="fab fa-tiktok"></i>
            </NavLink>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;
