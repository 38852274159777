import React, { useEffect } from "react";
import { Container } from "@mui/material";
import Layout from "../../components/Layout/Layout";

export default function BookAnAppointment() {
  useEffect(() => {
    // Add the zcal script to the page if it doesn't already exist
    const scriptId = "zcal-embed-script";
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://static.zcal.co/embed/v1/embed.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <Layout>
      <Container
        sx={{
          position: "relative",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          boxSizing: "border-box",
        }}
      >
        <iframe
          src="https://zcal.co/emb/realmigration?embed=1&embedType=iframe"
          loading="lazy"
          style={{
            border: "none",
            width: "100%",
            height: "100%",
            minHeight: "544px",
            borderRadius: "8px",
          }}
          id="zcal-iframe"
          title="Schedule a meeting"
        ></iframe>
      </Container>
    </Layout>
  );
}
