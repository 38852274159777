import React from "react";
import Layout from "../../../../components/Layout/Layout";
import { Row, Col, Button } from "react-bootstrap";
import visa_804 from "../../../../images/visaImages/visa_804_1.png";

import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import "./AgedPVisa_804.css";

const AgedPVisa_804 = () => {
  return (
    <Layout
      title="Aged Parent Visa Subclass 804 - Detailed Guide for Eligibility, Benefits, and Application Process"
      description="Discover everything about the Aged Parent Visa Subclass 804. Learn about eligibility criteria, benefits, the application process, and frequently asked questions to ensure a successful application."
      keywords="Aged Parent Visa 804, Australia aged parent visa, visa eligibility, visa benefits, Australia immigration, aged parent visa application, Subclass 804 requirements"
    >
      <>
        <Row className="m-5">
          <Col lg={6} md={6} sm={12}>
            <img
              src={visa_804}
              alt="skilledindependent-visa-189"
              className="img-fluid subclass-189-image"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <h2 className="subclass-189-h1">
              Aged Parent Visa
              <span className="subclass-189-h1-span">(Subclass 804)</span>
            </h2>

            <p className="subclass-189-p">
              Ready to join your children in Australia?
              <a href="#" className="subclass-189-a">
                Start your application today for the Aged Parent Visa (Subclass
                804)!
              </a>
              This visa is perfect for elderly parents wishing to reunite with
              their children who are Australian citizens, permanent residents,
              or eligible New Zealand citizens. Whether you dream of family
              gatherings, exploring Australia's beautiful landscapes, or living
              permanently in a vibrant and welcoming society, this visa opens
              the door to your aspirations. Take the opportunity to create
              cherished memories with your loved ones and build a promising
              future together in Australia. Apply now and take the first step
              towards a joyful reunion!
            </p>
            <Button href="/contact" className="mt-3 contact-us-call-button">
              Call Now
            </Button>
          </Col>
        </Row>

        {/* //tab section */}
        <div className="row m-5 ">
          <div className="col-12">
            <div className="choose-us-title-view">
              <h2 className="choose-us-title">
                Aged Parent Visa
                <b className="span-text">(Subclass 804)</b> A Comprehensive
                Guide
              </h2>
            </div>
            <p className="choose-us-text subclass-189-page-font">
              The Aged Parent Visa (Subclass 804) allows elderly parents of
              Australian citizens, permanent residents, or eligible New Zealand
              citizens to live in Australia permanently. This visa is designed
              for older parents who wish to join their children in Australia and
              enjoy the benefits of living in a developed country with their
              family.
            </p>
          </div>
          <div className="col-12 ">
            <ul className="nav nav-tabs custom-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active custom-nav-link"
                  id="success-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#success"
                  type="button"
                  role="tab"
                  aria-controls="success"
                  aria-selected="true"
                >
                  <i className="fas fa-clipboard-list fa-2x"></i>
                  <br />
                  Eligibility Criteria
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="permanent-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#permanent"
                  type="button"
                  role="tab"
                  aria-controls="permanent"
                  aria-selected="false"
                >
                  <i className="fas fa-check-circle fa-2x"></i>
                  <br />
                  Why Visa 804?
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="priority-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#priority"
                  type="button"
                  role="tab"
                  aria-controls="priority"
                  aria-selected="false"
                >
                  <i className="fas fa-gift fa-2x"></i>
                  <br />
                  Visa 804 benefits
                </button>
              </li>
            </ul>
            <div className="tab-content custom-tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active custom-tab-pane"
                id="success"
                role="tabpanel"
                aria-labelledby="success-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>Eligibility for Aged Parent Visa (Subclass 804)</h3>
                    <p>
                      To apply for the Aged Parent Visa (Subclass 804),
                      applicants must meet the following eligibility criteria:
                    </p>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Relationship Requirements</strong>
                          Applicants must be the parent of an Australian
                          citizen, permanent resident, or eligible New Zealand
                          citizen.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Balance of Family Test:</strong>
                          At least half of the applicant’s children must reside
                          permanently in Australia, or more of their children
                          live in Australia than in any other country.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Assurance of Support </strong>A financial bond
                          is required to ensure that the applicant will not rely
                          on government support.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Health and Character Requirements</strong>
                          Applicants must meet health and character requirements
                          as part of the visa application process.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Age Requirement </strong>
                          Applicants must be of pension age as defined by
                          Australian law.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Visa Sponsorship: </strong>
                          Applicants must be sponsored by their child who is an
                          Australian citizen, permanent resident, or eligible
                          New Zealand citizen.
                        </li>
                        <li className="subclass-189-li">
                          <strong>No Pending Visa Applications:</strong>
                          Applicants must not have a pending application for
                          another parent visa at the time of applying.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="permanent"
                role="tabpanel"
                aria-labelledby="permanent-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>Why Choose the Aged Parent Visa (Subclass 804)?</h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Permanent Residency: </strong>
                          This visa grants permanent residency, allowing aged
                          parents to stay in Australia indefinitely.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Family Reunification:</strong>
                          Reunite with family members living in Australia and
                          enjoy a supportive family environment.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Access to Australian Benefits:</strong>
                          Gain access to Medicare and other social security
                          benefits available to permanent residents.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="priority"
                role="tabpanel"
                aria-labelledby="priority-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>Benefits of Aged Parent Visa (Subclass 804)</h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Permanent Residency: </strong>
                          Enjoy the rights and benefits of being a permanent
                          resident in Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Medicare Access: </strong>
                          Access Australia’s public healthcare system, Medicare.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Work and Study Rights:</strong>
                          Work and study in Australia without any restrictions.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Pathway to Citizenship:</strong>
                          After meeting residency requirements, apply for
                          Australian citizenship.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Sponsor Other Family Members: </strong>
                          Sponsor other family members for visas to Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Travel Rights:</strong>
                          Travel in and out of Australia without restrictions.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Legal Protection:</strong>
                          Receive protection under Australian law as a permanent
                          resident.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h3 className="subclass-189-pt-h1">Application Process</h3>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Ensure you meet all eligibility criteria.
                </li>
                <li className="subclass-189-li">
                  Collect all required documents for the application.
                </li>
                <li className="subclass-189-li">
                  Submit your visa application along with the required fees.
                </li>
                <li className="subclass-189-li">
                  Wait for the application to be processed by the Department of
                  Home Affairs.
                </li>
                <li className="subclass-189-li">
                  If approved, receive your visa grant and move to Australia.
                </li>
                <li className="subclass-189-li">
                  The application fee must be paid when you submit your visa
                  application.
                </li>
                <li className="subclass-189-li">
                  Proof of relationship to the sponsor, Balance of Family Test
                  documentation, Assurance of Support documentation, Health and
                  character certificates.
                </li>
              </ul>
            </p>

            {/* .......point test............. */}
            <h3 className="subclass-189-pt-h1">
              Points Test for Australia Visa 500
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              {/* <ul>
                                                    <li className="subclass-189-li">
                                                      Age: 25-32 years (30 points), 18-24 & 33-39 years (25 points),
                                                      etc.
                                                    </li>
                                                    <li className="subclass-189-li">
                                                      English Language Ability: Superior (20 points), Proficient (10
                                                      points).
                                                    </li>
                                                    <li className="subclass-189-li">
                                                      Skilled Employment: In Australia or overseas, up to 20 points
                                                      depending on years of experience.
                                                    </li>
                                                    <li className="subclass-189-li">
                                                      Educational Qualifications: Doctorate (20 points),
                                                      Bachelor’s/Master’s (15 points),Diploma or trade
                                                      qualification: 10 points
                                                    </li>
                                                    <li className="subclass-189-li">
                                                      Other Factors: Australian study requirement, specialist
                                                      education qualification, accredited community language, etc.
                                                    </li>
                                                  </ul> */}
              The Parent Visa (Subclass 103) does not involve a points test.
              Eligibility is primarily based on family relationship, balance of
              family test, sponsorship, health, and character requirements.
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Related Visas</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="/parent-visa-103"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 103 Parent visa
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="/contributory-parent-visa-143"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (Subclass 143) Contributory Parent visa
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/contributory-parent-visa-173"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 173 Contributory Parent (Temporary) visa
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="
/contributory-aged-parent-visa-884"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 884 Contributory Aged Parent (Temporary) visa
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="/contributory-aged-parent-visa-864"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (Subclass 864) Contributory Aged Parent visa
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>

        {/* ................visa qualify............ */}
        <Row lg={12} className="subclass-189-vq-row">
          <h3 className="mt-5">Do you think you qualify for this visa?</h3>
          <p>
            Contact us if you would like to know more about this visa or need
            immigration advice.
          </p>
          <div className="mb-5">
            <Button
              href="/contact"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Contact
            </Button>
            <Button
              href="/appointment"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Book A Consultation
            </Button>
            <Button
              href="/initial-assessment-consultation"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Our Prices
            </Button>
          </div>
        </Row>

        {/* <Row>
                  <Col>
                    <div className="row m-5">
                      <h1 className="home-h1">
                        Offshore Partner Visa (Subclass 309/100) Two Stage:
                      </h1>
                      <div className="col-lg-6 col-md-12">
                        <h5 className="subclass-189-pt-h1">
                          Partner visa (Temporary) Visa 309
                        </h5>
                        <p className="subclass-189-p subclass-189-pt-col">
                          
                          Must be outside of Australia to apply for this visa. Prior to
                          applying, gather all required paperwork, including health,
                          character, and sponsorship information. Use the online
                          application process to apply for the visa when you are away
                          from home.
                        </p>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <h5 className="subclass-189-pt-h1">
                          Partner visa (Permanent) Visa 100
                        </h5>
                        <p className="subclass-189-p subclass-189-pt-col">
                          
                          There is no need to provide any more information if Subclass
                          309 of the Visa comes right after Subclass 100. However, if a
                          Visa Subclass 100 is not immediately issued, the authorities
                          must be given the necessary information. Two years following
                          application lodging, it will be assessed; submit the required
                          paperwork one month beforehand. Submission of an application
                          through an online system.
                        </p>
                      </div>
                     
                    </div>
                  </Col>
                </Row> */}

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h5 className="subclass-189-pt-h1">Conditions and Obligations</h5>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Visa Conditions 1:</strong>
                  The visa holder must enter Australia within the specified
                  period.
                </li>
                <li className="subclass-189-li">
                  <strong>Visa Conditions 2:</strong>
                  The visa holder must comply with Australian laws.
                </li>
                <li className="subclass-189-li">
                  <strong>Obligations 1: </strong>
                  Maintain health insurance.
                </li>
                <li className="subclass-189-li">
                  <strong>Obligations 2: </strong>
                  Provide the Assurance of Support as required.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">Express Your Interest</h3>
            <p className="subclass-189-p subclass-189-pt-col">
              {/* <ul>
                                        <li className="subclass-189-li">
                                          <strong>Research: </strong>
                                          Thoroughly research the visa requirements and benefits.
                                        </li>
                                        <li className="subclass-189-li">
                                          <strong>Preparation:</strong>
                                          Prepare all necessary documents and financial proofs.
                                        </li>
                                        <li className="subclass-189-li">
                                          <strong>Application:</strong>
                                          Complete and submit the online application.
                                        </li>
                                        <li className="subclass-189-li">
                                          <strong>Follow-up:</strong>
                                          Monitor your application status through ImmiAccount.
                                        </li>
                                        <li className="subclass-189-li">
                                          <strong>Visa Grant:</strong>
                                          Upon approval, make travel and accommodation arrangements.
                                        </li>
                                      </ul> */}
              Expressing your interest in the Aged Parent Visa (Subclass 804)
              can be done by submitting an Expression of Interest (EOI) through
              the Department of Home Affairs. This step is crucial to indicate
              your intention to apply and start the application process.
            </p>

            <h3 className="subclass-189-pt-h1">
              English Language Requirements
            </h3>
            <p>
              To meet the English language requirement, you must demonstrate
              competent English. This can be done through:
            </p>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>IELTS:</strong>A minimum score of 6 in each component
                  (Listening, Reading, Writing, and Speaking).
                </li>
                <li className="subclass-189-li">
                  <strong>TOEFL iBT:</strong>A minimum score of 12 in Listening,
                  13 in Reading, 21 in Writing, and 18 in Speaking.
                </li>
                <li className="subclass-189-li">
                  <strong>PTE Academic:</strong>A minimum score of 50 in each
                  component (Listening, Reading, Writing, and Speaking).
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Google Adds</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>

            <div className="subclass-189-rv-card-shadow mt-5">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Latest Post</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </>
      {/* faqs section */}
      <div className="row testimonial-background visa_question-acc">
        <div className="col-lg-12 cl-sm-12">
          <div className="testimonial-heading">
            {/* <h1 className="home-h1">Success Stories</h1> */}
            <h4 className="about-us-h4">
              Frequently Asked Questions (FAQs) about the Aged Parent Visa
              (Subclass 804)?
            </h4>
          </div>

          <>
            <Row>
              <Col lg={12} className="">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      What is the Aged Parent Visa (Subclass 804)?
                    </Accordion.Header>
                    <Accordion.Body>
                      The Aged Parent Visa (Subclass 804) allows elderly parents
                      of Australian citizens, permanent residents, or eligible
                      New Zealand citizens to live in Australia permanently.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      What are the main eligibility criteria for the Aged Parent
                      Visa (Subclass 804)?
                    </Accordion.Header>
                    <Accordion.Body>
                      The main eligibility criteria include relationship
                      requirements, balance of family test, assurance of
                      support, health and character requirements, age
                      requirement, visa sponsorship, and no pending visa
                      applications.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      How long does it take to process the Aged Parent Visa
                      (Subclass 804)?
                    </Accordion.Header>
                    <Accordion.Body>
                      Processing times can vary, but it often takes several
                      years due to high demand and limited places available.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can I work in Australia on an Aged Parent Visa (Subclass
                      804)?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, this visa grants full work rights in Australia.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Is there a points test for the Aged Parent Visa (Subclass
                      804)?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, there is no points test for this visa. Eligibility is
                      based on meeting specific criteria.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Can I include my partner in my Aged Parent Visa (Subclass
                      804) application?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can include your partner and dependent family
                      members in your application.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      What is the Assurance of Support for the Aged Parent Visa
                      (Subclass 804)?
                    </Accordion.Header>
                    <Accordion.Body>
                      The Assurance of Support is a financial bond that ensures
                      the applicant will not rely on government support for a
                      specified period.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      What are the benefits of the Aged Parent Visa (Subclass
                      804)?
                    </Accordion.Header>
                    <Accordion.Body>
                      Benefits include permanent residency, access to Medicare,
                      work and study rights, the pathway to citizenship, and the
                      ability to sponsor other family members.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      Can I travel in and out of Australia on the Aged Parent
                      Visa (Subclass 804)?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can travel freely in and out of Australia once
                      you hold this visa.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      How can I express my interest in the Aged Parent Visa
                      (Subclass 804)?
                    </Accordion.Header>
                    <Accordion.Body>
                      You can express your interest by submitting an Expression
                      of Interest (EOI) through the Department of Home Affairs.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </>
        </div>
      </div>
    </Layout>
  );
};

export default AgedPVisa_804;
