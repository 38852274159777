import React from "react";
import Layout from "../../components/Layout/Layout";
import { Card, Button, Row, Col } from "react-bootstrap"; // Importing Bootstrap components for styling
import { Link } from "react-router-dom";

const JobCard = ({ jobTitle, location, description, visaInfo }) => {
  return (
    <Card style={{ width: "100%", margin: "20px auto" }}>
      <Card.Body>
        {/* Proper Heading for the Job Title */}
        <h2 style={{ fontWeight: "bold", fontSize: "1.5rem" }}>{jobTitle}</h2>
        <h5 className="text-muted">{location}</h5>
        <Card.Text style={{ fontSize: "1rem" }}>{description}</Card.Text>
        <Card.Text style={{ fontSize: "1rem" }}>
          <strong>{visaInfo}</strong>
        </Card.Text>

        <Link to="/detail-job">
          <Button variant="primary">View Detail and Apply</Button>
        </Link>
      </Card.Body>
    </Card>
  );
};

const JObsCareer = () => {
  const jobData = [
    {
      jobTitle: "Enrolled Nurse (Visa 482 Sponsorship Available)",
      location: "Queensland (QLD)",
      description:
        "Our client is currently seeking an experienced and dedicated Enrolled Nurse with a minimum of 2 years of experience to join our growing team in Queensland. This is a fantastic opportunity for skilled nurses looking to further their career in a supportive and positive working environment.",
      visaInfo: "Visa 482 sponsorship is available for the right candidate!",
    },
  ];

  return (
    <Layout
      title="Find Jobs in Australia | Visa Information and Career Opportunities"
      description="Discover exciting job opportunities in Australia. Get detailed visa information, explore in-demand careers, and find your path to working and living in Australia. Start your career journey today!"
      keywords="Australia jobs, Australian visa information, work in Australia, Australian job market, skilled migration visa, Australian work visas, career opportunities in Australia, jobs for migrants, apply for jobs in Australia, Australian employment opportunities"
    >
      <div style={{ padding: "20px" }}>
        {/* Use <h1> for the main heading */}
        <h1 style={{ textAlign: "center", marginBottom: "30px" }}>
          Jobs & Careers in Australia
        </h1>
        <Row>
          {jobData.map((job, index) => (
            <Col
              lg={6}
              md={6}
              sm={12}
              key={index}
              style={{ marginBottom: "20px" }}
            >
              <JobCard
                jobTitle={job.jobTitle}
                location={job.location}
                description={job.description}
                visaInfo={job.visaInfo}
              />
            </Col>
          ))}
        </Row>
      </div>
    </Layout>
  );
};

export default JObsCareer;
