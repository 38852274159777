import React, { useEffect, useState } from "react";
import AdminLayout from "../../../../components/AdminLayout/AdminLayout";
import axios from "axios";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBBtn,
  MDBIcon,
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import SearchField from "../../../../components/SearchFeild";
import { Modal, Button } from "react-bootstrap";

const ShowAdmin = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);
  const [error, setError] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  useEffect(() => {
    fetchAdmins();
  }, []);

  const fetchAdmins = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}admin/get-admins`
      );
      console.log("Full API response:", response.data);

      let admins;
      if (response.data.admins && Array.isArray(response.data.admins)) {
        admins = response.data.admins;
      } else {
        throw new Error("Received data is not in expected array format");
      }

      setData(admins);
      setFilteredData(admins);
    } catch (err) {
      console.error("Error fetching admins:", err);
      setError("Failed to fetch admins. Please try again later.");
    }
  };

  const handleSearch = (searchTerm) => {
    if (!searchTerm) {
      setFilteredData(data);
    } else {
      const filtered = data.filter(
        (user) =>
          user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.phone_number.includes(searchTerm)
      );
      setFilteredData(filtered);
    }
    setCurrentPage(1);
  };

  const handleDelete = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API}admin/remove-admin/${userIdToDelete}`
      );
      if (response.status === 200) {
        // Refetch the admins list to refresh the component
        await fetchAdmins();
        setShowDeleteModal(false);
      } else {
        throw new Error("Failed to remove user from admin");
      }
    } catch (err) {
      console.error("Error removing user from admin:", err);
      setError(
        err.response
          ? err.response.data.message
          : "Failed to remove user from admin. Unknown error."
      );
    }
  };

  const confirmDelete = (userId) => {
    setUserIdToDelete(userId);
    setShowDeleteModal(true);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
      pageNumbers.push(i);
    }
    return (
      <MDBPagination className="mb-4">
        {pageNumbers.map((number) => (
          <MDBPaginationItem key={number} active={currentPage === number}>
            <MDBPaginationLink onClick={() => setCurrentPage(number)}>
              {number}
            </MDBPaginationLink>
          </MDBPaginationItem>
        ))}
      </MDBPagination>
    );
  };

  const renderTableBody = () => {
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredData.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );

    if (currentRecords.length === 0) {
      return (
        <MDBTableBody className="align-center mb-0">
          <tr>
            <td colSpan={6} className="text-center mb-0">
              No Data Found
            </td>
          </tr>
        </MDBTableBody>
      );
    } else {
      return currentRecords.map((item, index) => (
        <MDBTableBody key={item._id}>
          <tr>
            <th scope="row">{index + 1 + indexOfFirstRecord}</th>
            <td>{item.first_name}</td>
            <td>{item.last_name}</td>
            <td>{item.email}</td>
            <td>{item.phone_number}</td>
            <td>
              <MDBBtn
                color="danger"
                size="sm"
                onClick={() => confirmDelete(item._id)}
              >
                <MDBIcon fas icon="trash" /> Remove From Admin
              </MDBBtn>
            </td>
          </tr>
        </MDBTableBody>
      ));
    }
  };

  return (
    <AdminLayout>
      <MDBContainer>
        <div>
          <MDBRow className="align-items-center mb-4">
            <MDBCol xs="12" md="6" className="text-start">
              <h2>List Of All Admins</h2>
            </MDBCol>
            <MDBCol xs="12" md="6" className="text-end">
              <SearchField
                handleSearch={handleSearch}
                className="w-100 w-md-auto"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="12">
              <MDBTable responsive>
                <MDBTableHead dark>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Action</th>
                  </tr>
                </MDBTableHead>
                {renderTableBody()}
              </MDBTable>
              {renderPagination()}
            </MDBCol>
          </MDBRow>
        </div>
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to Remove this user from Admin?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Remove
            </Button>
          </Modal.Footer>
        </Modal>
      </MDBContainer>
    </AdminLayout>
  );
};

export default ShowAdmin;
