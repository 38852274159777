import React from "react";
import Layout from "../../components/Layout/Layout";
import { Row, Col, Button } from "react-bootstrap";
import contactUsImage from "../../images/contactUs.jpg";
import { Link, NavLink } from "react-router-dom";

const FullRepresentation = () => {
  return (
    <Layout
      title="Full Representation Consultation - Real Migration and Education: Comprehensive Immigration Services"
      description="Book a full representation consultation with Real Migration and Education. We provide comprehensive immigration services, handling all aspects of your Australian visa application process. Our experienced team ensures thorough guidance and support for skilled, partner, and family visas."
      keywords="Full representation consultation, Real Migration and Education, comprehensive immigration services, Australian visa application, visa representation, immigration experts, skilled visa support, partner visa assistance, family visa services, immigration advice, PR in Australia, visa application process, expert immigration guidance, Australian migration consultancy, complete visa service"
    >
      <Row className="m-5">
        <Col lg={6} md={6} sm={12}>
          <h2 className="choose-us-title">
            Consultation <b className="span-text">With</b> Full Representation
          </h2>
          <p className="contact-us-heading-p mt-5">
            We will take full control of your migration case, offering
            comprehensive guidance and assistance throughout the entire process.
            From initial consultation to finalizing your Australian visa
            application, our expert team ensures that every step is managed
            efficiently and effectively, providing you with peace of mind and
            the best chance for success.
          </p>
          <div className="contact-us-email-container">
            <span>Email us at:</span>
            <a href="mailto:info@realmigration.com.au" className="email-link">
              info@realmigration.com.au
            </a>
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} className="contact-us-image-col">
          <img
            src={contactUsImage}
            alt="Visa Application"
            className="img-fluid contact-us-image"
          />
        </Col>
      </Row>
      <Row className="initail-consult-bg">
        <h2 className="choose-us-title " style={{ textAlign: "center" }}>
          What Is This?
        </h2>
        <div className="m-5">
          <p>
            The Full Representation service is the most comprehensive service
            that we offer. We provide immigration advice and assistance in
            relation to:
          </p>
          <p>
            <ul>
              <li>
                <strong>Migration processes</strong>
                including visa applications, sponsorship and nomination and
                skills assessment.
              </li>
              <li>
                <strong>Review applications</strong>
                to the Migration & Refugee Division of the Administrative
                Appeals Tribunal (AAT).
              </li>
            </ul>
          </p>
        </div>

        <Col>
          <Row className="m-5">
            <Col lg={6} md={6} sm={12}>
              <h2>Migration Process</h2>
              <p className="contact-us-heading-p">
                Our full representation service includes preparing, lodging, and
                managing your application from start to finish. We handle every
                detail of your migration process, ensuring a smooth and
                successful journey towards achieving your visa goals.
              </p>
              <p className="contact-us-heading-p ">
                We will take complete control of your application, offering
                guidance and assistance throughout the entire process. This
                includes communicating with the Department on your behalf and
                managing any post-lodgement issues or requests for further
                information. Our comprehensive service ensures that every aspect
                of your application is handled professionally and efficiently,
                providing you with peace of mind and the best chance for a
                successful outcome.
              </p>
              <p>
                <span>
                  <b>Benefits of the Full Representation Service include:</b>
                </span>
                <p>
                  <ul>
                    <li>
                      <strong>Dedicated Migration Agent:</strong>A personal
                      expert to manage your application and address all your
                      questions throughout the process.
                    </li>
                    <li>
                      <strong>Personalized Assessment:</strong> Evaluation of
                      your unique circumstances to determine eligibility, the
                      best migration pathway, and potential challenges.
                    </li>
                    <li>
                      <strong>Documentary Evidence Guidance:</strong>Advice on
                      the necessary documents to support your application.
                    </li>
                    <li>
                      <strong>Document Review:</strong>Comprehensive feedback on
                      each document included in your application.
                    </li>
                    <li>
                      <strong>Application Preparation:</strong>Assistance with
                      completing application forms and drafting legal
                      submissions to the Department.
                    </li>
                    <li>
                      <strong>Liaison with Authorities:</strong>Communicating
                      with the Department and relevant authorities on your
                      behalf until a decision is made.
                    </li>
                  </ul>
                </p>
              </p>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <h3>Review Applications (Appeals & Reviews)</h3>
              <p className="contact-us-heading-p ">
                Our Full Representation service also supports clients who have
                had a visa refused or cancelled, offering assistance in seeking
                a review of the decision at the Administrative Appeals Tribunal
                (AAT). We provide expert guidance and representation to help you
                navigate this complex process and improve your chances of a
                successful outcome.
              </p>
              <p className="contact-us-heading-p mt-3">
                <h2>We can help you by:</h2>
              </p>
              <p>
                <ul>
                  <li>
                    <strong>Submitting a valid review application.</strong>
                    Ensuring all requirements are met for a valid review.
                  </li>
                  <li>
                    <strong>
                      Identify the best strategy based on the facts of your
                      case.
                    </strong>
                    Identifying the best strategy based on your case's specific
                    facts.
                  </li>
                  <li>
                    <strong>
                      Prepare legal submissions in support to your case.
                    </strong>
                    Preparing detailed legal submissions to support your case.
                  </li>
                  <li>
                    <strong>
                      Advise on the documentary evidence to support your
                      application for review.
                    </strong>
                    Advising on the necessary documents to bolster your review
                    application.
                  </li>
                  <li>
                    <strong>
                      Support you during any interviews and tribunal hearings.
                    </strong>
                    Providing guidance and representation during interviews and
                    tribunal hearings.
                  </li>
                  <li>
                    <strong>
                      Liaising with the Tribunal on your behalf and until your
                      application is decided.
                    </strong>
                    Communicating with the Tribunal on your behalf until a
                    decision is made.
                  </li>
                </ul>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>

      <h2 className="choose-us-title s-card-heading mt-5">
        <b className="span-text">Consultation</b> Types
      </h2>
      <div className="row m-5 ">
        <div className="col-lg-3 col-md-6 col-sm-12 ">
          <div className="card mb-3">
            <div className="card-body s-card-heading initail-consult-card">
              <h2 className="card-title ">Skilled and Training visas</h2>
              <p className="card-text">
                At Real Migration And Education, we thoroughly analyze your
                migration goals, qualifications, and work experience to assess
                your eligibility for skilled visas and create a tailored
                migration roadmap to achieve your immigration objectives.
              </p>
              <p>
                <h2>
                  <b>$150</b>
                </h2>
              </p>
              <Link to="/appointment" className="btn btn-enquire mt-4">
                Book Now
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="card mb-3">
            <div className="card-body s-card-heading initail-consult-card">
              <h2 className="card-title ">Employer-Sponsored visas</h2>
              <p className="card-text">
                Real Migration And Education helps business sponsors and visa
                applicants assess their eligibility for employer-sponsored visa
                programs, guiding you through the process and exploring your
                options.
              </p>
              <p>
                <h2>
                  <b>$150</b>
                </h2>
              </p>
              <Link to="/appointment" className="btn btn-enquire mt-4">
                Book Now
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="card mb-3">
            <div className="card-body s-card-heading initail-consult-card">
              <h2 className="card-title ">Family and Partner visas</h2>
              <p className="card-text">
                Real Migration And Education assesses your unique circumstances
                to find the best migration pathway to Australia for you and your
                family. We specialize in Partner, Parent, and Child visas,
                providing personalized guidance to meet your needs.
              </p>
              <p>
                <h2>
                  <b>$150</b>
                </h2>
              </p>
              <Link to="/appointment" className="btn btn-enquire mt-4">
                Book Now
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="card mb-3">
            <div className="card-body s-card-heading initail-consult-card">
              <h2 className="card-title ">
                AAT review, Refusal & Cancellation matter
              </h2>
              <p className="card-text">
                In our initial consultation, we aim to fully understand the
                assistance you are seeking and discuss your situation in detail
                with a Registered Migration Agent, ensuring we address all
                aspects of your case.
              </p>
              <p>
                <h2>
                  <b>$150</b>
                </h2>
              </p>
              <Link to="/appointment" className="btn btn-enquire mt-4">
                Book Now
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* ................visa qualify............ */}
      <Row lg={12} className="subclass-189-vq-row">
        <p>
          <h3 className="mt-5">Consultation time is 60 minutes.</h3>
        </p>
        <p>
          After the online consultation, you receive a Professional Consultation
          Report (10-15 pages) with the key points that have been discussed.
        </p>
        <p>
          Should you have any questions, send us an email:
          info@realmigration.com.au, or use our contact form.
        </p>
        <div className="mb-5">
          <Button
            className="subclass-189-vq-button subclass-189-vq-btn-margin"
            href="mailto:info@realmigration.com.au"
          >
            Send Email
          </Button>
          <Button
            className="subclass-189-vq-button subclass-189-vq-btn-margin"
            href="/contact"
          >
            Fill Contact Form
          </Button>
        </div>
      </Row>
    </Layout>
  );
};

export default FullRepresentation;
