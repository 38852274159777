import React from "react";
import Layout from "../../../../components/Layout/Layout";
import { Row, Col, Button } from "react-bootstrap";
import NOICC from "../../../../images/visaImages/NOICC_1.png";

const Noicc = () => {
  return (
    <Layout
      title="Complete Guide to Contributory Parent Visa Subclass 173: Eligibility, Benefits, and Application Process"
      description="Learn all about the Contributory Parent Visa Subclass 173. Understand eligibility criteria, benefits, application process, and more to join your family in Australia."
      keywords="Contributory Parent Visa 173, Australian Parent Visa, Subclass 173 Visa, Parent Visa Eligibility, Australia Family Visa, Parent Visa Benefits, Parent Visa Application Process"
    >
      <>
        <Row className="m-5">
          <Col lg={6} md={6} sm={12}>
            <img
              src={NOICC}
              alt="skilledindependent-visa-189"
              className="img-fluid subclass-189-image"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <h2 className="subclass-189-h1">
              Notice of Intention
              <span className="subclass-189-h1-span">(NOICC)</span>
            </h2>

            <p className="subclass-189-p">
              A Notice of Intention to Consider Cancellation (NOICC) may be
              issued if there are grounds for cancelling a visa under the
              Migration Act 1958. The Department of Home Affairs will send you a
              NOICC, giving you the opportunity to respond to the information
              provided.
            </p>
            <Button href="/appointment" className="mt-3 contact-us-call-button">
              Book Your Consultation
            </Button>
          </Col>
        </Row>

        {/* //tab section */}
        <div className="row m-5 ">
          <div className="col-12">
            <div className="choose-us-title-view">
              <h2 className="choose-us-title">
                Notice of Intention to Consider Cancellation
                <b className="span-text">(NOICC)</b> info about !!
              </h2>
            </div>
            <p className="choose-us-text subclass-189-page-font">
              There are very few visas that an unlawful non-citizen can apply
              for after a visa is cancelled. Depending on the reason for
              cancellation, they will likely have difficulties being granted
              another visa to return to Australia.
            </p>
            <p className="choose-us-text subclass-189-page-font">
              Contact us and let our team make a good, solid response to the
              Department within the timeline given.
            </p>
            <p className="choose-us-text subclass-189-page-font">
              Do not waste your time, and do not risk giving a response without
              proper advice and guidance. Our team is experienced in helping our
              clients look into all the issues surrounding the grounds for visa
              cancellation.
            </p>
          </div>
        </div>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h3 className="subclass-189-pt-h1">
              In what circumstances have NOICC been issued?
            </h3>

            <p className="subclass-189-p subclass-189-pt-col">
              <p className="subclass-189-li">
                There are a few situations where a NOICC may be issued to a visa
                holder in Australia. The Migration Act 1958 contains a number of
                different reasons for visa cancellation in Australia which
                include:
              </p>
              <ul>
                <li className="subclass-189-li">
                  Provision of incorrect information;
                </li>
                <li className="subclass-189-li">
                  Provision of bogus documents or false information to mislead
                  the Department;
                </li>
                <li className="subclass-189-li">
                  Visa conditions being breached or other requirements not being
                  met;
                </li>
                <li className="subclass-189-li">
                  Failure of a business skills visa holder to establish the
                  business or participate in management;
                </li>
                <li className="subclass-189-li">
                  The person is of character concern;
                </li>
                <li className="subclass-189-li">
                  A student visa holder does not meet the course attendance and/
                  or academic performance visa condition;
                </li>
                <li className="subclass-189-li">
                  The holder of a Regional Sponsored Migration Scheme (RSMS)
                  Visa either did not commence work within six months or did not
                  complete two years of employment with their employer;
                </li>
                <li className="subclass-189-li">
                  Circumstances in which the visa was granted no longer exist.
                </li>
              </ul>
              <p className="subclass-189-li">
                Generally, the power to cancel a visa is discretionary. This
                means that, even though there are grounds for cancellation, the
                Department does not have to cancel the visa. There are, however,
                some circumstances in which a visa must be cancelled.
              </p>
            </p>

            {/* .......point test............. */}
            {/* <h3 className="subclass-189-pt-h1">
              What visa-related powers does the AAT have?
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <p>
                  As previously noted, the AAT possesses the authority to assess
                  various migration-related decisions independently.
                </p>
                <li className="subclass-189-li">
                  All documentation, evidence, forms and interview records held
                  by the Department of Home Affairs
                </li>
                <li className="subclass-189-li">
                  All submissions or evidence submitted by you to the AAT
                </li>
              </ul>
              The AAT also wields independent investigative powers. Should the
              AAT uncover any adverse information concerning you or any party
              involved in the visa refusal appeal, they will inform you in
              writing and solicit your comments on this information. It is
              advisable to seek legal counsel in such situations.
            </p> */}
          </Col>
          {/* ..........related visa card ............... */}
          {/* <Col lg={3} md={4} sm={12}>
                <div className="subclass-189-rv-card-shadow">
                  <div className="subclass-189-rv-card-head">
                    <h5 className="subclass-189-rv-card-h">Related Visas</h5>
                  </div>
                  <p className="subclass-189-rv-card-p">
                    <ul>
                      <li className="subclass-189-li-a">
                        <a
                          href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/parent-103"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Subclass 103 Parent visa
                        </a>
                      </li>
                      <li className="subclass-189-li-a">
                        <a
                          href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/aged-parent-804"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Subclass 804 Aged Parent visa
                        </a>
                      </li>
    
                      <li className="subclass-189-li-a">
                        <a
                          href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/contributory-parent-143"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          (Subclass 143) Contributory Parent visa
                        </a>
                      </li>
    
                      <li className="subclass-189-li-a">
                        <a
                          href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/contributory-aged-parent-temporary-884"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Subclass 884 Contributory Aged Parent (Temporary) visa
                        </a>
                      </li>
                      <li className="subclass-189-li-a">
                        <a
                          href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/contributory-parent-temporary-173"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Subclass 173 Contributory Parent (Temporary) visa
                        </a>
                      </li>
                    </ul>
                  </p>
                </div>
              </Col> */}
        </Row>

        {/* ................visa qualify............ */}
        <Row lg={12} className="subclass-189-vq-row">
          <h3 className="mt-5">Do you think you qualify for this visa?</h3>
          <p>
            Contact us if you would like to know more about this visa or need
            immigration advice.
          </p>
          <div className="mb-5">
            <Button
              href="/contact"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Contact
            </Button>
            <Button
              href="/appointment"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Book A Consultation
            </Button>
            <Button
              href="/initial-assessment-consultation"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Our Prices
            </Button>
          </div>
        </Row>

        {/* <Row>
                                      <Col>
                                        <div className="row m-5">
                                          <h1 className="home-h1">
                                            Offshore Partner Visa (Subclass 309/100) Two Stage:
                                          </h1>
                                          <div className="col-lg-6 col-md-12">
                                            <h5 className="subclass-189-pt-h1">
                                              Partner visa (Temporary) Visa 309
                                            </h5>
                                            <p className="subclass-189-p subclass-189-pt-col">
                                              
                                              Must be outside of Australia to apply for this visa. Prior to
                                              applying, gather all required paperwork, including health,
                                              character, and sponsorship information. Use the online
                                              application process to apply for the visa when you are away
                                              from home.
                                            </p>
                                          </div>
                                          <div className="col-lg-6 col-md-12">
                                            <h5 className="subclass-189-pt-h1">
                                              Partner visa (Permanent) Visa 100
                                            </h5>
                                            <p className="subclass-189-p subclass-189-pt-col">
                                              
                                              There is no need to provide any more information if Subclass
                                              309 of the Visa comes right after Subclass 100. However, if a
                                              Visa Subclass 100 is not immediately issued, the authorities
                                              must be given the necessary information. Two years following
                                              application lodging, it will be assessed; submit the required
                                              paperwork one month beforehand. Submission of an application
                                              through an online system.
                                            </p>
                                          </div>
                                         
                                        </div>
                                      </Col>
                                    </Row> */}

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h5 className="subclass-189-pt-h1">
              What can you do when you receive a NOICC?
            </h5>

            <p className="subclass-189-p subclass-189-pt-col">
              <p>
                In most cases, the visa holder will be given prior warning of
                the Department’s intention to cancel the visa, and an
                opportunity to respond. The time frame to respond varies, and in
                most cases, the time given is five working days from the day you
                are perceived to have received the NOICC. Any response to the
                the NOICC, including comments or further evidence, must be in
                writing in English (or accompanied by an accurate English
                translation) to appeal why your visa should not be cancelled.
                The delegate will take into account matters such as:
              </p>
              <ul>
                <li className="subclass-189-li">
                  The purpose of your travel to or stay in Australia;
                </li>
                <li className="subclass-189-li">
                  The extent of your visa compliance with any visa conditions;
                </li>
                <li className="subclass-189-li">
                  The degree of hardship that may be caused to you and any
                  family members if the visa is cancelled;
                </li>
                <li className="subclass-189-li">
                  The circumstances in which the ground for cancellation arose;
                </li>
                <li className="subclass-189-li">
                  Your behaviour in relation to the department, now and on
                  previous occasions;
                </li>
                <li className="subclass-189-li">
                  Whether there are any persons in Australia whose visas would,
                  or may be cancelled if your visa is cancelled;
                </li>
                <li className="subclass-189-li">
                  Whether there are any mandatory legal consequences to a
                  decision to cancel your visa, such as detention and removal
                  from Australia;
                </li>
                <li className="subclass-189-li">
                  Whether Australia has obligations under relevant international
                  agreements that would be breached as a result of the
                  cancellation decisions;
                </li>
                <li className="subclass-189-li">Any other relevant matters.</li>
              </ul>
              <p>
                If a visa is cancelled by the Department of Home Affairs, the
                decision may be eligible for review by a tribunal, depending on
                the cancellation power used and whether the individual was in
                Australia at the time of cancellation. If tribunal review is not
                available, judicial review in a court may be an option.
              </p>
              <p>
                Upon visa cancellation, if the person does not hold another
                valid visa, they become an unlawful non-citizen and must leave
                Australia. They may be detained and removed unless they can
                obtain another visa, seek a review of the cancellation decision,
                or depart Australia voluntarily.
              </p>
              <p>
                The right to merits review is determined by the specific
                cancellation power and the person's location during the
                cancellation. For those who cannot access merits review,
                judicial review in a court offers a legal pathway to challenge
                the decision.
              </p>
              <p>
                Being an unlawful non-citizen has serious implications,
                including detention and removal from Australia. It's crucial to
                act quickly by seeking legal advice, applying for another visa
                if eligible, or lodging a review application. Taking prompt
                action can help manage the consequences and explore possible
                options to remain in Australia legally.
              </p>
            </p>

            {/* <h3 className="subclass-189-pt-h1">
              What decisions can the AAT make?
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <p>
                  The Administrative Appeals Tribunal member will decide to
                  affirm, vary, set aside, or remit the decision made by the
                  Department of Home Affairs.
                </p>
                <li className="subclass-189-li">
                  If the AAT affirms the decision, it means the decision made by
                  the Department of Home Affairs is not changed. If this happens
                  you will need to immediately consider your next best steps
                  such as appealing the decision to court, applying for another
                  visa or leaving Australia
                </li>
                <li className="subclass-189-li">
                  If the AAT varies a decision, it means the decision made by
                  the Department of Home Affairs is changed or altered in some
                  way
                </li>
                <li className="subclass-189-li">
                  If the AAT sets aside a decision, it means the AAT agrees or
                  partially agrees the original decision made by the Department
                  of Home Affairs was wrong
                </li>
                <li className="subclass-189-li">
                  If the AAT remits a decision, it means it sends the matter
                  back to the Department of Home Affairs to make a new decision
                  in accordance with the AAT’s instructions or recommendations.
                  This means you won your appeal case! Generally if your matter
                  is remitted, you will need to wait to hear from the Department
                  of Home Affairs before your visa is granted.
                </li>
              </ul>
            </p> */}

            {/* <h3 className="subclass-189-pt-h1">
                  English Language Requirements
                </h3>
                <p>
                  To meet the English language requirement, you must demonstrate
                  competent English. This can be done through:
                </p>
                <p className="subclass-189-p subclass-189-pt-col">
                  <ul>
                    <li className="subclass-189-li">
                      <strong>IELTS:</strong>A minimum score of 6 in each component
                      (Listening, Reading, Writing, and Speaking).
                    </li>
                    <li className="subclass-189-li">
                      <strong>TOEFL iBT:</strong>A minimum score of 12 in Listening,
                      13 in Reading, 21 in Writing, and 18 in Speaking.
                    </li>
                    <li className="subclass-189-li">
                      <strong>PTE Academic:</strong>A minimum score of 50 in each
                      component (Listening, Reading, Writing, and Speaking).
                    </li>
                  </ul>
                </p> */}
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Google Adds</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>

            <div className="subclass-189-rv-card-shadow mt-5">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Latest Post</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </>
    </Layout>
  );
};

export default Noicc;
