import React from "react";
import Layout from "../../../components/Layout/Layout";
import { Card, Button } from "react-bootstrap"; // Importing Bootstrap components for styling

const DetailPage = () => {
  const jobDetail = {
    jobTitle: "Enrolled Nurse (Visa 482 Sponsorship Available)",
    location: "Queensland (QLD)",
    description:
      "Our client is currently seeking an experienced and dedicated Enrolled Nurse with a minimum of 2 years of experience to join our growing team in Queensland. This is a fantastic opportunity for skilled nurses looking to further their career in a supportive and positive working environment.",
    visaInfo: "Visa 482 sponsorship is available for the right candidate!",
    aboutRole: {
      location: "Queensland, Australia",
      visaSponsorship: "Temporary Skill Shortage (TSS) Visa 482",
      experienceRequired:
        "Minimum of 2 years of professional nursing experience",
      training:
        "Comprehensive training and ongoing professional development provided",
      culture:
        "Join a team with a great culture that values respect, teamwork, and continuous growth.",
    },
    keyResponsibilities: [
      "Deliver high-quality nursing care to patients.",
      "Work collaboratively with a multidisciplinary team.",
      "Ensure the health and well-being of patients are prioritized.",
      "Maintain accurate and up-to-date patient records.",
      "Support senior staff and contribute to a positive team environment.",
    ],
    requirements: [
      "Minimum 2 years of nursing experience (essential).",
      "Valid AHPRA registration as an Enrolled Nurse.",
      "Strong communication and interpersonal skills.",
      "Ability to work well within a team and contribute to a supportive work environment.",
      "Willingness to undergo training and continuous professional development.",
    ],
    whyJoinUs: [
      "Supportive and inclusive work culture.",
      "Ongoing professional training and growth opportunities.",
      "Competitive salary and benefits.",
      "Visa 482 sponsorship for qualified candidates.",
      "Opportunity to work in a beautiful location with a dedicated and passionate team.",
    ],
    howToApply:
      "To apply, please send your resume to info@realmigration.com.au.",
  };

  return (
    <Layout
      title="Job Detail - Enrolled Nurse"
      description="Detailed description of the Enrolled Nurse job role with Visa 482 sponsorship."
    >
      <div className="container-fluid" style={{ padding: "20px" }}>
        <h1
          style={{
            textAlign: "center",
            marginBottom: "20px",
            fontSize: "2rem",
          }}
        >
          {jobDetail.jobTitle}
        </h1>

        {/* Card Component */}
        <Card
          className="mx-auto"
          style={{ width: "100%", maxWidth: "800px", padding: "20px" }}
        >
          <Card.Body>
            {/* Job Description */}
            <Card.Text style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
              {jobDetail.description}
            </Card.Text>

            <Card.Title
              style={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                marginTop: "20px",
              }}
            >
              About the Role
            </Card.Title>
            <Card.Text style={{ fontSize: "1rem", marginBottom: "10px" }}>
              <strong>Location:</strong> {jobDetail.aboutRole.location}
            </Card.Text>
            <Card.Text style={{ fontSize: "1rem", marginBottom: "10px" }}>
              <strong>Visa Sponsorship:</strong>{" "}
              {jobDetail.aboutRole.visaSponsorship}
            </Card.Text>
            <Card.Text style={{ fontSize: "1rem", marginBottom: "10px" }}>
              <strong>Experience Required:</strong>{" "}
              {jobDetail.aboutRole.experienceRequired}
            </Card.Text>
            <Card.Text style={{ fontSize: "1rem", marginBottom: "10px" }}>
              <strong>Training:</strong> {jobDetail.aboutRole.training}
            </Card.Text>
            <Card.Text style={{ fontSize: "1rem", marginBottom: "10px" }}>
              <strong>Culture:</strong> {jobDetail.aboutRole.culture}
            </Card.Text>

            <h2
              style={{
                marginTop: "30px",
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              Key Responsibilities
            </h2>
            <ul style={{ fontSize: "1rem" }}>
              {jobDetail.keyResponsibilities.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>

            <h2
              style={{
                marginTop: "30px",
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              Requirements
            </h2>
            <ul style={{ fontSize: "1rem" }}>
              {jobDetail.requirements.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>

            <h2
              style={{
                marginTop: "30px",
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              Why Join Us
            </h2>
            <ul style={{ fontSize: "1rem" }}>
              {jobDetail.whyJoinUs.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>

            <h2
              style={{
                marginTop: "30px",
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              How to Apply
            </h2>
            <Card.Text style={{ fontSize: "1rem" }}>
              {jobDetail.howToApply}
            </Card.Text>

            <Button variant="primary" href="mailto:info@realmigration.com.au">
              Apply Now
            </Button>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default DetailPage;
