import React from "react";
import "./PageNotFound.css";
import Layout from "../../components/Layout/Layout";
import { Link } from "react-router-dom";
const PageNotFound = () => {
  return (
    <Layout title={"page not found -At real migration"}>
      <div className="pnf">
        <h1 className="pnf-tittle">404</h1>
        <h2 className="pnf-heading">Oops ! Page Not Found</h2>
        <Link to="/" className="pnf-button">
          Go Back
        </Link>
      </div>
    </Layout>
  );
};

export default PageNotFound;
