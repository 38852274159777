import AdminLayout from '../../../../components/AdminLayout/AdminLayout';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Table } from "react-bootstrap";

export default function ViewAppointment() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}booking/allbookings`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  return (
    <AdminLayout>
      <Container>
        <h1 style={{ padding: "10px" }}>Appointments</h1>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Agent MARN</th>
              <th>Service ID</th>
              <th>Date</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {data.map((booking, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{booking.customerFirstName}</td>
                <td>{booking.customerLastName}</td>
                <td>{booking.customerEmail}</td>
                <td>{booking.customerPhoneNumber}</td>
                <td>{booking.agentMARN}</td>
                <td>{booking.serviceId}</td>
                <td>{booking.date}</td>
                <td>{booking.time}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </AdminLayout>
  );
}
