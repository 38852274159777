import React from "react";
import Layout from "../../../../components/Layout/Layout";
import { Row, Col, Button } from "react-bootstrap";
import visa_487 from "../../../../images/visaImages/visa_887_1.png";

import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import "./SkilledResdence_887.css";

const SkilledResdence_887 = () => {
  return (
    <Layout
      title="Complete Guide to the Subclass 887 Skilled Residence Visa - Eligibility, Benefits, and Application Process"
      description="Learn all about the Subclass 887 Skilled Residence Visa, including eligibility criteria, benefits, and the application process. Transition from provisional to permanent residency in regional Australia."
      keywords="Subclass 887 Skilled Residence Visa"
    >
      <>
        <Row className="m-5">
          <Col lg={6} md={6} sm={12}>
            <img
              src={visa_487}
              alt="skilledindependent-visa-189"
              className="img-fluid subclass-189-image"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <h2 className="subclass-189-h1">
              Skilled Residence Visa{" "}
              <span className="subclass-189-h1-span">(Subclass 887)</span>
            </h2>

            <p className="subclass-189-p">
              Are you a skilled professional eager to contribute your expertise
              to regional Australia?
              <a href="#" className="subclass-189-a">
                Start your journey by applying for your Visa 887 online today!
              </a>
              Discover the advantages of the Skilled Regional Visa (Subclass
              887), which allows you to live and work permanently in regional
              Australia, supported by state or territory nomination. Enjoy the
              benefits and vibrant lifestyle of one of the world's most dynamic
              regional economies.
            </p>
            <Button href="/contact" className="mt-3 contact-us-call-button">
              Call Now
            </Button>
          </Col>
        </Row>

        {/* //tab section */}
        <div className="row m-5 ">
          <div className="col-12">
            <div className="choose-us-title-view">
              <h2 className="choose-us-title">
                Skilled Residence Visa{" "}
                <b className="span-text">(Subclass 887)</b> Overview
              </h2>
            </div>
            <p>
              The <b>Subclass 887 Skilled Residence Visa</b> is a permanent visa
              for skilled workers who have lived and worked in specified
              regional areas of Australia while holding an eligible provisional
              visa. This visa allows you and your family to live in Australia
              permanently and enjoy the benefits of permanent residency.
            </p>
          </div>
          <div className="col-12 ">
            <ul className="nav nav-tabs custom-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active custom-nav-link"
                  id="success-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#success"
                  type="button"
                  role="tab"
                  aria-controls="success"
                  aria-selected="true"
                >
                  <i className="fas fa-clipboard-list fa-2x"></i>
                  <br />
                  Eligibility Criteria
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="permanent-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#permanent"
                  type="button"
                  role="tab"
                  aria-controls="permanent"
                  aria-selected="false"
                >
                  <i className="fas fa-check-circle fa-2x"></i>
                  <br />
                  Why Visa 887?
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="priority-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#priority"
                  type="button"
                  role="tab"
                  aria-controls="priority"
                  aria-selected="false"
                >
                  <i className="fas fa-gift fa-2x"></i>
                  <br />
                  Visa 887 benefits
                </button>
              </li>
            </ul>
            <div className="tab-content custom-tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active custom-tab-pane"
                id="success"
                role="tabpanel"
                aria-labelledby="success-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Eligibility Criteria for the Subclass 887 Skilled
                      Residence Visa
                    </h3>
                    <p>
                      To qualify for the Subclass 887 visa, you must meet the
                      following requirements:
                    </p>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Current Visa Status:</strong>
                          Hold an eligible provisional visa such as the Subclass
                          489, 495, 496, 475, 487, or Bridging Visa A or B after
                          applying for a Subclass 489, 495, or 487 visa.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Residence Requirement:</strong>
                          Have lived in a specified regional area of Australia
                          for at least two years.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Compliance with Visa Conditions:</strong>
                          Must have complied with the conditions of your
                          provisional visa, including residence and work
                          conditions in designated regional areas.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Health and Character Requirements:</strong>
                          Meet the health and character requirements specified
                          by the Australian government.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Work Requirement::</strong>
                          Have worked full-time (at least 35 hours per week) in
                          a specified regional area for at least one year.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="permanent"
                role="tabpanel"
                aria-labelledby="permanent-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Why Choose the Skilled Independent Visa (Subclass 887)?
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Pathway to Permanent Residency:</strong>
                          Visa 887 offers a direct pathway to permanent
                          residency in Australia for holders of eligible
                          provisional visas.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Flexibility and Freedom:</strong>
                          It provides flexibility to live and work anywhere in
                          regional Australia, catering to diverse lifestyle
                          preferences and career opportunities.
                        </li>
                        <li className="subclass-189-li">
                          <strong>No Points Test:</strong>
                          Unlike initial provisional visas, Visa 887 does not
                          require applicants to meet a points test, facilitating
                          a streamlined application process.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Minimal Residency Requirement:</strong>
                          Applicants need to reside in regional Australia for
                          only 2 years and work full-time for at least 12 months
                          during this period.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Family Inclusion:</strong>
                          The visa allows for the inclusion of family members,
                          providing them with access to education, healthcare,
                          and employment opportunities in regional areas.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="priority"
                role="tabpanel"
                aria-labelledby="priority-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>Benefits of Skilled Independent Visa 887</h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Access to Medicare:</strong>
                          Gain access to Australia's public healthcare system,
                          Medicare, ensuring comprehensive health coverage.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Social Security Benefits:</strong>
                          Eligibility for various social security benefits and
                          programs provided by the Australian government.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Pathway to Citizenship:</strong>
                          Fulfill residency and other requirements to apply for
                          Australian citizenship, enjoying the rights and
                          privileges as an Australian citizen.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Sponsorship Opportunities:</strong>
                          Opportunity to sponsor eligible family members for
                          permanent residency, fostering family reunification.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h3 className="subclass-189-pt-h1">Application Process</h3>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Ensure you meet the eligibility criteria for Visa 887,
                  including holding an eligible provisional visa and fulfilling
                  residency and work requirements in regional Australia.
                </li>
                <li className="subclass-189-li">
                  Collect necessary documents such as proof of identity,
                  residency in regional Australia, employment history, and
                  family details.
                </li>
                <li className="subclass-189-li">
                  Apply online through the Department of Home Affairs' portal,
                  providing accurate information and uploading required
                  documents.
                </li>
                <li className="subclass-189-li">
                  Submit your completed application and pay the relevant fees.
                  Ensure all information is accurate and up to date to avoid
                  delays.
                </li>
                <li className="subclass-189-li">
                  Wait for the Department of Home Affairs to process your
                  application. They may request additional information or
                  conduct checks as part of the assessment process.
                </li>
                <li className="subclass-189-li">
                  Upon approval, you will receive a notification of visa grant.
                  Review the conditions of your visa and plan your move to enjoy
                  the benefits of permanent residency in regional Australia.
                </li>
              </ul>
            </p>

            {/* .......point test............. */}
            <h3 className="subclass-189-pt-h1">Points Test</h3>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Age: 25-32 years (30 points), 18-24 & 33-39 years (25 points),
                  etc.
                </li>
                <li className="subclass-189-li">
                  English Language Ability: Superior (20 points), Proficient (10
                  points).
                </li>
                <li className="subclass-189-li">
                  Skilled Employment: In Australia or overseas, up to 20 points
                  depending on years of experience.
                </li>
                <li className="subclass-189-li">
                  Educational Qualifications: Doctorate (20 points),
                  Bachelor’s/Master’s (15 points),Diploma or trade
                  qualification: 10 points
                </li>
                <li className="subclass-189-li">
                  Other Factors: Australian study requirement, specialist
                  education qualification, accredited community language, etc.
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Related Visas</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="/skilledindependent-visa-189"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled independent visa (Subclass 189)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="/skilled-nominated-visa-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated Visa (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="/permanent-residence-visa-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="/skilled-work-visa-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>

        {/* ................visa qualify............ */}
        <Row lg={12} className="subclass-189-vq-row">
          <h3 className="mt-5">Do you think you qualify for this visa?</h3>
          <p>
            Contact us if you would like to know more about this visa or need
            immigration advice.
          </p>
          <div className="mb-5">
            <Button
              href="/contact"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Contact
            </Button>
            <Button
              href="/appointment"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Book A Consultation
            </Button>
            <Button
              href="/initial-assessment-consultation"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Our Prices
            </Button>
          </div>
        </Row>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h5 className="subclass-189-pt-h1">
              Visa Conditions and Obligations for Subclass 887 (Skilled Regional
              Visa)
            </h5>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Residency Requirement:</strong>
                  You must have lived in a designated regional area of Australia
                  for at least 2 years while holding an eligible provisional
                  visa.
                </li>
                <li className="subclass-189-li">
                  <strong>Work Requirement::</strong>
                  During your residency period, you must have worked full-time
                  in a designated regional area of Australia for at least 12
                  months.
                </li>
                <li className="subclass-189-li">
                  <strong>Compliance with Australian Laws:</strong>
                  You must comply with all Australian laws and regulations
                  during your stay, including those related to work, health, and
                  character.
                </li>
                <li className="subclass-189-li">
                  <strong>Notification of Changes:</strong>
                  Notify the Department of Home Affairs of any changes in your
                  circumstances, such as changes in address or employment
                  status.
                </li>
                <li className="subclass-189-li">
                  <strong>Stay in Designated Areas:</strong>
                  Live, work, and study only in designated regional areas of
                  Australia as specified by the visa conditions.
                </li>
                <li className="subclass-189-li">
                  <strong>Fulfillment of Visa Conditions:</strong>
                  Ensure you fulfill all visa conditions to maintain your
                  eligibility for permanent residency under Visa 887.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">
              Express Your Interest (EOI) in SkillSelect for Subclass 887 Visa
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Submitting an EOI:</strong>
                  To apply for the Subclass 887 Visa, create a SkillSelect
                  account, complete the EOI with details of your skills and
                  regional residency, and submit it for Department of Home
                  Affairs review, paving the way for permanent residency in
                  regional Australia.
                </li>
                <li className="subclass-189-li">
                  <strong>Invitation Rounds:</strong>
                  During invitation rounds, the Department of Home Affairs
                  reviews EOIs and invites candidates with the highest points
                  scores to apply for the Subclass 887 Visa. This ensures
                  applicants with relevant skills in regional Australia can
                  progress towards permanent residency.
                </li>
                <li className="subclass-189-li">
                  <strong>State or Territory Nomination:</strong>
                  Expressing interest in state or territory nomination for the
                  Subclass 887 Visa underscores your commitment to regional
                  contributions, enhancing your application for permanent
                  residency in Australia.
                </li>
                <li className="subclass-189-li">
                  <strong>Updating Your EOI:</strong>
                  Keeping your EOI accurate and current is essential for the
                  Subclass 887 Visa application. Regular updates reflect changes
                  and achievements, improving your points score and enhancing
                  your prospects for an invitation from the Department of Home
                  Affairs.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">
              English Language Requirements
            </h3>
            <p>
              To meet the English language requirement, you must demonstrate
              competent English. This can be done through:
            </p>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>IELTS:</strong>A minimum score of 6 in each component
                  (Listening, Reading, Writing, and Speaking).
                </li>
                <li className="subclass-189-li">
                  <strong>TOEFL iBT:</strong>A minimum score of 12 in Listening,
                  13 in Reading, 21 in Writing, and 18 in Speaking.
                </li>
                <li className="subclass-189-li">
                  <strong>PTE Academic:</strong>A minimum score of 50 in each
                  component (Listening, Reading, Writing, and Speaking).
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Google Adds</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>

            <div className="subclass-189-rv-card-shadow mt-5">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Latest Post</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </>
      {/* faqs section */}
      <div className="row testimonial-background visa_question-acc">
        <div className="col-lg-12 cl-sm-12">
          <div className="testimonial-heading">
            {/* <h1 className="home-h1">Success Stories</h1> */}
            <h4 className="about-us-h4">
              FAQs about the Australia Skilled Work Regional (Provisional) Visa,
              Subclass 887
            </h4>
          </div>

          <>
            <Row>
              <Col lg={12} className="">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      What is the Subclass 887 Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      The Subclass 887 Visa is a permanent visa for skilled
                      workers who have lived and worked in specified regional
                      areas of Australia while holding an eligible provisional
                      visa, such as the Subclass 489, 495, 496, 475, or 487
                      visas.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How long do I need to live and work in a regional area to
                      qualify for the Subclass 887 Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      You must have lived in a specified regional area for at
                      least two years and worked full-time in that area for at
                      least one year.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Can I include my family members in my Subclass 887 Visa
                      application?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can include immediate family members, such as
                      your spouse or de facto partner and dependent children, in
                      your visa application.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What is the processing time for the Subclass 887 Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Processing times can vary, but it typically takes several
                      months. Check the latest processing times on the
                      Department of Home Affairs website.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Can I travel while my Subclass 887 Visa application is
                      being processed?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can travel in and out of Australia while your
                      visa application is being processed, as long as you hold a
                      valid visa.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What happens if my Subclass 887 Visa application is
                      refused?
                    </Accordion.Header>
                    <Accordion.Body>
                      If your application is refused, you may have the right to
                      seek a review of the decision. Details on how to appeal
                      will be provided in the refusal notification.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Do I need a nomination from a state or territory for the
                      Subclass 887 Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, a nomination is not required for the Subclass 887
                      Visa. The nomination is necessary for the initial
                      provisional visas like the Subclass 489. However, meeting
                      the conditions of your provisional visa is crucial.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      Can I apply for the Subclass 887 Visa if I hold a Bridging
                      Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can apply for the Subclass 887 Visa if you hold a
                      Bridging Visa A or B and have previously held an eligible
                      provisional visa.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      How does the Subclass 887 Visa differ from the Subclass
                      191 Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Both visas offer pathways to permanent residency. The
                      Subclass 887 Visa is specifically for those who have held
                      certain provisional visas and met residence and work
                      requirements in regional areas, while the Subclass 191
                      Visa applies to holders of the Subclass 491 or Subclass
                      494 visas.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      What if I cannot meet the income requirement for the
                      Subclass 887 Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      The Subclass 887 Visa does not have a specific income
                      threshold requirement. Instead, it focuses on meeting the
                      residence and work conditions during your provisional visa
                      period.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </>
        </div>
      </div>
    </Layout>
  );
};

export default SkilledResdence_887;
