import React from "react";
import Layout from "../../../../components/Layout/Layout";
import { Row, Col, Button } from "react-bootstrap";
import visa_820 from "../../../../images/visaImages/visa_820_1.png";

import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import "./OnShurePVisa_820.css";

const OnShurePVisa_820 = () => {
  return (
    <Layout
      title=" Australia Onshore Partner Visa (Subclass 820/801) - Eligibility, Benefits, and Application Process"
      description="Explore the comprehensive guide to the Australia Onshore Partner Visa (Subclass 820/801). Learn about the eligibility criteria, benefits, application process, conditions, obligations, and FAQs."
      keywords="Australia Partner Visa, Subclass 820, Subclass 801, onshore partner visa, partner visa benefits, partner visa application process, Australian partner visa conditions, immigration to Australia"
    >
      <>
        <Row className="m-5">
          <Col lg={6} md={6} sm={12}>
            <img
              src={visa_820}
              alt="skilledindependent-visa-189"
              className="img-fluid subclass-189-image"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <h2 className="subclass-189-h1">
              Onshore Partner Visa
              <span className="subclass-189-h1-span">(Subclass 820/801)</span>
            </h2>

            <p className="subclass-189-p">
              Are you planning to join your partner in Australia and build a
              life together?
              <a href="#" className="subclass-189-a">
                Start your journey by applying for your Visa (Subclass 820/801)
                online today!
              </a>
              Take the first step towards a shared future by applying for your
              Onshore Partner Visa (Subclass 820/801) online today! This visa is
              designed for individuals who are married to or in a de facto
              relationship with an Australian citizen, permanent resident, or
              eligible New Zealand citizen. Whether you're looking to live with
              your partner, work in Australia, or eventually become a permanent
              resident and citizen, this visa offers a pathway to a new life
              together in Australia. Enjoy the benefits of being close to your
              loved one and building a stable and fulfilling future in
              Australia.
            </p>
            <Button href="/contact" className="mt-3 contact-us-call-button">
              Call Now
            </Button>
          </Col>
        </Row>

        {/* //tab section */}
        <div className="row m-5 ">
          <div className="col-12">
            <div className="choose-us-title-view">
              <h2 className="choose-us-title">
                Australia Onshore Partner Visa
                <b className="span-text">(Subclass 820/801)</b> Overview
              </h2>
            </div>
            <p>
              The Australia Onshore Partner Visa (Subclass 820/801) allows the
              spouse or de facto partner of an Australian citizen, Australian
              permanent resident, or eligible New Zealand citizen to live in
              Australia. This visa is granted in two stages: the temporary
              Subclass 820 visa and the permanent Subclass 801 visa.
            </p>
          </div>
          <div className="col-12 ">
            <ul className="nav nav-tabs custom-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active custom-nav-link"
                  id="success-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#success"
                  type="button"
                  role="tab"
                  aria-controls="success"
                  aria-selected="true"
                >
                  <i className="fas fa-clipboard-list fa-2x"></i>
                  <br />
                  Eligibility Criteria
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="permanent-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#permanent"
                  type="button"
                  role="tab"
                  aria-controls="permanent"
                  aria-selected="false"
                >
                  <i className="fas fa-check-circle fa-2x"></i>
                  <br />
                  Why Visa 820/801?
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="priority-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#priority"
                  type="button"
                  role="tab"
                  aria-controls="priority"
                  aria-selected="false"
                >
                  <i className="fas fa-gift fa-2x"></i>
                  <br />
                  Visa 820/801 benefits
                </button>
              </li>
            </ul>
            <div className="tab-content custom-tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active custom-tab-pane"
                id="success"
                role="tabpanel"
                aria-labelledby="success-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Eligibility for the Australia Onshore Partner Visa
                      (Subclass 820/801)
                    </h3>
                    <p>
                      To be eligible for the Subclass 820/801 visa, applicants
                      must meet the following criteria:
                    </p>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Relationship:</strong>
                          Be in a genuine and continuing relationship with their
                          sponsoring partner.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Location:</strong>
                          Be in Australia at the time of application.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Health Requirements:</strong>
                          Meet the health requirements set by the Australian
                          government.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Character Requirements:</strong>
                          Meet the character requirements, including police
                          clearances.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Visa History:</strong>
                          Have no history of visa cancellations or refusals.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Relationship Evidence:</strong>
                          Provide evidence of the relationship, such as joint
                          financial statements, cohabitation documents, and
                          social proof.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Commitment:</strong>
                          Demonstrate a mutual commitment to a shared life
                          excluding all others.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="permanent"
                role="tabpanel"
                aria-labelledby="permanent-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>Why Choose the Onshore Partner Visa?</h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Live in Australia: </strong>
                          Ability to live in Australia with your partner.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Work Rights:</strong>
                          Permission to work in Australia without restrictions.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Study Opportunities:</strong>
                          Access to educational opportunities and student loans.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Healthcare Access:</strong>
                          Enrollment in Medicare, Australia’s public health care
                          system.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Travel Flexibility:</strong>
                          Freedom to travel to and from Australia while the visa
                          is being processed.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Family Support:</strong>
                          Ability to include dependent children in the visa
                          application.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Pathway to Permanent Residency: </strong>
                          Eligibility to apply for permanent residency and
                          eventually citizenship.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="priority"
                role="tabpanel"
                aria-labelledby="priority-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Benefits of the Onshore Partner Visa (Subclass 820/801)
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Work Rights: </strong>
                          Ability to work in Australia with no restrictions.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Study Rights:</strong>
                          Access to educational opportunities and student loans.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Healthcare:</strong>
                          Enrollment in Medicare, Australia’s public health care
                          system.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Travel:</strong>
                          Freedom to travel in and out of Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Family Inclusion: </strong>
                          Possibility to include dependent children in the visa
                          application.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Pathway to Citizenship: </strong>
                          Eligibility to apply for Australian citizenship after
                          meeting residency requirements.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Support Services:</strong>
                          Access to settlement services and community support.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h3 className="subclass-189-pt-h1">Application Process</h3>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Collect evidence of the relationship, including financial,
                  social, and personal documents.
                </li>
                <li className="subclass-189-li">
                  Submit the visa application online through the Department of
                  Home Affairs website.
                </li>
                <li className="subclass-189-li">
                  Pay the required visa application fees.
                </li>
                <li className="subclass-189-li">
                  Undergo medical examinations and provide police clearances.
                </li>
                <li className="subclass-189-li">
                  If the initial application is approved, the applicant receives
                  the temporary Subclass 820 visa.
                </li>
                <li className="subclass-189-li">
                  Two years after lodging the Subclass 820 visa application, the
                  applicant can apply for the Subclass 801 visa.
                </li>
                <li className="subclass-189-li">
                  The Department of Home Affairs assesses the Subclass 801
                  application and grants the permanent visa if all criteria are
                  met.
                </li>
              </ul>
            </p>

            {/* .......point test............. */}
            <h3 className="subclass-189-pt-h1">
              Points Test for Australia Visa 500
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              {/* <ul>
                                    <li className="subclass-189-li">
                                      Age: 25-32 years (30 points), 18-24 & 33-39 years (25 points),
                                      etc.
                                    </li>
                                    <li className="subclass-189-li">
                                      English Language Ability: Superior (20 points), Proficient (10
                                      points).
                                    </li>
                                    <li className="subclass-189-li">
                                      Skilled Employment: In Australia or overseas, up to 20 points
                                      depending on years of experience.
                                    </li>
                                    <li className="subclass-189-li">
                                      Educational Qualifications: Doctorate (20 points),
                                      Bachelor’s/Master’s (15 points),Diploma or trade
                                      qualification: 10 points
                                    </li>
                                    <li className="subclass-189-li">
                                      Other Factors: Australian study requirement, specialist
                                      education qualification, accredited community language, etc.
                                    </li>
                                  </ul> */}
              The Onshore Partner Visa (Subclass 820/801) does not require a
              points test. Instead, it focuses on the genuineness of the
              relationship and meeting health and character requirements.
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Related Visas</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="/offshore-partner-visa-309-100"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclasses (309 and 100) Partner visa (apply overseas)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="/prospective-marriage-visa-300"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 300 Prospective Marriage visa
                    </a>
                  </li>

                  {/* <li className="subclass-189-li-a">
                    <a
                      href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/electronic-travel-authority-601"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 601 Electronic Travel Authority
                    </a>
                  </li> */}

                  {/* <li className="subclass-189-li-a">
                                        <a
                                          href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/temporary-skill-shortage-482"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          Temporary Skill Shortage visa Subclass 482
                                        </a>
                                      </li>
                    
                                      <li className="subclass-189-li-a">
                                        <a
                                          href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/skilled-employer-sponsored-regional-494"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          Skilled Employer Sponsored Regional (Provisional) visa 494
                                        </a>
                                      </li> */}
                </ul>
              </p>
            </div>
          </Col>
        </Row>

        {/* ................visa qualify............ */}
        <Row lg={12} className="subclass-189-vq-row">
          <h3 className="mt-5">Do you think you qualify for this visa?</h3>
          <p>
            Contact us if you would like to know more about this visa or need
            immigration advice.
          </p>
          <div className="mb-5">
            <Button
              href="/contact
"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Contact
            </Button>
            <Button
              href="/appointment"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Book A Consultation
            </Button>
            <Button
              href="/initial-assessment-consultation"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Our Prices
            </Button>
          </div>
        </Row>

        <Row>
          <Col>
            <div className="row m-5">
              <h1 className="home-h1">
                Onshore Partner Visa (Subclass 820/801) Two Stage:
              </h1>
              <div className="col-lg-6 col-md-12">
                <h5 className="subclass-189-pt-h1">
                  Partner visa (Temporary) Visa 820{" "}
                </h5>
                <p className="subclass-189-p subclass-189-pt-col">
                  {/* <ul>
                    <li className="subclass-189-li">
                      For people visiting Australia for holidays, recreation, or
                      to visit family and friends.
                    </li>
                    <li className="subclass-189-li">
                      Can be applied for from inside or outside Australia.
                    </li>
                  </ul> */}
                  This visa lets the de facto partner or spouse of an Australian
                  citizen, Australian permanent resident or eligible New Zealand
                  citizen live in Australia temporarily. Getting this visa is
                  the first step towards a permanent Partner visa (subclass
                  801).
                </p>
              </div>
              <div className="col-lg-6 col-md-12">
                <h5 className="subclass-189-pt-h1">
                  Partner visa (Permanent) Visa 801{" "}
                </h5>
                <p className="subclass-189-p subclass-189-pt-col">
                  {/* <ul>
                    <li className="subclass-189-li">
                      For business people making short business visits for
                      reasons such as attending a conference, negotiating, or
                      exploring business opportunities.
                    </li>
                    <li className="subclass-189-li">
                      Must be applied for from outside Australia.
                    </li>
                  </ul> */}
                  This visa lets the de facto partner or spouse of an Australian
                  citizen, Australian permanent resident or eligible New Zealand
                  citizen live in Australia permanently. It is usually only for
                  people who currently hold a temporary Partner visa (subclass
                  820).
                </p>
              </div>
              {/* <div className="col-lg-6 col-md-12">
                <h5 className="subclass-189-pt-h1">Sponsored Family Stream:</h5>
                <p className="subclass-189-p subclass-189-pt-col">
                  <ul>
                    <li className="subclass-189-li">
                      For people visiting family members in Australia. A family
                      member in Australia must sponsor you and provide a bond.
                    </li>
                    <li className="subclass-189-li">
                      Must be applied for from outside Australia.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="col-lg-6 col-md-12">
                <h5 className="subclass-189-pt-h1">
                  Approved Destination Status (ADS) Stream:
                </h5>
                <p className="subclass-189-p subclass-189-pt-col">
                  <ul>
                    <li className="subclass-189-li">
                      For people from the People’s Republic of China traveling
                      in an organized tour group.
                    </li>
                    <li className="subclass-189-li">
                      Must be applied for from outside Australia.
                    </li>
                  </ul>
                </p>
              </div> */}
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col lg={9} md={8} sm={12}>
            <h5 className="subclass-189-pt-h1">Conditions and Obligations</h5>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Relationship Maintenance:</strong>
                  The relationship must be genuine and ongoing.
                </li>
                <li className="subclass-189-li">
                  <strong>Notify Changes</strong>
                  Inform the Department of Home Affairs of any changes in the
                  relationship status or personal details.
                </li>
                <li className="subclass-189-li">
                  <strong>Compliance: </strong>
                  Comply with Australian laws and visa conditions
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">Express Your Interest</h3>
            <p className="subclass-189-p subclass-189-pt-col">
              {/* <ul>
                        <li className="subclass-189-li">
                          <strong>Research: </strong>
                          Thoroughly research the visa requirements and benefits.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Preparation:</strong>
                          Prepare all necessary documents and financial proofs.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Application:</strong>
                          Complete and submit the online application.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Follow-up:</strong>
                          Monitor your application status through ImmiAccount.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Visa Grant:</strong>
                          Upon approval, make travel and accommodation arrangements.
                        </li>
                      </ul> */}
              If you are interested in applying for the Onshore Partner Visa
              (Subclass 820/801), it's crucial to express your interest early,
              gather all necessary documentation, and ensure that you meet all
              eligibility criteria.
            </p>

            <h3 className="subclass-189-pt-h1">
              English Language Requirements
            </h3>
            <p>
              To meet the English language requirement, you must demonstrate
              competent English. This can be done through:
            </p>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>IELTS:</strong>A minimum score of 6 in each component
                  (Listening, Reading, Writing, and Speaking).
                </li>
                <li className="subclass-189-li">
                  <strong>TOEFL iBT:</strong>A minimum score of 12 in Listening,
                  13 in Reading, 21 in Writing, and 18 in Speaking.
                </li>
                <li className="subclass-189-li">
                  <strong>PTE Academic:</strong>A minimum score of 50 in each
                  component (Listening, Reading, Writing, and Speaking).
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Google Adds</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>

            <div className="subclass-189-rv-card-shadow mt-5">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Latest Post</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </>
      {/* faqs section */}
      <div className="row testimonial-background visa_question-acc">
        <div className="col-lg-12 cl-sm-12">
          <div className="testimonial-heading">
            {/* <h1 className="home-h1">Success Stories</h1> */}
            <h4 className="about-us-h4">
              Frequently Asked Questions (FAQs) about the Onshore Partner Visa
              (Subclass 820/801)
            </h4>
          </div>

          <>
            <Row>
              <Col lg={12} className="">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      What is the difference between Subclass 820 and Subclass
                      801?
                    </Accordion.Header>
                    <Accordion.Body>
                      Subclass 820 is a temporary visa, while Subclass 801 is a
                      permanent visa.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How long does it take to process the Onshore Partner Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      The processing time can vary, but it generally takes
                      around 20-24 months for the Subclass 820 visa and an
                      additional 24 months for the Subclass 801 visa.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Can I work in Australia on a Subclass 820 visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can work in Australia with no restrictions on a
                      Subclass 820 visa.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Do I need to be in Australia to apply for the Subclass
                      820/801 visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you must be in Australia when you apply for the visa
                      and when the visa is granted.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      What evidence do I need to provide to prove my
                      relationship?
                    </Accordion.Header>
                    <Accordion.Body>
                      Evidence can include joint financial statements, shared
                      property leases, photos, social proof, and statements from
                      friends and family.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Can I include my children in the visa application?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can include dependent children in your visa
                      application.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      What happens if my relationship ends before the visa is
                      granted?
                    </Accordion.Header>
                    <Accordion.Body>
                      If the relationship ends before the visa is granted, you
                      must inform the Department of Home Affairs, which may
                      affect your visa application.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      Do I need to undergo a medical examination?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you must undergo a medical examination as part of the
                      application process.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      What are the character requirements for the visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      You must provide police clearances and meet the character
                      requirements set by the Australian government.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      How can I apply for Australian citizenship after obtaining
                      the Subclass 801 visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      After holding the Subclass 801 visa for a certain period
                      and meeting residency requirements, you can apply for
                      Australian citizenship.
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* <Accordion.Item eventKey="10">
                                        <Accordion.Header>
                                          What happens if I overstay my ETA Visa?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          Overstaying your ETA Visa can result in penalties,
                                          including a potential ban on re-entry to Australia and
                                          other legal consequences.
                                        </Accordion.Body>
                                      </Accordion.Item> */}
                </Accordion>
              </Col>
            </Row>
          </>
        </div>
      </div>
    </Layout>
  );
};

export default OnShurePVisa_820;
