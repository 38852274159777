import React from "react";
import aboutUs from "../../images/aboutUs.jpg";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import bannerImage from "../../images/banner2.jpg";
import initialR from "../../images/visaImages/IA.png";
import fullR from "../../images/visaImages/FR.png";
import SearchT from "../../images/visaImages/ST.png";
import { Row, Button } from "react-bootstrap";
import mn from "../../images/marn.png";
import "./Services.css";

const Services = () => {
  return (
    <Layout
      title=" Comprehensive Visa Services | Australian Visa Experts | Real Migration and Education"
      description="Discover top-tier visa services at Real Migration and Education. Our Australian visa experts offer comprehensive support for all visa types, ensuring a streamlined and successful application process tailored to meet your unique migration goals."
      keywords=" visa services, Australian visas, visa support, migration experts, visa application services, visa consultancy, student visas Australia, family visa Australia, work visa Australia, Real Migration and Education visas"
    >
      <div className="mt-3">
        <div className="row m-5">
          <div className="col-lg-6 col-md-12">
            <h1 className="home-h1 mt-5">
              <label className="span-text">Visa</label>
              Services
            </h1>
            <p className="mt-5">
              As a specialized migration consultancy, we prioritize delivering
              expert services with a personalized approach. Our commitment is to
              offer tailored solutions that cater specifically to each client's
              unique circumstances, ensuring a more direct and supportive
              experience throughout their migration process.
            </p>
          </div>
          <div className="col-lg-6 col-md-12">
            <img
              src={bannerImage}
              alt="Real Migration And Education BannerImage"
              className="img-fluid bannerImage-s"
            />
          </div>
        </div>
        {/* visa service */}
        <h2 className="choose-us-title s-card-heading">
          <b className="span-text">Australia</b> Immigration Services
        </h2>
        <div className="row m-5">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card mb-3">
              <div className="card-body s-card-heading">
                <h5 className="card-title ">Initial Assessment</h5>
                <p className="card-text">
                  Assessment of your circumstances to understand your
                  immigration goals and the assistance you need.
                </p>
                <img
                  src={initialR}
                  alt="Real Migration Initial consult img"
                  className="img-fluid aboutUs mt-3"
                />
                <Link
                  to="/initial-assessment-consultation"
                  className="btn btn-enquire mt-4"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card mb-3">
              <div className="card-body s-card-heading">
                <h5 className="card-title ">Full Representation</h5>
                <p className="card-text">
                  We will take complete control of your case, providing guidance
                  and assistance during the entire process.
                </p>
                <img
                  src={fullR}
                  alt="Real Migration full representation img"
                  className="img-fluid aboutUs mt-3"
                />
                <Link
                  to="/full-representation-consultation"
                  className="btn btn-enquire mt-4"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card mb-3">
              <div className="card-body s-card-heading">
                <h5 className="card-title ">Searching Tools</h5>
                <p className="card-text">
                  We provide comprehensive, multiple searching options to help
                  you identify visa eligibility quickly and accurately.
                </p>
                <img
                  src={SearchT}
                  alt="Real Migration search tools"
                  className="img-fluid aboutUs mt-3"
                />
                <Link to="/contact" className="btn btn-enquire mt-4">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row m-5">
          <div className="col-lg-4 col-md-12">
            <img
              src={mn}
              alt="Real Migration And Education BannerImage"
              className="img-fluid bannerImage-m"
            />
          </div>

          <div className="col-lg-8 col-md-12">
            <h1 className="home-h1 ">
              {" "}
              Registered
              <label className="span-text">Migration</label> Agents
            </h1>
            <p className="mt-5">
              At Real Migration and Education, Shahab Ahmed is a Registered
              Migration Agent (MARN 2318146) and a member of the Migration
              Institute of Australia .
            </p>
            <p className="mt-5">
              Shahab Ahmad is a professional Engineer with waelth of experience
              in Australian job market and has spent significant time in
              education sector as well. He is passionate about migration and
              specially the people who look frward to call Australia their home.
            </p>
            {/* <p className="mt-5">
              Currently, Shahab is pursuing a Law Degree in Australia and has
              recently joined the teaching staff at Griffith University as a
              casual instructor for the Graduate Diploma in Australian Migration
              Law & Practice. Her approach to client service is professional,
              honest, and ethical, underscored by a genuine commitment to
              helping applicants achieve their migration goals.
            </p> */}
          </div>
        </div>
        {/* ................visa qualify............ */}
        <Row lg={12} className="subclass-189-vq-row">
          <h3 className="mt-5"> Languages spoken: English and Urdu</h3>
          <p>
            Contact us if you would like to know more about this visa or need
            immigration advice.
          </p>
          <div className="mb-5">
            <Button
              className="subclass-189-vq-button subclass-189-vq-btn-margin"
              href="/Contact"
            >
              Contact Us
            </Button>
            <Button
              className="subclass-189-vq-button subclass-189-vq-btn-margin"
              href="/appointment"
            >
              Book A Consultation
            </Button>
            <Button
              className="subclass-189-vq-button subclass-189-vq-btn-margin"
              href="/initial-assessment-consultation"
            >
              Our Prices
            </Button>
          </div>
        </Row>
      </div>
    </Layout>
  );
};

export default Services;
