import { useEffect, useState } from "react";
import { useAuth } from "../../context/Auth";
import { Outlet } from "react-router-dom";
import axios from "axios";
import Spinner from "../Spinner";

export default function PrivateRoute() {
  const [ok, setOK] = useState(false);
  const { auth } = useAuth();

  useEffect(() => {
    const authCheck = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_API}api/auth/user-auth`
        );
        console.log(`${process.env.REACT_APP_API}api/auth/user-auth`);
        if (resp.data.ok) {
          setOK(true);
        } else {
          setOK(false);
        }
      } catch (error) {
        setOK(false);
      }
    };

    if (auth?.token) authCheck();
  }, [auth?.token]);

  return ok ? <Outlet /> : <Spinner />;
}
