import React from "react";
import Layout from "../../../../components/Layout/Layout";
import { Row, Col, Button } from "react-bootstrap";
import visa_143 from "../../../../images/visaImages/Visa_143_1.png";

import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import "./CPVisa_143.css";

const CPVisa_143 = () => {
  return (
    <Layout
      title="Contributory Parent Visa (Subclass 143): Eligibility, Benefits, and Application Process"
      description="Learn about the Contributory Parent Visa (Subclass 143), including eligibility criteria, benefits, application process, conditions, and obligations. Get answers to FAQs and more."
      keywords="Contributory Parent Visa 143, Australian parent visa, visa 143 eligibility, parent visa benefits, visa 143 application process, Australia parent visa conditions, express your interest visa 143"
    >
      <>
        <Row className="m-5">
          <Col lg={6} md={6} sm={12}>
            <img
              src={visa_143}
              alt="skilledindependent-visa-189"
              className="img-fluid subclass-189-image"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <h2 className="subclass-189-h1">
              Contributory Parent Visa
              <span className="subclass-189-h1-span">(Subclass 143)</span>
            </h2>

            <p className="subclass-189-p">
              Ready to join your children in Australia?
              <a href="#" className="subclass-189-a">
                Start your application today for the Contributory Parent Visa
                (Subclass 143)!
              </a>
              This visa is ideal for parents looking to reunite with their
              children who are Australian citizens, permanent residents, or
              eligible New Zealand citizens. Whether you dream of family
              gatherings, exploring Australia's stunning landscapes, or living
              permanently in a diverse and welcoming society, this visa paves
              the way for your aspirations. Seize the opportunity to create
              lasting memories with your loved ones and build a promising future
              together in Australia. Apply now and take the first step towards a
              heartwarming reunion!
            </p>
            <Button href="/contact" className="mt-3 contact-us-call-button">
              Call Now
            </Button>
          </Col>
        </Row>

        {/* //tab section */}
        <div className="row m-5 ">
          <div className="col-12">
            <div className="choose-us-title-view">
              <h2 className="choose-us-title">
                Contributory Parent Visa
                <b className="span-text">(Subclass 143)</b> A Comprehensive
                Guide
              </h2>
            </div>
            <p>
              The Contributory Parent Visa (Subclass 143) allows parents of an
              Australian citizen, permanent resident, or eligible New Zealand
              citizen to live in Australia permanently. This visa is designed
              for parents who are willing to make a higher contribution towards
              their stay in Australia. It offers a faster processing time
              compared to other parent visas, making it an attractive option for
              many families looking to reunite in Australia.
            </p>
          </div>
          <div className="col-12 ">
            <ul className="nav nav-tabs custom-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active custom-nav-link"
                  id="success-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#success"
                  type="button"
                  role="tab"
                  aria-controls="success"
                  aria-selected="true"
                >
                  <i className="fas fa-clipboard-list fa-2x"></i>
                  <br />
                  Eligibility Criteria
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="permanent-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#permanent"
                  type="button"
                  role="tab"
                  aria-controls="permanent"
                  aria-selected="false"
                >
                  <i className="fas fa-check-circle fa-2x"></i>
                  <br />
                  Why Visa 143?
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="priority-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#priority"
                  type="button"
                  role="tab"
                  aria-controls="priority"
                  aria-selected="false"
                >
                  <i className="fas fa-gift fa-2x"></i>
                  <br />
                  Visa 143 benefits
                </button>
              </li>
            </ul>
            <div className="tab-content custom-tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active custom-tab-pane"
                id="success"
                role="tabpanel"
                aria-labelledby="success-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Eligibility Criteria for Contributory Parent Visa
                      (Subclass 143)
                    </h3>
                    <p>
                      To apply for the Contributory Parent Visa (Subclass 143),
                      applicants must meet the following eligibility criteria:
                    </p>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Parent Relationship:</strong>
                          You must be the parent of an Australian citizen,
                          permanent resident, or eligible New Zealand citizen.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Sponsorship:</strong>
                          Your child must sponsor you and be settled in
                          Australia (usually for at least two years).
                        </li>
                        <li className="subclass-189-li">
                          <strong>Balance of Family Test: </strong>At least half
                          of your children must live permanently in Australia,
                          or more of your children live in Australia than in any
                          other country.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Health and Character Requirements:</strong>
                          You must meet the health and character requirements
                          specified by the Australian government.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Assurance of Support: </strong>
                          You need an Assurance of Support to ensure that you
                          will not rely on government assistance.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Contributory Payment: </strong>
                          You must be willing to pay the higher visa application
                          charge associated with this visa.
                        </li>
                        <li className="subclass-189-li">
                          <strong>No Previous Visa Cancellation:</strong>
                          You must not have had a visa canceled or a previous
                          application refused.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="permanent"
                role="tabpanel"
                aria-labelledby="permanent-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Why Choose the Contributory Parent Visa (Subclass 143)?
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Permanent Residency: </strong>
                          Offers a pathway to permanent residency in Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Faster Processing Time:</strong>
                          Processed more quickly than non-contributory parent
                          visas.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Family Reunion:</strong>
                          Enables you to live permanently with your children and
                          grandchildren.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Work and Study:</strong>
                          Allows you to work and study in Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Medicare Access:</strong>
                          Provides access to Medicare, Australia’s healthcare
                          system.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Travel Rights:</strong>
                          Grants the right to travel to and from Australia for
                          five years from the date the visa is granted.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Pathway to Citizenship:</strong>
                          Opens a pathway to apply for Australian citizenship in
                          the future.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="priority"
                role="tabpanel"
                aria-labelledby="priority-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Benefits of the Contributory Parent Visa (Subclass 143)
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Permanent Residency: </strong>
                          Secure permanent residency for a stable life in
                          Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Healthcare: </strong>
                          Access to Medicare, Australia’s public healthcare
                          system.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Work Rights:</strong>
                          Full rights to work in Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Educational Opportunities</strong>
                          Access to educational facilities and courses.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Travel Flexibility: </strong>
                          Ability to travel in and out of Australia for five
                          years.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Social Security Benefits:</strong>
                          Potential eligibility for social security benefits.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Family Reunification:</strong>
                          Opportunity to live with and support your family in
                          Australia.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h3 className="subclass-189-pt-h1">Application Process</h3>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Ensure your child is willing and eligible to sponsor you.
                </li>
                <li className="subclass-189-li">
                  Collect necessary documents, including proof of relationship,
                  health, and character.
                </li>
                <li className="subclass-189-li">
                  Submit your visa application online or by mail, along with the
                  required visa application fee.
                </li>
                <li className="subclass-189-li">
                  Complete the required health examinations and provide police
                  clearance certificates.
                </li>
                <li className="subclass-189-li">
                  Arrange for an Assurance of Support, which involves a
                  financial bond to cover any welfare costs.
                </li>
                <li className="subclass-189-li">
                  Pay the second installment of the visa application charge once
                  requested by the Department of Home Affairs.
                </li>
                <li className="subclass-189-li">
                  Await the decision on your visa application. You may be asked
                  to provide additional information or attend an interview
                  during this time.
                </li>
              </ul>
            </p>

            {/* .......point test............. */}
            <h3 className="subclass-189-pt-h1">
              Points Test for Australia Visa 500
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              {/* <ul>
                                                        <li className="subclass-189-li">
                                                          Age: 25-32 years (30 points), 18-24 & 33-39 years (25 points),
                                                          etc.
                                                        </li>
                                                        <li className="subclass-189-li">
                                                          English Language Ability: Superior (20 points), Proficient (10
                                                          points).
                                                        </li>
                                                        <li className="subclass-189-li">
                                                          Skilled Employment: In Australia or overseas, up to 20 points
                                                          depending on years of experience.
                                                        </li>
                                                        <li className="subclass-189-li">
                                                          Educational Qualifications: Doctorate (20 points),
                                                          Bachelor’s/Master’s (15 points),Diploma or trade
                                                          qualification: 10 points
                                                        </li>
                                                        <li className="subclass-189-li">
                                                          Other Factors: Australian study requirement, specialist
                                                          education qualification, accredited community language, etc.
                                                        </li>
                                                      </ul> */}
              The Contributory Parent Visa (Subclass 143) does not require
              applicants to pass a points test. The main criteria are the family
              relationship, sponsorship, and meeting the balance of family test,
              along with health, character, and financial requirements.
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Related Visas</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="/parent-visa-103"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 103 Parent visa
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="/aged-parent-visa-804
"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 804 Aged Parent visa
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/contributory-parent-visa-173"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 173 Contributory Parent (Temporary) visa
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/contributory-aged-parent-visa-884"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 884 Contributory Aged Parent (Temporary) visa
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="/contributory-aged-parent-visa-864"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (Subclass 864) Contributory Aged Parent visa
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>

        {/* ................visa qualify............ */}
        <Row lg={12} className="subclass-189-vq-row">
          <h3 className="mt-5">Do you think you qualify for this visa?</h3>
          <p>
            Contact us if you would like to know more about this visa or need
            immigration advice.
          </p>
          <div className="mb-5">
            <Button
              href="/contact"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Contact
            </Button>
            <Button
              href="/appointment"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Book A Consultation
            </Button>
            <Button
              href="/initial-assessment-consultation"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Our Prices
            </Button>
          </div>
        </Row>

        {/* <Row>
                      <Col>
                        <div className="row m-5">
                          <h1 className="home-h1">
                            Offshore Partner Visa (Subclass 309/100) Two Stage:
                          </h1>
                          <div className="col-lg-6 col-md-12">
                            <h5 className="subclass-189-pt-h1">
                              Partner visa (Temporary) Visa 309
                            </h5>
                            <p className="subclass-189-p subclass-189-pt-col">
                              
                              Must be outside of Australia to apply for this visa. Prior to
                              applying, gather all required paperwork, including health,
                              character, and sponsorship information. Use the online
                              application process to apply for the visa when you are away
                              from home.
                            </p>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <h5 className="subclass-189-pt-h1">
                              Partner visa (Permanent) Visa 100
                            </h5>
                            <p className="subclass-189-p subclass-189-pt-col">
                              
                              There is no need to provide any more information if Subclass
                              309 of the Visa comes right after Subclass 100. However, if a
                              Visa Subclass 100 is not immediately issued, the authorities
                              must be given the necessary information. Two years following
                              application lodging, it will be assessed; submit the required
                              paperwork one month beforehand. Submission of an application
                              through an online system.
                            </p>
                          </div>
                         
                        </div>
                      </Col>
                    </Row> */}

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h5 className="subclass-189-pt-h1">Conditions and Obligations</h5>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Health Insurance:</strong>
                  Maintain adequate health insurance for the duration of your
                  stay in Australia.
                </li>
                <li className="subclass-189-li">
                  <strong>No Government Assistance: </strong>
                  Agree not to access government welfare support during your
                  initial stay period.
                </li>
                <li className="subclass-189-li">
                  <strong>Assurance of Support: </strong>
                  Ensure that your sponsor fulfills the Assurance of Support
                  requirements.
                </li>
                <li className="subclass-189-li">
                  <strong>Notify Changes: </strong>
                  Inform the Department of Home Affairs of any changes in your
                  circumstances.
                </li>
                <li className="subclass-189-li">
                  <strong>Abide by Australian Laws: </strong>
                  Comply with all Australian laws and visa conditions.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">Express Your Interest</h3>
            <p className="subclass-189-p subclass-189-pt-col">
              {/* <ul>
                                            <li className="subclass-189-li">
                                              <strong>Research: </strong>
                                              Thoroughly research the visa requirements and benefits.
                                            </li>
                                            <li className="subclass-189-li">
                                              <strong>Preparation:</strong>
                                              Prepare all necessary documents and financial proofs.
                                            </li>
                                            <li className="subclass-189-li">
                                              <strong>Application:</strong>
                                              Complete and submit the online application.
                                            </li>
                                            <li className="subclass-189-li">
                                              <strong>Follow-up:</strong>
                                              Monitor your application status through ImmiAccount.
                                            </li>
                                            <li className="subclass-189-li">
                                              <strong>Visa Grant:</strong>
                                              Upon approval, make travel and accommodation arrangements.
                                            </li>
                                          </ul> */}
              If you are considering applying for the Contributory Parent Visa
              (Subclass 143), it's essential to express your interest and ensure
              all required documents and criteria are met. Engaging a migration
              agent can help streamline the process and increase your chances of
              a successful application.
            </p>

            <h3 className="subclass-189-pt-h1">
              English Language Requirements
            </h3>
            <p>
              To meet the English language requirement, you must demonstrate
              competent English. This can be done through:
            </p>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>IELTS:</strong>A minimum score of 6 in each component
                  (Listening, Reading, Writing, and Speaking).
                </li>
                <li className="subclass-189-li">
                  <strong>TOEFL iBT:</strong>A minimum score of 12 in Listening,
                  13 in Reading, 21 in Writing, and 18 in Speaking.
                </li>
                <li className="subclass-189-li">
                  <strong>PTE Academic:</strong>A minimum score of 50 in each
                  component (Listening, Reading, Writing, and Speaking).
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Google Adds</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>

            <div className="subclass-189-rv-card-shadow mt-5">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Latest Post</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </>
      {/* faqs section */}
      <div className="row testimonial-background visa_question-acc">
        <div className="col-lg-12 cl-sm-12">
          <div className="testimonial-heading">
            {/* <h1 className="home-h1">Success Stories</h1> */}
            <h4 className="about-us-h4">
              Frequently Asked Questions (FAQs) about the Contributory Parent
              Visa (Subclass 143)?
            </h4>
          </div>

          <>
            <Row>
              <Col lg={12} className="">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      What is the Contributory Parent Visa (Subclass 143)?
                    </Accordion.Header>
                    <Accordion.Body>
                      The Contributory Parent Visa (Subclass 143) allows parents
                      of Australian citizens, permanent residents, or eligible
                      New Zealand citizens to live in Australia permanently.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How long does it take to process the Contributory Parent
                      Visa (Subclass 143)?
                    </Accordion.Header>
                    <Accordion.Body>
                      Processing times can vary, but it typically takes 2-3
                      years. It's advisable to check the latest processing times
                      on the Department of Home Affairs website.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      What is the balance of family test?
                    </Accordion.Header>
                    <Accordion.Body>
                      The balance of family test requires that at least half of
                      your children live permanently in Australia, or more of
                      your children live in Australia than in any other country.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can I work on a Contributory Parent Visa (Subclass 143)?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, once granted, you have full work rights in Australia.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      What is the Assurance of Support?
                    </Accordion.Header>
                    <Accordion.Body>
                      The Assurance of Support is a financial bond provided by
                      your sponsor to cover any potential welfare costs for a
                      period of 10 years.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Can I include my partner in the visa application?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can include your partner and dependent family
                      members in your visa application.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Do I need to have private health insurance for the
                      Contributory Parent Visa (Subclass 143)?
                    </Accordion.Header>
                    <Accordion.Body>
                      While it's not mandatory, having adequate health insurance
                      is highly recommended to cover any medical expenses.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      Is there a visa application fee for the Contributory
                      Parent Visa (Subclass 143)?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, there is a substantial visa application fee, which is
                      divided into two installments.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      What documents are required for the visa application?
                    </Accordion.Header>
                    <Accordion.Body>
                      You will need to provide documents proving your
                      relationship to your child, health and character
                      documents, and financial assurance details.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      Can I travel outside Australia while my visa application
                      is being processed?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, but you must inform the Department of Home Affairs of
                      your travel plans and ensure that you comply with any
                      conditions placed on your application.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </>
        </div>
      </div>
    </Layout>
  );
};

export default CPVisa_143;
