import React from "react";
import Layout from "../../../../components/Layout/Layout";
import { Row, Col, Button } from "react-bootstrap";
import visaCan from "../../../../images/visaImages/visa_cancel.png";

const Visa_cancel_issue = () => {
  return (
    <Layout
      title="Complete Guide to Contributory Parent Visa Subclass 173: Eligibility, Benefits, and Application Process"
      description="Learn all about the Contributory Parent Visa Subclass 173. Understand eligibility criteria, benefits, application process, and more to join your family in Australia."
      keywords="Contributory Parent Visa 173, Australian Parent Visa, Subclass 173 Visa, Parent Visa Eligibility, Australia Family Visa, Parent Visa Benefits, Parent Visa Application Process"
    >
      <>
        <Row className="m-5">
          <Col lg={6} md={6} sm={12}>
            <img
              src={visaCan}
              alt="skilledindependent-visa-189"
              className="img-fluid subclass-189-image"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <h2 className="subclass-189-h1">
              Visa Cancellation
              <span className="subclass-189-h1-span">(issues)</span>
            </h2>

            <p className="subclass-189-p">
              Only the Minister or a delegate of the Minister can grant, refuse
              or cancel a visa. This can be discretionary, mandatory or by
              operation of law. In some circumstances, you can ask us to cancel
              your visa. Cancelling visas is at the discretion of the
              Department. If we cancel your visa, we may also cancel the visas
              held by any of your family members. Your employer, a sponsor or
              family member cannot cancel your visa. However, a person with
              parental responsibility can request us to cancel the visa of a
              person under 18 years old.
            </p>
            <Button href="/appointment" className="mt-3 contact-us-call-button">
              Book your Appointment
            </Button>
          </Col>
        </Row>

        {/* //tab section */}
        <div className="row m-5 ">
          <div className="col-12">
            <div className="choose-us-title-view">
              <h2 className="choose-us-title">
                Main Reasons why <b className="span-text">(your visa)</b> may be
                cancelled
              </h2>
            </div>
            <p className="choose-us-text subclass-189-page-font">
              Having a visa cancelled can have significant consequences for your
              ability to stay in or return to Australia. Here are common reasons
              why your visa might be cancelled:
            </p>
            <h3>Non-Compliance with Visa Conditions</h3>
            <p className="choose-us-text subclass-189-page-font">
              Your visa may be cancelled if you do not comply with the
              conditions attached to it. Common conditions include:
              <li className="subclass-189-li">
                <strong>Work Restrictions:</strong>
                Working in jobs or hours not permitted by your visa.
              </li>
              <li className="subclass-189-li">
                <strong>Study Restrictions:</strong>
                Studying without proper authorization if your visa does not
                allow it.
              </li>
              <li className="subclass-189-li">
                <strong>Staying Duration:</strong>
                Overstaying the permitted period of your visa.
              </li>
              <li className="subclass-189-li">
                <strong>Address Notification:</strong>
                Failing to notify the Department of Home Affairs of your
                residential address or any changes to it.
              </li>
            </p>
            <h3> Failure to Meet Character Requirements</h3>
            <p className="choose-us-text subclass-189-page-font">
              The Australian government places a high emphasis on character
              requirements. Your visa may be cancelled if:
              <li className="subclass-189-li">
                <strong>Criminal Record:</strong>
                You have a substantial criminal record.
              </li>
              <li className="subclass-189-li">
                <strong>Association with Criminal Conduct:</strong>
                You are found to have been involved in or associated with
                criminal activity.
              </li>
              <li className="subclass-189-li">
                <strong>Risk to Community:</strong>
                You are considered a threat to the Australian community.
              </li>
              <li className="subclass-189-li">
                <strong>Dishonesty:</strong>
                You have provided false or misleading information to the
                Department of Home Affairs.
              </li>
            </p>

            <h3> Providing False Information</h3>
            <p className="choose-us-text subclass-189-page-font">
              Honesty in your visa application is crucial. Your visa may be
              cancelled if:
              <li className="subclass-189-li">
                <strong>False Documents:</strong>
                You submit forged or fraudulent documents.
              </li>
              <li className="subclass-189-li">
                <strong>Misleading Information:</strong>
                You provide incorrect or misleading information in your
                application or during your stay.
              </li>
              <li className="subclass-189-li">
                <strong>Omissions:</strong>
                You fail to disclose important information, such as previous
                visa refusals or cancellations.
              </li>
            </p>
          </div>
        </div>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h3 className="subclass-189-pt-h1">
              Character requirements for visas
            </h3>

            <p className="subclass-189-p subclass-189-pt-col">
              <p>
                To visit or stay in Australia, you must meet the character
                requirements outlined in section 501 of the Migration Act 1958.
                These requirements help determine if you are of good character.
              </p>
              <ul>
                <li className="subclass-189-li">
                  Have a substantial criminal record
                </li>
                <li className="subclass-189-li">
                  Have been convicted of a sexually based crime involving a
                  child
                </li>
                <li className="subclass-189-li">
                  Have escaped from immigration detention or committed an
                  offence while in or after escaping from detention
                </li>
                <li className="subclass-189-li">
                  Are or have been involved with a group or organisation
                  suspected of criminal conduct
                </li>
                <li className="subclass-189-li">
                  Are suspected of involvement in serious international crimes
                  or security risks
                </li>
                <li className="subclass-189-li">
                  Have a history of conduct showing you are not of good
                  character
                </li>
                <li className="subclass-189-li">
                  Are likely to engage in criminal conduct, harass others, or be
                  a danger to the community
                </li>
                <li className="subclass-189-li">
                  Are subject to adverse security assessments or Interpol
                  notices related to risk to the community
                </li>
                <li className="subclass-189-li">
                  Have been convicted of a domestic violence offence or subject
                  to a domestic violence order
                </li>
              </ul>
              <p>
                If you appear to fail the character requirements, you will be
                given a chance to provide reasons why your visa should not be
                cancelled.
              </p>
            </p>

            {/* .......point test............. */}
            <h3 className="subclass-189-pt-h1">
              Appeal a refusal or cancellation
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <p>
                  If your visa is refused or cancelled, you may be eligible to
                  apply for a review of the decision, except if the Minister
                  personally made the decision, in which case there is no appeal
                  process.
                </p>
                {/* <li className="subclass-189-li">
                  All documentation, evidence, forms and interview records held
                  by the Department of Home Affairs
                </li>
                <li className="subclass-189-li">
                  All submissions or evidence submitted by you to the AAT
                </li> */}
                <p>
                  The Administrative Appeals Tribunal (AAT) conducts independent
                  merits reviews of departmental decisions, including visa
                  cancellations. There are strict time limits for appeals, and
                  you must apply in writing within the specified period.
                </p>
                <p>
                  If you believe the refusal or cancellation decision was not
                  made lawfully, you can seek judicial review. It’s advisable to
                  seek assistance from a registered migration agent or obtain
                  independent legal advice regarding your immigration status.
                </p>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          {/* <Col lg={3} md={4} sm={12}>
                <div className="subclass-189-rv-card-shadow">
                  <div className="subclass-189-rv-card-head">
                    <h5 className="subclass-189-rv-card-h">Related Visas</h5>
                  </div>
                  <p className="subclass-189-rv-card-p">
                    <ul>
                      <li className="subclass-189-li-a">
                        <a
                          href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/parent-103"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Subclass 103 Parent visa
                        </a>
                      </li>
                      <li className="subclass-189-li-a">
                        <a
                          href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/aged-parent-804"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Subclass 804 Aged Parent visa
                        </a>
                      </li>
    
                      <li className="subclass-189-li-a">
                        <a
                          href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/contributory-parent-143"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          (Subclass 143) Contributory Parent visa
                        </a>
                      </li>
    
                      <li className="subclass-189-li-a">
                        <a
                          href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/contributory-aged-parent-temporary-884"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Subclass 884 Contributory Aged Parent (Temporary) visa
                        </a>
                      </li>
                      <li className="subclass-189-li-a">
                        <a
                          href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/contributory-parent-temporary-173"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Subclass 173 Contributory Parent (Temporary) visa
                        </a>
                      </li>
                    </ul>
                  </p>
                </div>
              </Col> */}
        </Row>

        {/* ................visa qualify............ */}
        <Row lg={12} className="subclass-189-vq-row">
          <h3 className="mt-5">Do you think you qualify for this visa?</h3>
          <p>
            Contact us if you would like to know more about this visa or need
            immigration advice.
          </p>
          <div className="mb-5">
            <Button
              href="/contact"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Contact
            </Button>
            <Button
              href="/appointment"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Book A Consultation
            </Button>
            <Button
              href="/initial-assessment-consultation"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Our Prices
            </Button>
          </div>
        </Row>

        {/* <Row>
                                      <Col>
                                        <div className="row m-5">
                                          <h1 className="home-h1">
                                            Offshore Partner Visa (Subclass 309/100) Two Stage:
                                          </h1>
                                          <div className="col-lg-6 col-md-12">
                                            <h5 className="subclass-189-pt-h1">
                                              Partner visa (Temporary) Visa 309
                                            </h5>
                                            <p className="subclass-189-p subclass-189-pt-col">
                                              
                                              Must be outside of Australia to apply for this visa. Prior to
                                              applying, gather all required paperwork, including health,
                                              character, and sponsorship information. Use the online
                                              application process to apply for the visa when you are away
                                              from home.
                                            </p>
                                          </div>
                                          <div className="col-lg-6 col-md-12">
                                            <h5 className="subclass-189-pt-h1">
                                              Partner visa (Permanent) Visa 100
                                            </h5>
                                            <p className="subclass-189-p subclass-189-pt-col">
                                              
                                              There is no need to provide any more information if Subclass
                                              309 of the Visa comes right after Subclass 100. However, if a
                                              Visa Subclass 100 is not immediately issued, the authorities
                                              must be given the necessary information. Two years following
                                              application lodging, it will be assessed; submit the required
                                              paperwork one month beforehand. Submission of an application
                                              through an online system.
                                            </p>
                                          </div>
                                         
                                        </div>
                                      </Col>
                                    </Row> */}

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h5 className="subclass-189-pt-h1">
              Refused or cancelled visa due to concerns about your character
            </h5>

            <p className="subclass-189-p subclass-189-pt-col">
              <p>
                If you do not meet character requirements, the Minister or a
                delegate can refuse your application or cancel your visa.
              </p>
              <p>
                To decide if you meet character requirements, we consider a
                variety of matters, including:
              </p>
              <ul>
                <li className="subclass-189-li">
                  the protection of the Australian community
                </li>
                <li className="subclass-189-li">
                  the best interests of any children in Australia
                </li>
                <li className="subclass-189-li">
                  Australia’s international legal responsibilities
                </li>
                <li className="subclass-189-li">
                  the impact of visa refusal or cancellation on your family in
                  Australia
                </li>
                <li className="subclass-189-li">
                  any impact on Australian business and community interests
                </li>
              </ul>
              Character decisions require consideration of a range of factors.
              When making a decision, departmental delegates refer to a
              Ministerial Direction. This sets out the considerations they must
              balance when considering whether to refuse or cancel someone’s
              visa on character grounds.
            </p>

            <h3 className="subclass-189-pt-h1">Mandatory cancellation</h3>
            <p className="subclass-189-p subclass-189-pt-col">
              <p>
                By law, we must cancel your visa if you are serving a sentence
                in a prison full-time for an Australian crime, and you have:
              </p>
              <ul>
                <li className="subclass-189-li">
                  been sentenced to death, imprisonment for life or imprisonment
                  for longer than a year.
                </li>
                <li className="subclass-189-li">
                  been convicted of a sexually based crime involving a child by
                  an Australian or foreign court.
                </li>
                <li className="subclass-189-li">
                  had a charge proven for a sexually based crime involving a
                  child, even if you were discharged without conviction.
                </li>
                <li className="subclass-189-li">
                  been found guilty of a sexually based crime involving a child,
                  even if you were discharged without conviction.
                </li>
              </ul>
              If we must cancel your visa, you have 28 days to request
              revocation of the cancellation. Revocation of the cancellation
              decision would have the effect of restoring your visa.
            </p>

            {/* <h3 className="subclass-189-pt-h1">
                  English Language Requirements
                </h3>
                <p>
                  To meet the English language requirement, you must demonstrate
                  competent English. This can be done through:
                </p>
                <p className="subclass-189-p subclass-189-pt-col">
                  <ul>
                    <li className="subclass-189-li">
                      <strong>IELTS:</strong>A minimum score of 6 in each component
                      (Listening, Reading, Writing, and Speaking).
                    </li>
                    <li className="subclass-189-li">
                      <strong>TOEFL iBT:</strong>A minimum score of 12 in Listening,
                      13 in Reading, 21 in Writing, and 18 in Speaking.
                    </li>
                    <li className="subclass-189-li">
                      <strong>PTE Academic:</strong>A minimum score of 50 in each
                      component (Listening, Reading, Writing, and Speaking).
                    </li>
                  </ul>
                </p> */}
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Google Adds</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>

            <div className="subclass-189-rv-card-shadow mt-5">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Latest Post</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </>
    </Layout>
  );
};

export default Visa_cancel_issue;
