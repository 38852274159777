import React, { useState, useRef } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Layout from "../../components/Layout/Layout";
import contactUsImage from "../../images/contactUs.jpg";
import queryForm from "../../images/qForm.jpg";
import "./ContactUs.css";
import { toast } from "react-toastify";
import axios from "axios";

const ContactUs = () => {
  const fileInputRef = useRef(null);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    visa: "",
    message: "",
    file: null,
  });

  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
    if (!touched[field]) {
      setTouched({
        ...touched,
        [field]: true,
      });
    }
  };

  const handleBlur = (field) => () => {
    const newErrors = validateField(field);
    setErrors({
      ...errors,
      ...newErrors,
    });
  };

  const validateField = (field) => {
    if (!formData[field].trim()) {
      return {
        [field]: `${field[0].toUpperCase() + field.slice(1)} is required`,
      };
    }
    return {};
  };

  const validate = () => {
    let newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.email.trim()) newErrors.email = "Email address is required";
    if (!formData.phone.trim()) newErrors.phone = "Phone number is required";
    if (!formData.visa.trim()) newErrors.visa = "Visa selection is required";
    if (!formData.message.trim()) newErrors.message = "Message is required";
    return newErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formErrors = validate();
    if (Object.keys(formErrors).length === 0) {
      const data = new FormData();
      data.append("name", formData.name);
      data.append("email", formData.email);
      data.append("phone_number", formData.phone); // Assuming formData.phone is where phone number is stored
      data.append("visa_type", formData.visa); // Assuming formData.visa is where visa type is stored
      data.append("message", formData.message);

      // Append file only if it exists
      if (formData.file) {
        data.append("file", formData.file);
      }
      // console.log(formData.file);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API}api/form/contact-form`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.success) {
          toast.success(response.data.message, {
            onClose: () => {
              // Optionally navigate or simply reset the form
              setFormData({
                name: "",
                email: "",
                phone: "",
                visa: "",
                message: "",
                file: null,
              });
              // Directly reset the file input
              if (fileInputRef.current) {
                fileInputRef.current.value = "";
              }
              setTouched({});
              setErrors({});
              // If you need to navigate away:
              // navigate("/contact");
            },
            autoClose: 3000,
          });
        } else {
          toast.error(response.data.message);
        }
      } catch (err) {
        toast.error("Something went wrong with the form submission.");
      }
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <Layout
      title="About Real Migration and Education - Your Trusted Australian Immigration Experts"
      description="Australian immigration, Real Migration and Education, Australia visa application, immigration advice, immigration contact center, migrate to Australia, Australian visa help, immigration services Australia"
      keywords="Contact Real Migration and Education, Get in touch with immigration experts, Immigration help contact, Immigration services contact info, Reach out for visa assistance, Contact for visa application help, Ask about Australian immigration, Australian visa application contact, Contact for PR in Australia, Student visa assistance contact, Family visa queries contact, How to contact an immigration agent, Ways to contact visa consultants, Immigration office contact details,Email Australian immigration consultant, Online inquiry for Australian visa, Reliable visa consultation contact, Accredited Australian immigration agency contact"
    >
      <>
        <Row className="m-5">
          <Col lg={6} md={6} sm={12}>
            <h1 className="contact-us-heading">Contact Us</h1>
            <p>
              Consult our Australian Visa Agents for precise and informed visa
              guidance. Our trusted Migration Consultants offer reliable advice
              to navigate your migration process effectively.
            </p>
            <div className="contact-us-email-container">
              <span>Email us at:</span>
              <a href="mailto:info@realmigration.com.au" className="email-link">
                info@realmigration.com.au
              </a>
            </div>
            <div>
              <b>OR</b>
            </div>
            <Button className="mt-3 contact-us-call-button">Call Now</Button>
          </Col>
          <Col lg={6} md={6} sm={12} className="contact-us-image-col">
            <img
              src={contactUsImage}
              alt="Visa Application"
              className="img-fluid contact-us-image"
            />
          </Col>
        </Row>
        <Row className="m-5">
          <Col lg={7} md={7} sm={12}>
            <h2 className="contact-us-heading">
              Australia <b className="contact-us-immigration-b">Immigration</b>{" "}
              Contact Center
            </h2>
            <p className="contact-us-immigration-p">
              Support from Australian migration agents is crucial for navigating
              the complexities of the Australian immigration system. These
              experienced professionals not only offer tailored advice and
              maximize the chances of success, but they also stay abreast of
              frequent policy changes and provide peace of mind throughout the
              immigration journey. They play a vital role in interpreting the
              legal and administrative aspects of migration laws, ensuring that
              applications are both complete and compliant with current
              regulations
            </p>
            <p className="contact-us-immigration-p">
              <p className="contact-us-immigration-p">
                Support from Australian migration agents is crucial for
                navigating the complexities of the Australian immigration
                system. These experienced professionals not only offer tailored
                advice and maximize the chances of success, but they also stay
                abreast of frequent policy changes and provide peace of mind
                throughout the immigration journey. They play a vital role in
                interpreting the legal and administrative aspects of migration
                laws, ensuring that applications are both complete and compliant
                with current regulations
              </p>
            </p>

            {/* <img
              src={queryForm}
              alt="Query Form"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              className="img-fluid contact-us-qForm mt-5 mb-5"
            /> */}
          </Col>
          <Col lg={5} md={5} sm={12} className="px-2">
            <Form
              className="p-3 border rounded"
              style={{ borderColor: "#001f80" }}
              onSubmit={handleSubmit}
            >
              <h3 className="contact-us-query-form-h">
                Fill out <b className="contact-us-immigration-b">form</b> for
                Query
              </h3>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name *</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.name}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                  onBlur={handleBlur("name")}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email address *</Form.Label>
                <Form.Control
                  type="email"
                  value={formData.email}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                  onBlur={handleBlur("email")}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="phone">
                <Form.Label>Phone Number *</Form.Label>
                <PhoneInput
                  international
                  defaultCountry="US"
                  value={formData.phone}
                  onChange={(value) => handleInputChange("phone", value)}
                  className={`form-control ${errors.phone ? "is-invalid" : ""}`}
                />
                {errors.phone && (
                  <div className="invalid-feedback">{errors.phone}</div>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="visa">
                <Form.Label>What visa are you interested in? *</Form.Label>
                <Form.Control
                  as="select"
                  value={formData.visa}
                  onChange={(e) => handleInputChange("visa", e.target.value)}
                  onBlur={handleBlur("visa")}
                  isInvalid={!!errors.visa}
                >
                  <option value="">-- Please Choose an Option --</option>
                  <option value="Tourist Visa">Tourist Visa</option>
                  <option value="Student Visa">Student Visa</option>
                  <option value="Work Visa">Work Visa</option>
                  <option value="Family Visa">Family Visa</option>
                  <option value="Business Visa">Business Visa</option>
                  <option value="Business Visa">Other</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.visa}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="message">
                <Form.Label>Message *</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={formData.message}
                  onChange={(e) => handleInputChange("message", e.target.value)}
                  onBlur={handleBlur("message")}
                  isInvalid={!!errors.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Upload File</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => handleInputChange("file", e.target.files[0])}
                  ref={fileInputRef}
                />
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                style={{ backgroundColor: "#001f80", borderColor: "#cb0731" }}
              >
                Submit
              </Button>
            </Form>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={6} sm={12}>
            <h1 className="contact-us-heading m-5">
              Our <b className="contact-us-agent-b">Agent mission</b> is to
              exceed expectations
            </h1>

            <Row>
              <Col lg={12} md={12} sm={12}>
                <div className="m-5">
                  <ul>
                    <li className="contact-us-heading-p">
                      Consult our Australian Visa Agents for precise and
                      informed visa guidance. Our trusted Migration Consultants
                      offer reliable advice to navigate your migration process
                      effectively.
                    </li>
                    <li className="mt-3">
                      From initial consultation to visa application For
                      Australia, we provide comprehensive services to meet all
                      your immigration requirements.
                    </li>
                    <li className="mt-3">
                      From initial consultation to visa application For
                      Australia, we provide comprehensive services to meet all
                      your immigration requirements.
                    </li>
                    <li className="mt-3">
                      Our ultimate goal is to exceed your expectations,
                      providing exceptional service and support throughout your
                      immigration journey toword Australia
                    </li>
                    <li className="mt-3">
                      We provide timely updates on changes in immigration laws
                      and regulations For Australia, keeping you informed and
                      prepared.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={6} sm={12} className="contact-us-image-col">
            <img
              src={contactUsImage}
              alt="Visa Application"
              className="img-fluid contact-us-image"
            />
          </Col>
        </Row>
      </>
    </Layout>
  );
};

export default ContactUs;
