// File path: src/pages/Blog.js

import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { NavLink } from "react-router-dom";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    // Fetch blog data from API
    axios
      .get(`${process.env.REACT_APP_API}user/view-blogs`)
      .then((response) => {
        // Check if the response contains the expected blogs array
        if (response.data && Array.isArray(response.data.blogs)) {
          setBlogs(response.data.blogs);
        } else {
          console.error("Unexpected response format:", response.data);
          setBlogs([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching blog data:", error);
        setBlogs([]);
      });
  }, []);

  return (
    <Layout
      title="Real Migration and Education Blog | Your Guide to Australian Migration and Education"
      description="Discover expert insights and guidance on Australian migration and education. Real Migration and Education offers reliable advice to help you achieve your dreams."
    >
      <Container>
        <h1 className="my-4" style={{ textAlign: "center" }}>
          Latest Blogs on Australia Migration and Education
        </h1>
        <Row>
          {blogs.length > 0 ? (
            blogs.map((blog, index) => (
              <Col md={4} key={index}>
                <Card className="mb-4">
                  <Card.Img variant="top" src={`./uploads/${blog.img}`} />
                  <Card.Body>
                    <Card.Title>{blog.title}</Card.Title>
                    <NavLink to={`/user/ViewBlog/${blog._id}`}>
                      <Button variant="primary">View More</Button>
                    </NavLink>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <p>No blogs available.</p>
          )}
        </Row>
      </Container>
    </Layout>
  );
};

export default Blog;
