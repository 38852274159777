import React from "react";
import Layout from "../../../../components/Layout/Layout";
import { Row, Col, Button } from "react-bootstrap";
import SubClass_189 from "../../../../images/visaImages/visa_189_1.png";
import "./SkilledIndependent_189.css";
import { Link, NavLink } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
const SkilledIndependent_189 = () => {
  return (
    <Layout
      title="Skilled Independent Visa (Subclass 189) - Comprehensive Guide"
      description="Learn everything about the Skilled Independent Visa (Subclass 189), including eligibility, application process, requirements, and benefits. Get detailed information on how to apply and succeed."
      keywords="Skilled Independent Visa 189, Subclass 189 Visa, Australian Independent Visa, Visa 189 Requirements, How to Apply for Visa 189, Australian Permanent Residency"
    >
      <>
        <Row className="m-5">
          <Col lg={6} md={6} sm={12}>
            <img
              src={SubClass_189}
              alt="skilledindependent-visa-189"
              className="img-fluid subclass-189-image"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <h2 className="subclass-189-h1">
              Skilled Independent Visa{" "}
              <span className="subclass-189-h1-span">(SUBCLASS 189)</span>
            </h2>

            <p className="subclass-189-p">
              Are you a skilled professional with expertise in fields highly
              sought after in Australia?
              <NavLink to="#" className="subclass-189-a">
                Start your journey by applying for your Visa 189 online today!
              </NavLink>
              Consider applying for a Skilled Independent Visa. This visa offers
              the chance to live and work permanently in Australia, allowing you
              to make the most of the opportunities in one of the world’s most
              dynamic economies.
            </p>
            <Link to="/contact" className="btn btn-enquire m-2">
              Call Now
            </Link>
          </Col>
        </Row>

        {/* //tab section */}
        <div className="row m-5 ">
          <div className="col-12">
            <div className="choose-us-title-view">
              <h2 className="choose-us-title">
                Skilled Independent Visa{" "}
                <b className="span-text">(Subclass 189)</b> Overview
              </h2>
            </div>
            <p>
              The Skilled Independent Visa (Subclass 189) is a permanent
              residency visa for skilled workers who are not sponsored by an
              employer, state, or territory. This visa is designed to attract
              highly skilled workers to Australia to contribute to the country’s
              economy. Successful applicants can live and work permanently in
              Australia and enjoy the benefits that come with permanent
              residency.
            </p>
          </div>
          <div className="col-12 ">
            <ul className="nav nav-tabs custom-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active custom-nav-link"
                  id="success-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#success"
                  type="button"
                  role="tab"
                  aria-controls="success"
                  aria-selected="true"
                >
                  <i className="fas fa-clipboard-list fa-2x"></i>
                  <br />
                  Eligibility Criteria
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="permanent-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#permanent"
                  type="button"
                  role="tab"
                  aria-controls="permanent"
                  aria-selected="false"
                >
                  <i className="fas fa-check-circle fa-2x"></i>
                  <br />
                  Why Visa 189?
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="priority-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#priority"
                  type="button"
                  role="tab"
                  aria-controls="priority"
                  aria-selected="false"
                >
                  <i className="fas fa-gift fa-2x"></i>
                  <br />
                  Visa 189 benefits
                </button>
              </li>
            </ul>
            <div className="tab-content custom-tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active custom-tab-pane"
                id="success"
                role="tabpanel"
                aria-labelledby="success-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>Eligibility Criteria for Visa 189</h3>
                    <p>
                      To be eligible for the Skilled Independent Visa (Subclass
                      189), applicants must meet the following criteria:
                    </p>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Occupation:</strong>
                          Your occupation must be on the Medium and Long-term
                          Strategic Skills List (MLTSSL).
                        </li>
                        <li className="subclass-189-li">
                          <strong>Skills Assessment:</strong>
                          Obtain a positive skills assessment from the relevant
                          assessing authority for your nominated occupation.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Points Test:</strong>
                          Achieve a minimum of 65 points on the points test,
                          which assesses factors such as age, English language
                          ability, work experience, and education.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Invitation to Apply:</strong>
                          Submit an Expression of Interest (EOI) through
                          SkillSelect and receive an invitation to apply.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Age:</strong>
                          Be under 45 years of age at the time of the
                          invitation.
                        </li>
                        <li className="subclass-189-li">
                          <strong>English Language:</strong>
                          Demonstrate competent English language skills.
                        </li>
                        <li>
                          <strong>Health and Character:</strong>
                          Meet health and character requirements as specified by
                          the Australian government.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="permanent"
                role="tabpanel"
                aria-labelledby="permanent-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Why Choose the Skilled Independent Visa (Subclass 189)?
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Permanent Residency:</strong>
                          The visa grants you permanent residency in Australia,
                          allowing you to live and work anywhere in the country
                          indefinitely.
                        </li>
                        <li className="subclass-189-li">
                          <strong>No Sponsorship Required:</strong>
                          Unlike other visas, the Skilled Independent Visa does
                          not require employer, state, or territory sponsorship,
                          giving you the freedom to choose where you want to
                          live and work.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Pathway to Citizenship:</strong>
                          This visa offers a clear pathway to Australian
                          citizenship after fulfilling residency requirements,
                          providing long-term security and benefits.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Family Inclusion:</strong>
                          You can include eligible family members in your
                          application, ensuring that your loved ones can join
                          you and enjoy the same benefits.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Access to Healthcare:</strong>
                          As a permanent resident, you can enroll in Australia’s
                          public healthcare scheme, Medicare, providing you and
                          your family with access to essential healthcare
                          services.
                        </li>
                        <li className="subclass-189-li">
                          <strong>High Demand Occupations:</strong>
                          If your occupation is on the Medium and Long-term
                          Strategic Skills List (MLTSSL), it indicates a high
                          demand for your skills in Australia, increasing your
                          chances of successful employment.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Comprehensive Rights:</strong>
                          Enjoy the right to work and study in Australia without
                          restrictions, access certain social security benefits,
                          and travel freely in and out of the country.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="priority"
                role="tabpanel"
                aria-labelledby="priority-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>Benefits of Skilled Independent Visa 189</h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Permanent Residency:</strong>
                          Live and work in Australia indefinitely with access to
                          the public healthcare system (Medicare).
                        </li>
                        <li className="subclass-189-li">
                          <strong>Family Inclusion:</strong>
                          Include eligible family members in your application,
                          allowing them to enjoy the same benefits.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Work and Study:</strong>
                          Work and study in Australia without restrictions.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Social Security Benefits:</strong>
                          Access certain social security payments after a
                          qualifying period.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Citizenship Pathway:</strong>
                          Apply for Australian citizenship after meeting
                          residency and other requirements.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h3 className="subclass-189-pt-h1">Application Process</h3>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Your occupation must be on the Medium and Long-term Strategic
                  Skills List (MLTSSL).
                </li>
                <li className="subclass-189-li">
                  Obtain a positive skills assessment from the relevant
                  assessing authority for your nominated occupation.
                </li>
                <li className="subclass-189-li">
                  Achieve a minimum of 65 points on the points test, which
                  assesses factors such as age, English language ability, work
                  experience, and education.
                </li>
                <li className="subclass-189-li">
                  Submit an Expression of Interest (EOI) through SkillSelect and
                  receive an invitation to apply.
                </li>
                <li className="subclass-189-li">
                  Be under 45 years of age at the time of the invitation.
                </li>
                <li className="subclass-189-li">
                  Demonstrate competent English language skills.
                </li>
                <li>
                  Meet health and character requirements as specified by the
                  Australian government.
                </li>
              </ul>
            </p>

            {/* .......point test............. */}
            <h3 className="subclass-189-pt-h1">Points Test</h3>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Age: 25-32 years (30 points), 18-24 & 33-39 years (25 points),
                  etc.
                </li>
                <li className="subclass-189-li">
                  English Language Ability: Superior (20 points), Proficient (10
                  points).
                </li>
                <li className="subclass-189-li">
                  Skilled Employment: In Australia or overseas, up to 20 points
                  depending on years of experience.
                </li>
                <li className="subclass-189-li">
                  Educational Qualifications: Doctorate (20 points),
                  Bachelor’s/Master’s (15 points).
                </li>
                <li className="subclass-189-li">
                  Other Factors: Australian study requirement, specialist
                  education qualification, accredited community language, etc.
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Related Visas</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <NavLink
                      to="/skilled-nominated-visa-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </NavLink>
                  </li>
                  <li className="subclass-189-li-a">
                    <NavLink
                      to="/skilled-work-visa-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </NavLink>
                  </li>
                  <li className="subclass-189-li-a">
                    <NavLink
                      to="/permanent-residence-visa-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </NavLink>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>

        {/* ................visa qualify............ */}
        <Row lg={12} className="subclass-189-vq-row">
          <h3 className="mt-5">Do you think you qualify for this visa?</h3>
          <p>
            Contact us if you would like to know more about this visa or need
            immigration advice.
          </p>
          <div className="mb-5">
            <Button
              href="/contact"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Contact
            </Button>
            <Button
              href="/appointment"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Book A Consultation
            </Button>
            <Button
              href="/initial-assessment-consultation"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Our Prices
            </Button>
          </div>
        </Row>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h3 className="subclass-189-pt-h1">
              Visa Conditions and Obligations
            </h3>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Compliance with Australian Laws:</strong>
                  As a visa holder, you must comply with all Australian laws.
                  Any serious legal violations can lead to visa cancellation or
                  other penalties.
                </li>
                <li className="subclass-189-li">
                  <strong>Initial Entry Date:</strong>
                  If your visa is granted while you are outside Australia, you
                  will be given an initial entry date. You must enter Australia
                  by this date, which is usually set within six months of the
                  visa grant.
                </li>
                <li className="subclass-189-li">
                  <strong>Residence Obligations:</strong>
                  While there is no strict residency requirement to maintain the
                  visa, spending significant time outside Australia may impact
                  your eligibility for citizenship and your ability to extend
                  your travel facility.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">
              Express Your Interest (EOI) in SkillSelect
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Submitting an EOI:</strong>
                  Submitting an Expression of Interest (EOI) through SkillSelect
                  is the first step in applying for the Subclass 189 visa. Your
                  EOI provides details about your skills, work experience, and
                  qualifications, which are used to assess your eligibility.
                </li>
                <li className="subclass-189-li">
                  <strong>Invitation Rounds:</strong>
                  EOIs are reviewed in invitation rounds held by the Department
                  of Home Affairs. Invitations to apply for the visa are issued
                  to applicants with the highest points scores in each round.
                </li>
                <li className="subclass-189-li">
                  <strong>Updating Your EOI:</strong>
                  You can update your EOI with new information or to improve
                  your points score. Keeping your EOI up-to-date ensures you
                  have the best chance of receiving an invitation.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">
              English Language Requirements
            </h3>
            <p>
              To meet the English language requirement, you must demonstrate
              competent English. This can be done through:
            </p>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>IELTS:</strong>A minimum score of 6 in each component.
                </li>
                <li className="subclass-189-li">
                  <strong>TOEFL iBT:</strong>A minimum score of 12 in Listening,
                  13 in Reading, 21 in Writing, and 18 in Speaking.
                </li>
                <li className="subclass-189-li">
                  <strong>PTE Academic:</strong>A minimum score of 50 in each
                  component.
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Google Adds</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>

            <div className="subclass-189-rv-card-shadow mt-5">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Latest Post</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>

        {/* faqs section */}
        <div className="row testimonial-background visa_question-acc">
          <div className="col-lg-12 cl-sm-12">
            <div className="testimonial-heading">
              {/* <h1 className="home-h1">Success Stories</h1> */}
              <h4 className="about-us-h4">
                FAQs About Australia Skilled Independent Visa (Subclass 189)
              </h4>
            </div>

            <>
              <Row>
                <Col lg={12} className="">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        What is the Australia Skilled Independent Visa (Subclass
                        189)?
                      </Accordion.Header>
                      <Accordion.Body>
                        The Subclass 189 visa is a points-tested permanent visa
                        for skilled workers who are not sponsored by an
                        employer, family member, or state/territory government.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Who is eligible for the Subclass 189 visa?
                      </Accordion.Header>
                      <Accordion.Body>
                        Applicants must have an occupation on the relevant
                        skilled occupation list, meet the points test
                        requirement, have a suitable skills assessment, and
                        receive an invitation to apply.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        What is the points test, and how does it work for the
                        Subclass 189 visa?
                      </Accordion.Header>
                      <Accordion.Body>
                        The points test assesses factors like age, English
                        proficiency, skilled employment, educational
                        qualifications, and other criteria. A minimum of 65
                        points is required to be eligible.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        How do I submit an Expression of Interest (EOI) for the
                        Subclass 189 visa?
                      </Accordion.Header>
                      <Accordion.Body>
                        Candidates submit an EOI through the Department of Home
                        Affairs' SkillSelect system. Based on the information
                        provided, eligible candidates may receive an invitation
                        to apply for the visa.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        Can I include family members in my Subclass 189 visa
                        application?
                      </Accordion.Header>
                      <Accordion.Body>
                        Yes, you can include eligible family members, such as
                        your partner and dependent children, in your visa
                        application.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        What are the English language requirements for the
                        Subclass 189 visa?
                      </Accordion.Header>
                      <Accordion.Body>
                        Applicants must demonstrate English proficiency,
                        typically through a standardized test like IELTS, PTE,
                        or TOEFL, unless exempt due to citizenship from an
                        English-speaking country.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                        How long does it take to process a Subclass 189 visa
                        application?
                      </Accordion.Header>
                      <Accordion.Body>
                        Processing times vary but generally range from several
                        months to over a year, depending on individual
                        circumstances and the volume of applications.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="7">
                      <Accordion.Header>
                        Can I apply for the Subclass 189 visa if I am already in
                        Australia?
                      </Accordion.Header>
                      <Accordion.Body>
                        Yes, you can apply for this visa both from within
                        Australia and from outside the country, provided you
                        meet all eligibility criteria.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="8">
                      <Accordion.Header>
                        What are the benefits of holding a Subclass 189 visa?
                      </Accordion.Header>
                      <Accordion.Body>
                        The visa allows you to live, work, and study anywhere in
                        Australia permanently, sponsor eligible relatives for
                        permanent residence, and, eventually, apply for
                        Australian citizenship.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="9">
                      <Accordion.Header>
                        What should I do after receiving an invitation to apply
                        for the Subclass 189 visa?
                      </Accordion.Header>
                      <Accordion.Body>
                        After receiving an invitation, you have 60 days to lodge
                        a complete visa application, including all required
                        documents and information. It’s crucial to ensure
                        accuracy and completeness to avoid delays.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Row>
            </>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default SkilledIndependent_189;
