import React, { useState, useRef } from "react";
import AdminLayout from "../../../../components/AdminLayout/AdminLayout";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBTextArea,
} from "mdb-react-ui-kit";
import axios from "axios";

const WriteBlog = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const maxWords = 2000;
  const fileInputRef = useRef(null);

  const handleImageUpload = (event) => {
    setImage(event.target.files[0]);
  };

  const handlePublish = async (e) => {
    e.preventDefault();

    if (!title || !description || !image) {
      setErrorMessage("All fields are required.");
      setSuccessMessage(""); // Clear any existing success message
      return;
    }
    setErrorMessage(""); // Clear any existing error

    const data = new FormData();
    data.append("title", title);
    data.append("description", description);
    data.append("img", image);

    for (let [key, value] of data.entries()) {
      console.log(key, value);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}api/blog/blog-post`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        setSuccessMessage("Your blog has been submitted successfully.");
        setErrorMessage(""); // Clear any existing error message

        // Clear the form after successful submission
        setTitle("");
        setDescription("");
        setImage(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }

        // Clear success message after 2 seconds
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
      } else {
        setErrorMessage("Something went wrong with the form submission.");
        setSuccessMessage(""); // Clear any existing success message
      }
    } catch (err) {
      console.error("Error during form submission:", err);
      setErrorMessage("Something went wrong with the form submission.");
      setSuccessMessage(""); // Clear any existing success message
    }
  };

  const handleDescriptionChange = (e) => {
    const words = e.target.value.split(/\s+/);
    if (words.length <= maxWords) {
      setDescription(e.target.value);
    }
  };

  const wordCount = description
    .split(/\s+/)
    .filter((word) => word.length > 0).length;
  const wordsLeft = maxWords - wordCount;

  return (
    <AdminLayout>
      <MDBContainer>
        <MDBRow className="justify-content-center mt-5">
          <MDBCol md="8">
            <h2 className="text-center mb-4">Write Your Blog</h2>
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            {successMessage && (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}
            <form onSubmit={handlePublish}>
              <div className="mb-4">
                <label htmlFor="titleInput" className="form-label">
                  Enter Title
                </label>
                <MDBInput
                  id="titleInput"
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="descriptionTextArea" className="form-label">
                  Write Description (2000 words max)
                </label>
                <MDBTextArea
                  id="descriptionTextArea"
                  rows={8}
                  value={description}
                  onChange={handleDescriptionChange}
                />
                <small className="form-text text-muted">
                  {wordsLeft} words left
                </small>
              </div>
              <div className="mb-4">
                <label htmlFor="fileInput" className="form-label">
                  Upload Related Image
                </label>
                <MDBInput
                  type="file"
                  id="fileInput"
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                />
              </div>
              <button className="btn btn-primary mb-5" type="submit">
                Publish Your Blog
              </button>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </AdminLayout>
  );
};

export default WriteBlog;
