import React from "react";
import Layout from "../../../../components/Layout/Layout";
import { Row, Col, Button } from "react-bootstrap";
import visa_485 from "../../../../images/visaImages/visa_485_1.png";

import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import "./StgVisa_485.css";

const StgVisa_485 = () => {
  return (
    <Layout
      title="Complete Guide to the Subclass 485 Temporary Graduate Visa - Eligibility, Benefits, and Application Process"
      description="Learn about the Subclass 485 Temporary Graduate Visa, including eligibility criteria, benefits, and the step-by-step application process. Discover how this visa helps international graduates stay and work in Australia."
      keywords="Subclass 485 visa, Temporary Graduate visa, 485 visa eligibility, 485 visa benefits, 485 visa application process, post-study work Australia, graduate visa Australia"
    >
      <>
        <Row className="m-5">
          <Col lg={6} md={6} sm={12}>
            <img
              src={visa_485}
              alt="skilledindependent-visa-189"
              className="img-fluid subclass-189-image"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <h2 className="subclass-189-h1">
              Temporary Graduate Visa
              <span className="subclass-189-h1-span">(Subclass 485)</span>
            </h2>

            <p className="subclass-189-p">
              Are you looking to gain valuable work experience in Australia
              after completing your studies?
              <a href="#" className="subclass-189-a">
                Start your journey by applying for your Visa 485 online today!
              </a>
              This visa, known as the Temporary Graduate Visa (Subclass 485), is
              designed for international students who have recently graduated
              from an Australian educational institution. It provides them with
              an opportunity to gain valuable work experience in their field of
              study.
            </p>
            <Button href="/contact" className="mt-3 contact-us-call-button">
              Call Now
            </Button>
          </Col>
        </Row>

        {/* //tab section */}
        <div className="row m-5 ">
          <div className="col-12">
            <div className="choose-us-title-view">
              <h2 className="choose-us-title">
                Skilled Temporary Graduate Visa
                <b className="span-text">(Subclass 485)</b> Overview
              </h2>
            </div>
            <p>
              The <b>Subclass 485 Temporary Graduate Visa</b> allows
              international students who have recently graduated from an
              Australian educational institution to live, study, and work in
              Australia temporarily. This visa aims to help graduates gain
              valuable work experience in Australia, enhancing their career
              prospects.
            </p>
          </div>
          <div className="col-12 ">
            <ul className="nav nav-tabs custom-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active custom-nav-link"
                  id="success-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#success"
                  type="button"
                  role="tab"
                  aria-controls="success"
                  aria-selected="true"
                >
                  <i className="fas fa-clipboard-list fa-2x"></i>
                  <br />
                  Eligibility Criteria
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="permanent-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#permanent"
                  type="button"
                  role="tab"
                  aria-controls="permanent"
                  aria-selected="false"
                >
                  <i className="fas fa-check-circle fa-2x"></i>
                  <br />
                  Why Visa 485?
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="priority-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#priority"
                  type="button"
                  role="tab"
                  aria-controls="priority"
                  aria-selected="false"
                >
                  <i className="fas fa-gift fa-2x"></i>
                  <br />
                  Visa 485 benefits
                </button>
              </li>
            </ul>
            <div className="tab-content custom-tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active custom-tab-pane"
                id="success"
                role="tabpanel"
                aria-labelledby="success-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>Eligibility Criteria for the Subclass 485 Visa</h3>
                    <p>
                      To be eligible for the Subclass 485 visa, applicants must
                      meet the following criteria:
                    </p>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Recent Graduate:</strong>
                          You must have graduated from an Australian educational
                          institution within the last 6 months.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Qualification Requirement:</strong>
                          You must hold an eligible qualification, such as a
                          bachelor’s degree, master’s degree, or doctoral
                          degree.
                        </li>
                        <li className="subclass-189-li">
                          <strong>English Language Proficiency:</strong>
                          You must meet the English language requirements,
                          generally a minimum IELTS score or equivalent.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Health and Character Requirements:</strong>
                          You must meet Australian health and character
                          requirements. This typically includes undergoing
                          health examinations and providing police certificates.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Australian Study Requirement:</strong>
                          You must have completed at least 2 years of study in
                          Australia at a CRICOS-registered institution.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Age Requirement:</strong>
                          You must be under 50 years of age at the time of
                          application.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="permanent"
                role="tabpanel"
                aria-labelledby="permanent-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Why Choose the Subclass 485 Temporary Graduate Visa?
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Transition from Study to Work:</strong>
                          The Subclass 485 visa bridges the gap between
                          completing your studies and gaining practical work
                          experience in Australia, enhancing your employability.
                        </li>
                        <li className="subclass-189-li">
                          <strong>
                            {" "}
                            Opportunity to Build a Career in Australia:
                          </strong>
                          Allows graduates to begin their professional careers
                          in a dynamic and supportive environment, paving the
                          way for long-term career development.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Gain International Work Experience:</strong>
                          Provides the chance to gain valuable work experience
                          in an international setting, which is highly regarded
                          by employers worldwide.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Access to Australian Job Market:</strong>
                          Enables you to explore job opportunities in Australia,
                          leveraging your Australian education and
                          qualifications.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Pathway to Permanent Residency:</strong>
                          Offers a clear pathway to other visas, such as the
                          Subclass 189 or Subclass 190, which can lead to
                          permanent residency in Australia.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="priority"
                role="tabpanel"
                aria-labelledby="priority-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Key Benefits of the Subclass 485 Temporary Graduate Visa
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Work Experience:</strong>
                          Enables graduates to gain practical work experience in
                          Australia, enhancing their skills and employability.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Extended Stay:</strong>
                          Allows graduates to stay in Australia for up to 4
                          years, depending on their qualifications and the
                          stream they apply for.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Pathway to Permanent Residency:</strong>
                          Provides a pathway to other visas, such as the
                          Subclass 189 or Subclass 190, which can lead to
                          permanent residency.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Family Inclusion:</strong>
                          You can include immediate family members in your visa
                          application, allowing them to live, work, and study in
                          Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Work and Study Flexibility:</strong>
                          Freedom to work full-time and pursue further studies
                          during the visa period.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h3 className="subclass-189-pt-h1">Application Process</h3>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Passport and personal identification documents.
                </li>
                <li className="subclass-189-li">
                  Academic transcripts and completion letters from your
                  Australian educational institution.
                </li>
                <li className="subclass-189-li">
                  English Language Proficiency IELTS test results or equivalent.
                </li>
                <li className="subclass-189-li">
                  Evidence of adequate health insurance coverage for the
                  duration of your stay.
                </li>
                <li className="subclass-189-li">
                  Health examination results and police certificates, if
                  applicable.
                </li>
                <li className="subclass-189-li">
                  Select the Subclass 485 visa application form and fill out the
                  required information. Ensure that all details are accurate and
                  complete.
                </li>
                <li className="subclass-189-li">
                  If the Department of Home Affairs requires further information
                  or documents, respond promptly to avoid delays. Check your
                  ImmiAccount regularly for updates.
                </li>
              </ul>
            </p>

            {/* .......point test............. */}
            <h3 className="subclass-189-pt-h1">Points Test</h3>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Age: 25-32 years (30 points), 18-24 & 33-39 years (25 points),
                  etc.
                </li>
                <li className="subclass-189-li">
                  English Language Ability: Superior (20 points), Proficient (10
                  points).
                </li>
                <li className="subclass-189-li">
                  Skilled Employment: In Australia or overseas, up to 20 points
                  depending on years of experience.
                </li>
                <li className="subclass-189-li">
                  Educational Qualifications: Doctorate (20 points),
                  Bachelor’s/Master’s (15 points),Diploma or trade
                  qualification: 10 points
                </li>
                <li className="subclass-189-li">
                  Other Factors: Australian study requirement, specialist
                  education qualification, accredited community language, etc.
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Related Visas</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="/skilled-recognised-graduate-476-visa"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled—Recognised Graduate visa 476
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="/temporary-skills-shortage-visa-482
"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Temporary Skill Shortage visa Subclass 482
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="
/skilled-employer-sponsored-regional-494-visa"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Employer Sponsored Regional (Provisional) visa 494
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/temporary-activity-408-visa"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Temporary Activity Visa (Subclass 408)
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/skilled-work-visa-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>

        {/* ................visa qualify............ */}
        <Row lg={12} className="subclass-189-vq-row">
          <h3 className="mt-5">Do you think you qualify for this visa?</h3>
          <p>
            Contact us if you would like to know more about this visa or need
            immigration advice.
          </p>
          <div className="mb-5">
            <Button
              href="/contact"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Contact
            </Button>
            <Button
              href="/appointment"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Book A Consultation
            </Button>
            <Button
              href="
/initial-assessment-consultation"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Our Prices
            </Button>
          </div>
        </Row>

        <Row>
          <Col>
            <div className="row m-5">
              <h1 className="home-h1">Streams of the Subclass 485 Visa</h1>
              <div className="col-lg-6 col-md-12">
                <h5 className="subclass-189-pt-h1">Graduate Work Stream:</h5>
                <p className="subclass-189-p subclass-189-pt-col">
                  <ul>
                    <li className="subclass-189-li">
                      For international students who have recently graduated
                      with skills and qualifications that relate to an
                      occupation on the Medium and Long-term Strategic Skills
                      List (MLTSSL).
                    </li>
                    <li className="subclass-189-li">
                      Visa duration: Up to 18 months.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="col-lg-6 col-md-12">
                <h5 className="subclass-189-pt-h1">Post-Study Work Stream:</h5>
                <p className="subclass-189-p subclass-189-pt-col">
                  <ul>
                    <li className="subclass-189-li">
                      For international students who have recently graduated
                      with a higher education degree from an Australian
                      institution, regardless of their field of study.
                    </li>
                    <li className="subclass-189-li">
                      Visa duration: Typically 2 to 4 years, depending on the
                      qualification.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h5 className="subclass-189-pt-h1">
              Conditions and Obligations for Subclass 485 Temporary Graduate
              Visa
            </h5>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Work Rights</strong>
                  Allows international graduates to work in Australia for up to
                  18 months (Graduate Work stream) or 2-3 years (Post-Study Work
                  stream).
                </li>
                <li className="subclass-189-li">
                  <strong>Study Requirement:</strong>
                  Requires completion of at least two years of study in
                  Australia.
                </li>
                <li className="subclass-189-li">
                  <strong>Health Insurance: </strong>
                  Mandatory health insurance coverage throughout the visa
                  duration.
                </li>
                <li className="subclass-189-li">
                  <strong>Compliance:</strong>
                  Must comply with Australian laws and visa conditions.
                </li>
                <li className="subclass-189-li">
                  <strong>Temporary Stay:</strong>
                  Provides temporary residency to gain work experience.
                </li>
                <li className="subclass-189-li">
                  <strong>Pathway to PR:</strong>
                  Can be a pathway to permanent residency through skilled
                  migration.
                </li>
                <li className="subclass-189-li">
                  <strong>Employment Focus</strong>
                  Requires employment relevant to the graduate's field of study.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">
              Express Your Interest in the Subclass 485 Temporary Graduate Visa
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Complete the EOI Form:</strong>
                  Fill in the Expression of Interest (EOI) form with detailed
                  information about your skills, work experience, and
                  qualifications.
                </li>
                <li className="subclass-189-li">
                  <strong>Nominate Occupation:</strong>
                  Select an occupation from the relevant skilled occupation list
                  that matches your skills and qualifications.
                </li>
                <li className="subclass-189-li">
                  <strong>Specify Visa Category:</strong>
                  Select the Temporary Activity Visa (Subclass 485) as your visa
                  category choice.
                </li>
                <li className="subclass-189-li">
                  <strong>Submit Your EOI:</strong>
                  Submit your Expression of Interest (EOI) for evaluation by the
                  Department of Home Affairs, where it will be assessed based on
                  the nature and details of your proposed activity or event in
                  Australia.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">
              English Language Requirements
            </h3>
            <p>
              To meet the English language requirement, you must demonstrate
              competent English. This can be done through:
            </p>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>IELTS:</strong>A minimum score of 6 in each component
                  (Listening, Reading, Writing, and Speaking).
                </li>
                <li className="subclass-189-li">
                  <strong>TOEFL iBT:</strong>A minimum score of 12 in Listening,
                  13 in Reading, 21 in Writing, and 18 in Speaking.
                </li>
                <li className="subclass-189-li">
                  <strong>PTE Academic:</strong>A minimum score of 50 in each
                  component (Listening, Reading, Writing, and Speaking).
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Google Adds</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>

            <div className="subclass-189-rv-card-shadow mt-5">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Latest Post</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </>
      {/* faqs section */}
      <div className="row testimonial-background visa_question-acc">
        <div className="col-lg-12 cl-sm-12">
          <div className="testimonial-heading">
            {/* <h1 className="home-h1">Success Stories</h1> */}
            <h4 className="about-us-h4">
              Frequently Asked Questions (FAQs) about the Subclass 485 Visa
            </h4>
          </div>

          <>
            <Row>
              <Col lg={12} className="">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Can I apply for the Subclass 485 Visa if I have completed
                      a diploma or certificate course in Australia?
                    </Accordion.Header>
                    <Accordion.Body>
                      The Subclass 485 Visa is generally for graduates with
                      higher education degrees. However, if your qualification
                      relates to an occupation on the MLTSSL, you may be
                      eligible under the Graduate Work Stream.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      What happens if my Subclass 485 Visa application is
                      refused?
                    </Accordion.Header>
                    <Accordion.Body>
                      If your application is refused, you will receive a
                      notification explaining the reasons for refusal and your
                      options for review or appeal.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Can I apply for permanent residency while on a Subclass
                      485 Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, the Subclass 485 Visa provides a pathway to other
                      visas, such as the Subclass 189 or Subclass 190, which can
                      lead to permanent residency.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      How soon should I apply for the Subclass 485 Visa after
                      graduating?
                    </Accordion.Header>
                    <Accordion.Body>
                      You must apply within 6 months of completing your course
                      in Australia to be eligible for the Subclass 485 Visa.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Can my family members work and study in Australia on a
                      Subclass 485 Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, immediate family members included in your visa
                      application can live, work, and study in Australia for the
                      duration of your visa.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Can I extend my Subclass 485 Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      The Subclass 485 Visa cannot be extended. However, you may
                      be eligible to apply for another visa, such as a skilled
                      visa or a permanent residency visa, if you meet the
                      criteria.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Can I include my partner in my Subclass 485 Visa
                      application?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can include your spouse or de facto partner in
                      your visa application, along with any dependent children.
                      They will also be granted the same visa conditions and
                      validity.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      Do I need to have a job offer to apply for the Subclass
                      485 Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, you do not need a job offer to apply for the Subclass
                      485 Visa. This visa allows you to stay in Australia to
                      search for work after completing your studies.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      What are the insurance requirements for the Subclass 485
                      Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      You must have adequate health insurance for the duration
                      of your stay in Australia. This can be Overseas Visitor
                      Health Cover (OVHC) or any other health insurance that
                      meets the visa requirements.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      Can I travel outside Australia while my Subclass 485 Visa
                      application is being processed?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can travel outside Australia while your Subclass
                      485 Visa application is being processed. However, you must
                      ensure that you hold a valid visa to re-enter Australia.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </>
        </div>
      </div>
    </Layout>
  );
};

export default StgVisa_485;
