import React from "react";
import Layout from "../../components/Layout/Layout";
import { Row, Col, Button } from "react-bootstrap";
import contactUsImage from "../../images/contactUs.jpg";
import { Link, NavLink } from "react-router-dom";
import aboutUs from "../../images/aboutUs.jpg";
import "./InitialAssessment.css";

const InitialAssessment = () => {
  return (
    <Layout
      title="Initial Assessment Consultation - Real Migration and Education: Expert Immigration Guidance"
      description="Schedule your initial assessment consultation with Real Migration and Education. Our expert team offers personalized immigration advice and guidance for Australian visa applications, including skilled, partner, and family visas. Start your journey to Australia with trusted support."
      keywords="Initial assessment consultation, Real Migration and Education, Australian immigration experts, visa application help, immigration advice, skilled visa consultation, partner visa consultation, family visa assistance, Australian visa services, immigration consultation booking, PR in Australia guidance, student visa consultation, migration pathway advice, immigration consultancy services"
    >
      <Row className="m-5">
        <Col lg={6} md={6} sm={12}>
          <h2 className="choose-us-title">
            Consultation <b className="span-text">And</b> Initial Assessment
          </h2>
          <p className="contact-us-heading-p mt-5">
            At Real Migration And Education, we offer a comprehensive initial
            assessment of your current situation to understand your immigration
            objectives and identify the specific assistance you require. Our
            goal is to tailor our services to meet your unique needs and help
            you achieve your immigration goals effectively.
          </p>
          <div className="contact-us-email-container">
            <span>Email us at:</span>
            <a href="mailto:info@realmigration.com.au" className="email-link">
              info@realmigration.com.au
            </a>
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} className="contact-us-image-col">
          <img
            src={contactUsImage}
            alt="Visa Application"
            className="img-fluid contact-us-image"
          />
        </Col>
      </Row>
      <Row className="initail-consult-bg">
        <h2 className="choose-us-title " style={{ textAlign: "center" }}>
          What Is an Initial Assessment?{" "}
          <b className="span-text">Begin Your Journey with </b> Real Migration
          And Education
        </h2>
        <Col>
          <Row className="m-5">
            <Col lg={6} md={6} sm={12}>
              <p className="contact-us-heading-p mt-3">
                The consultation and initial assessment at Real Migration And
                Education enable us to gather all relevant details about your
                case, ensuring we can provide you with accurate immigration
                advice regarding your visa options. This thorough understanding
                of your circumstances helps us guide you effectively toward the
                best possible outcome for your immigration journey.
              </p>
              <p className="contact-us-heading-p mt-3">
                At Real Migration And Education, our consultation and initial
                assessment gather all relevant details about your case. We
                analyze the most up-to-date Australian migration laws and
                policies to provide you with accurate immigration advice and
                insights into the legal complexities applicable to your
                situation. This comprehensive approach ensures we guide you
                effectively toward the best possible outcome for your
                immigration journey.
              </p>
              <p>
                <span>
                  <b>During the consultation we will explore and determine:</b>
                </span>
                <p>
                  <ul>
                    <li>Possible visa options</li>
                    <li>Best migration strategy and migration roadmap.</li>
                    <li>Best migration strategy and migration roadmap.</li>
                    <li>General eligibility</li>
                    <li>Likelihood of approval</li>
                    <li>Possible pitfalls or shortcomings to be overcome</li>
                    <li>Visa cost</li>
                    <li>Visa processing times</li>
                    <li>Visa conditions</li>
                  </ul>
                </p>
              </p>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <p className="contact-us-heading-p mt-3">
                After the 60-minute meeting, you will receive a detailed written
                report outlining all the information discussed. This report will
                guide you on how to proceed and what steps to take before
                submitting your visa application, ensuring you are well-prepared
                and informed.
              </p>
              <p className="contact-us-heading-p mt-3">
                During our initial consultation at{" "}
                <b>Real Migration And Education</b>, we will provide you with
                insights into the legislation applicable to your situation, as
                well as potential outcomes, risks, and costs associated with
                your migration issue. Our consultations focus on listening to
                you, understanding your unique circumstances, and answering your
                questions. This thorough approach allows us to offer tailored
                immigration advice that best suits your needs. Trust Real
                Migration And Education to guide you through the complexities of
                the immigration process with expert, personalized support.
              </p>
              <p>
                At Real Migration And Education, we are Registered Migration
                Agents who are passionate about every case we undertake. If you
                are genuinely interested in the services of a dedicated,
                knowledgeable, and enthusiastic team, we want to hear from you!
                Let us help you achieve your immigration goals with our expert
                guidance and support.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>

      <h2 className="choose-us-title s-card-heading mt-5">
        <b className="span-text">Consultation</b> Types
      </h2>
      <div className="row m-5 ">
        <div className="col-lg-3 col-md-6 col-sm-12 ">
          <div className="card mb-3">
            <div className="card-body s-card-heading initail-consult-card">
              <h2 className="card-title ">Skilled and Training visas</h2>
              <p className="card-text">
                At Real Migration And Education, we thoroughly analyze your
                migration goals, qualifications, and work experience to assess
                your eligibility for skilled visas and create a tailored
                migration roadmap to achieve your immigration objectives.
              </p>
              <p>
                <h2>
                  <b>$100</b>
                </h2>
              </p>
              <Link to="/appointment" className="btn btn-enquire mt-4">
                Book Now
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="card mb-3">
            <div className="card-body s-card-heading initail-consult-card">
              <h2 className="card-title ">Employer-Sponsored visas</h2>
              <p className="card-text">
                Real Migration And Education helps business sponsors and visa
                applicants assess their eligibility for employer-sponsored visa
                programs, guiding you through the process and exploring your
                options.
              </p>
              <p>
                <h2>
                  <b>$100</b>
                </h2>
              </p>
              <Link to="/appointment" className="btn btn-enquire mt-4">
                Book Now
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="card mb-3">
            <div className="card-body s-card-heading initail-consult-card">
              <h2 className="card-title ">Family and Partner visas</h2>
              <p className="card-text">
                Real Migration And Education assesses your unique circumstances
                to find the best migration pathway to Australia for you and your
                family. We specialize in Partner, Parent, and Child visas,
                providing personalized guidance to meet your needs.
              </p>
              <p>
                <h2>
                  <b>$100</b>
                </h2>
              </p>
              <Link to="/appointment" className="btn btn-enquire mt-4">
                Book Now
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="card mb-3">
            <div className="card-body s-card-heading initail-consult-card">
              <h2 className="card-title ">
                AAT review, Refusal & Cancellation matter
              </h2>
              <p className="card-text">
                In our initial consultation, we aim to fully understand the
                assistance you are seeking and discuss your situation in detail
                with a Registered Migration Agent, ensuring we address all
                aspects of your case.
              </p>
              <p>
                <h2>
                  <b>$100</b>
                </h2>
              </p>
              <Link to="/appointment" className="btn btn-enquire mt-4">
                Book Now
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* ................visa qualify............ */}
      <Row lg={12} className="subclass-189-vq-row">
        <p>
          <h3 className="mt-5">Consultation time is 45 minutes.</h3>
        </p>
        <p>
          After the online consultation, you receive a Professional Consultation
          Report (10-15 pages) with the key points that have been discussed.
        </p>
        <p>
          Should you have any questions, send us an email:
          info@realmigration.com.au, or use our contact form.
        </p>
        <div className="mb-5">
          <Button
            className="subclass-189-vq-button subclass-189-vq-btn-margin"
            href="mailto:info@realmigration.com.au"
          >
            Send Email
          </Button>
          <Button
            className="subclass-189-vq-button subclass-189-vq-btn-margin"
            href="/contact"
          >
            Fill Contact Form
          </Button>
        </div>
      </Row>
    </Layout>
  );
};

export default InitialAssessment;
