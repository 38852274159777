import React from "react";
import Layout from "../../../../components/Layout/Layout";
import { Row, Col, Button } from "react-bootstrap";
import visa_186 from "../../../../images/visaImages/visa_186_1.png";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import "./EnVisa_186.css";

const EnVisa_186 = () => {
  return (
    <Layout
      title="Complete Guide to the Subclass 186 Employer Nomination Scheme Visa - Eligibility, Benefits, and Application Process"
      description="Learn about the Subclass 186 Employer Nomination Scheme Visa, including eligibility criteria, benefits, and the step-by-step application process. Discover how this visa provides a pathway to permanent residency in Australia."
      keywords="Subclass 186 visa, Employer Nomination Scheme visa, 186 visa eligibility, 186 visa benefits, 186 visa application process, work in Australia, permanent residency Australia"
    >
      <>
        <Row className="m-5">
          <Col lg={6} md={6} sm={12}>
            <img
              src={visa_186}
              alt="skilledindependent-visa-189"
              className="img-fluid subclass-189-image"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <h2 className="subclass-189-h1">
              Employer Nomination Scheme Visa
              <span className="subclass-189-h1-span">(Subclass 186)</span>
            </h2>

            <p className="subclass-189-p">
              Are you aiming to secure skilled employment in Australia and
              contribute to its dynamic workforce?
              <a href="#" className="subclass-189-a">
                Start your journey by applying for your Visa 186 online today!
              </a>
              This visa allows you to work for an Australian employer in a
              nominated occupation, paving the way for permanent residency. It's
              an opportunity to showcase your skills, gain valuable work
              experience, and enjoy the diverse opportunities Australia has to
              offer.
            </p>
            <Button href="/contact" className="mt-3 contact-us-call-button">
              Call Now
            </Button>
          </Col>
        </Row>

        {/* //tab section */}
        <div className="row m-5 ">
          <div className="col-12">
            <div className="choose-us-title-view">
              <h2 className="choose-us-title">
                Employer Nomination Scheme (ENS) Visa
                <b className="span-text">(Subclass 186)</b> Overview
              </h2>
            </div>
            <p>
              The <b>Subclass 186 Employer Nomination Scheme (ENS) Visa</b>
              allows skilled workers nominated by their employer to live and
              work in Australia permanently. This visa is part of Australia's
              permanent Employer Sponsored visa program, which helps Australian
              employers address labor shortages by bringing in skilled workers
              from overseas.
            </p>
          </div>
          <div className="col-12 ">
            <ul className="nav nav-tabs custom-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active custom-nav-link"
                  id="success-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#success"
                  type="button"
                  role="tab"
                  aria-controls="success"
                  aria-selected="true"
                >
                  <i className="fas fa-clipboard-list fa-2x"></i>
                  <br />
                  Eligibility Criteria
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="permanent-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#permanent"
                  type="button"
                  role="tab"
                  aria-controls="permanent"
                  aria-selected="false"
                >
                  <i className="fas fa-check-circle fa-2x"></i>
                  <br />
                  Why Visa 186?
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="priority-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#priority"
                  type="button"
                  role="tab"
                  aria-controls="priority"
                  aria-selected="false"
                >
                  <i className="fas fa-gift fa-2x"></i>
                  <br />
                  Visa 186 benefits
                </button>
              </li>
            </ul>
            <div className="tab-content custom-tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active custom-tab-pane"
                id="success"
                role="tabpanel"
                aria-labelledby="success-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Eligibility Criteria for the Subclass 186 Employer
                      Nomination Scheme Visa
                    </h3>
                    <p>
                      To qualify for the Subclass 186 visa, applicants must meet
                      the following eligibility criteria:
                    </p>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Employer Nomination:</strong>
                          You must be nominated by an approved Australian
                          employer for a skilled position.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Skills and Qualifications:</strong>
                          Your skills and qualifications must align with a
                          position listed on the relevant skilled occupation
                          list.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Work Experience:</strong>
                          You must have the necessary work experience relevant
                          to the nominated occupation.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Age Requirements:</strong>
                          You must meet any age requirements specified for the
                          visa subclass.
                        </li>
                        <li className="subclass-189-li">
                          <strong>English Language Proficiency:</strong>
                          Unless exempt, you must demonstrate English language
                          proficiency through recognized tests.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Health and Character:</strong>
                          You must meet health and character requirements set by
                          the Australian Government.
                        </li>
                        <li className="subclass-189-li">
                          <strong>
                            Temporary Residence Transition Stream:
                          </strong>
                          If applying through this stream, you must have held a
                          relevant temporary visa and met additional
                          requirements.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="permanent"
                role="tabpanel"
                aria-labelledby="permanent-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Why Choose the Subclass 186 Employer Nomination Scheme
                      Visa?
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Pathway to Permanent Residency:</strong>
                          Offers a direct route to obtaining permanent residency
                          status in Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong> Employment Stability:</strong>
                          Provides long-term employment prospects with an
                          Australian employer.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Settlement Benefits:</strong>
                          Facilitates integration into Australian society, with
                          access to healthcare and education systems.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Family Inclusion:</strong>
                          Allows eligible family members to join the visa holder
                          in Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Employer Support:</strong>
                          Demonstrates commitment from an Australian employer,
                          enhancing professional credibility.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Flexibility in Career Advancement:</strong>
                          Opportunities to change employers and roles within
                          Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Contributing to Regional Development:</strong>
                          Supports regional areas by addressing specific skill
                          shortages.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="priority"
                role="tabpanel"
                aria-labelledby="priority-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Benefits of the Subclass 186 Employer Nomination Scheme
                      Visa
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Permanent Residency:</strong>
                          Enables indefinite stay and work rights in Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Healthcare and Education:</strong>
                          Access to subsidized healthcare and quality education
                          for children.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Social Security Benefits:</strong>
                          Eligibility for various social security payments and
                          benefits.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Work Flexibility:</strong>
                          Ability to work in any occupation and location in
                          Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Pathway to Citizenship:</strong>
                          Eligibility to apply for Australian citizenship after
                          meeting residency requirements.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Travel:</strong>
                          Allows multiple entries to and from Australia during
                          the visa validity period.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Rights of Family Members:</strong>
                          Spouses and dependent children can accompany and work
                          or study in Australia.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h3 className="subclass-189-pt-h1">Application Process</h3>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Employer lodges a nomination application for the skilled
                  position.
                </li>
                <li className="subclass-189-li">
                  Applicant undergoes a skills assessment by a relevant
                  assessing authority
                </li>
                <li className="subclass-189-li">
                  Lodge the visa application online, providing necessary
                  documentation.
                </li>
                <li className="subclass-189-li">
                  Applicants must meet health and character requirements set by
                  the Australian government.
                </li>
                <li className="subclass-189-li">
                  The employer's nomination must be approved before the visa
                  application can proceed.
                </li>
                <li className="subclass-189-li">
                  Once approved, the visa is granted, allowing the applicant to
                  work and live in Australia.
                </li>
                <li className="subclass-189-li">
                  Visa holders must comply with Australian laws, including work
                  conditions and residency obligations.
                </li>
              </ul>
            </p>

            {/* .......point test............. */}
            <h3 className="subclass-189-pt-h1">Points Test</h3>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Age: 25-32 years (30 points), 18-24 & 33-39 years (25 points),
                  etc.
                </li>
                <li className="subclass-189-li">
                  English Language Ability: Superior (20 points), Proficient (10
                  points).
                </li>
                <li className="subclass-189-li">
                  Skilled Employment: In Australia or overseas, up to 20 points
                  depending on years of experience.
                </li>
                <li className="subclass-189-li">
                  Educational Qualifications: Doctorate (20 points),
                  Bachelor’s/Master’s (15 points),Diploma or trade
                  qualification: 10 points
                </li>
                <li className="subclass-189-li">
                  Other Factors: Australian study requirement, specialist
                  education qualification, accredited community language, etc.
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Related Visas</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="/temporary-skills-shortage-visa-482"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Temporary Skill Shortage visa Subclass 482
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/skilled-employer-sponsored-regional-494-visa"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Employer Sponsored Regional (Provisional) visa 494
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/temporary-activity-408-visa"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Temporary Activity Visa (Subclass 408)
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/skilled-work-visa-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>

        {/* ................visa qualify............ */}
        <Row lg={12} className="subclass-189-vq-row">
          <h3 className="mt-5">Do you think you qualify for this visa?</h3>
          <p>
            Contact us if you would like to know more about this visa or need
            immigration advice.
          </p>
          <div className="mb-5">
            <Button
              href="/contact"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Contact
            </Button>
            <Button
              href="/appointment"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Book A Consultation
            </Button>
            <Button
              href="/initial-assessment-consultation"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Our Prices
            </Button>
          </div>
        </Row>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h5 className="subclass-189-pt-h1">
              Visa Conditions and Obligations for Subclass 186 Employer
              Nomination Scheme Visa
            </h5>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Employer Sponsorship:</strong>
                  You must be sponsored by an approved Australian employer who
                  nominates you for a skilled position.
                </li>
                <li className="subclass-189-li">
                  <strong>Nominated Occupation:</strong>
                  You must work in the occupation for which your employer
                  nominated you. Changes to your occupation may require a new
                  nomination and visa application.
                </li>
                <li className="subclass-189-li">
                  <strong>Regional Work (if applicable): </strong>
                  Depending on the stream, you may be required to live and work
                  in a designated regional area of Australia for a specified
                  period.
                </li>
                <li className="subclass-189-li">
                  <strong>Health and Character Requirements:</strong>
                  You must meet health and character requirements as set out by
                  the Department of Home Affairs.
                </li>
                <li className="subclass-189-li">
                  <strong>Visa Conditions:</strong>
                  Compliance with specific visa conditions attached to your
                  subclass, including any restrictions on work, study, or
                  travel.
                </li>
                <li className="subclass-189-li">
                  <strong>Notification of Changes:</strong>
                  You must notify the Department of Home Affairs of any changes
                  in your circumstances, such as changes in address, employment,
                  or marital status.
                </li>
                <li className="subclass-189-li">
                  <strong>Contribution to Australia:</strong>
                  Demonstrate your commitment to contributing positively to
                  Australia's economy and community.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">
              Express Your Interest in the Subclass 494 Visa
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Complete the EOI Form:</strong>
                  Fill in the Expression of Interest (EOI) form with detailed
                  information about your skills, work experience, and
                  qualifications.
                </li>
                <li className="subclass-189-li">
                  <strong>Nominate Occupation:</strong>
                  Select an occupation from the relevant skilled occupation list
                  that matches your skills and qualifications.
                </li>
                <li className="subclass-189-li">
                  <strong>Specify Visa Category:</strong>
                  Select the Temporary Activity Visa (Subclass 494) as your visa
                  category choice.
                </li>
                <li className="subclass-189-li">
                  <strong>Submit Your EOI:</strong>
                  Submit your Expression of Interest (EOI) for evaluation by the
                  Department of Home Affairs, where it will be assessed based on
                  the nature and details of your proposed activity or event in
                  Australia.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">
              English Language Requirements
            </h3>
            <p>
              To meet the English language requirement, you must demonstrate
              competent English. This can be done through:
            </p>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>IELTS:</strong>A minimum score of 6 in each component
                  (Listening, Reading, Writing, and Speaking).
                </li>
                <li className="subclass-189-li">
                  <strong>TOEFL iBT:</strong>A minimum score of 12 in Listening,
                  13 in Reading, 21 in Writing, and 18 in Speaking.
                </li>
                <li className="subclass-189-li">
                  <strong>PTE Academic:</strong>A minimum score of 50 in each
                  component (Listening, Reading, Writing, and Speaking).
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Google Adds</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>

            <div className="subclass-189-rv-card-shadow mt-5">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Latest Post</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </>
      {/* faqs section */}
      <div className="row testimonial-background visa_question-acc">
        <div className="col-lg-12 cl-sm-12">
          <div className="testimonial-heading">
            {/* <h1 className="home-h1">Success Stories</h1> */}
            <h4 className="about-us-h4">
              frequently asked questions (FAQs) about the Subclass 186 visa
              (Employer Nomination Scheme visa)
            </h4>
          </div>

          <>
            <Row>
              <Col lg={12} className="">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      What is the Subclass 186 visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      The Subclass 186 visa, also known as the Employer
                      Nomination Scheme visa, is a permanent residency visa that
                      allows skilled workers nominated by an approved Australian
                      employer to live and work in Australia permanently.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Who can apply for the Subclass 186 visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Skilled workers who have been nominated by an approved
                      Australian employer under the Employer Nomination Scheme
                      can apply for the Subclass 186 visa. There are different
                      streams available depending on the applicant's
                      circumstances.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      What are the streams under the Subclass 186 visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      The Subclass 186 visa has three streams: the Temporary
                      Residence Transition stream, the Direct Entry stream, and
                      the Agreement stream.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What is the Temporary Residence Transition stream?
                    </Accordion.Header>
                    <Accordion.Body>
                      This stream is for subclass 457/482 visa holders who have
                      worked for their employer in their nominated occupation
                      for at least three years and who have been nominated by
                      their employer for a permanent position in that
                      occupation.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      What is the Direct Entry stream?
                    </Accordion.Header>
                    <Accordion.Body>
                      The Direct Entry stream is for applicants who have been
                      nominated by their employer under the Direct Entry stream,
                      have never or only briefly worked in Australia, or are
                      temporary residents who do not qualify for the Temporary
                      Residence Transition stream.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What is the Agreement stream?
                    </Accordion.Header>
                    <Accordion.Body>
                      The Agreement stream is for applicants sponsored by an
                      employer through a tailored and negotiated labour
                      agreement or regional migration agreement.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      What are the eligibility criteria for the Subclass 186
                      visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Eligibility criteria include being nominated by an
                      approved employer, having skills and qualifications
                      relevant to the nominated occupation, meeting health and
                      character requirements, and meeting age and English
                      language proficiency requirements.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      Can family members be included in the Subclass 186 visa
                      application?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, eligible family members such as partners and
                      dependent children can be included in the visa
                      application.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      What are the obligations of Subclass 186 visa holders?
                    </Accordion.Header>
                    <Accordion.Body>
                      Visa holders are obligated to work for their sponsoring
                      employer for a specified period, comply with visa
                      conditions, maintain health insurance, and adhere to
                      Australian laws.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      Is the Subclass 186 visa a pathway to Australian
                      citizenship?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, after meeting residency and other requirements,
                      Subclass 186 visa holders may be eligible to apply for
                      Australian citizenship.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </>
        </div>
      </div>
    </Layout>
  );
};

export default EnVisa_186;
