import React from "react";
import Layout from "../../../../components/Layout/Layout";
import { Row, Col, Button } from "react-bootstrap";
import visa_173 from "../../../../images/visaImages/Visa_173_1.png";

import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import "./CPVisa_173.css";

const CPVisa_173 = () => {
  return (
    <Layout
      title="Complete Guide to Contributory Parent Visa Subclass 173: Eligibility, Benefits, and Application Process"
      description="Learn all about the Contributory Parent Visa Subclass 173. Understand eligibility criteria, benefits, application process, and more to join your family in Australia."
      keywords="Contributory Parent Visa 173, Australian Parent Visa, Subclass 173 Visa, Parent Visa Eligibility, Australia Family Visa, Parent Visa Benefits, Parent Visa Application Process"
    >
      <>
        <Row className="m-5">
          <Col lg={6} md={6} sm={12}>
            <img
              src={visa_173}
              alt="skilledindependent-visa-189"
              className="img-fluid subclass-189-image"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <h2 className="subclass-189-h1">
              Contributory Parent Visa
              <span className="subclass-189-h1-span">(Subclass 173)</span>
            </h2>

            <p className="subclass-189-p">
              Ready to join your children in Australia?
              <a href="#" className="subclass-189-a">
                Start your application today for the Contributory Parent Visa
                (Subclass 173)!
              </a>
              This visa is perfect for parents wishing to reunite with their
              children who are Australian citizens, permanent residents, or
              eligible New Zealand citizens. Whether you aim for family
              gatherings, exploring Australia's stunning landscapes, or living
              in a vibrant society, this visa opens the door to your
              aspirations. Create lasting memories with your loved ones and
              build a promising future together in Australia. Apply now and take
              the first step towards a joyful reunion!
            </p>
            <Button href="/contact" className="mt-3 contact-us-call-button">
              Call Now
            </Button>
          </Col>
        </Row>

        {/* //tab section */}
        <div className="row m-5 ">
          <div className="col-12">
            <div className="choose-us-title-view">
              <h2 className="choose-us-title">
                Contributory Parent Temporary visa
                <b className="span-text">(Subclass 173)</b> OverView
              </h2>
            </div>
            <p>
              The Contributory Parent Visa Subclass 173 is a temporary visa that
              allows parents of Australian citizens, permanent residents, or
              eligible New Zealand citizens to live in Australia for up to two
              years. This visa serves as a pathway to the permanent Contributory
              Parent Visa (Subclass 143). It is designed to help families
              reunite and enjoy life together in Australia.
            </p>
          </div>
          <div className="col-12 ">
            <ul className="nav nav-tabs custom-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active custom-nav-link"
                  id="success-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#success"
                  type="button"
                  role="tab"
                  aria-controls="success"
                  aria-selected="true"
                >
                  <i className="fas fa-clipboard-list fa-2x"></i>
                  <br />
                  Eligibility Criteria
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="permanent-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#permanent"
                  type="button"
                  role="tab"
                  aria-controls="permanent"
                  aria-selected="false"
                >
                  <i className="fas fa-check-circle fa-2x"></i>
                  <br />
                  Why Visa 173?
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="priority-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#priority"
                  type="button"
                  role="tab"
                  aria-controls="priority"
                  aria-selected="false"
                >
                  <i className="fas fa-gift fa-2x"></i>
                  <br />
                  Visa 173 benefits
                </button>
              </li>
            </ul>
            <div className="tab-content custom-tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active custom-tab-pane"
                id="success"
                role="tabpanel"
                aria-labelledby="success-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Eligibility Criteria for Contributory Parent Visa Subclass
                      173
                    </h3>
                    <p>
                      To be eligible for the Contributory Parent Visa Subclass
                      173, applicants must meet the following criteria:
                    </p>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Child’s Citizenship:</strong>
                          The applicant must have a child who is an Australian
                          citizen, permanent resident, or eligible New Zealand
                          citizen.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Balance of Family Test:</strong>
                          At least half of the applicant's children must live
                          permanently in Australia, or more of their children
                          live in Australia than in any other country.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Health Requirements: </strong> The applicant
                          must meet specific health standards as outlined by the
                          Australian immigration authorities.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Character Requirements: </strong>
                          The applicant must meet certain character
                          requirements, including providing police certificates.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Assurance of Support: </strong>
                          An assurance of support must be provided to ensure
                          that the applicant does not rely on government
                          assistance.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="permanent"
                role="tabpanel"
                aria-labelledby="permanent-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Why Choose the Contributory Parent Visa (Subclass 173)?
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Pathway to Permanent Residency </strong>
                          Provides a temporary stay that can lead to the
                          permanent Subclass 143 visa.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Reunite with Family:</strong>
                          Allows parents to live with their children in
                          Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Temporary Residency:</strong>
                          Live in Australia for up to two years.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Work Rights:</strong>
                          The ability to work in Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Travel Freedom:</strong>
                          Freedom to travel in and out of Australia during the
                          visa period.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Access to Healthcare:</strong>
                          Limited access to Australia's healthcare system.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Flexible Pathway:</strong>
                          Offers a step-by-step approach to gaining permanent
                          residency.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="priority"
                role="tabpanel"
                aria-labelledby="priority-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>Benefits of Contributory Parent Visa Subclass 173</h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Family Reunification: </strong>
                          Live with your children in Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Temporary Residency: </strong>
                          Provides a two-year stay in Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Pathway to Permanent Residency:</strong>
                          Easier transition to the permanent Contributory Parent
                          Visa (Subclass 143).
                        </li>
                        <li className="subclass-189-li">
                          <strong>Work Rights:</strong>
                          The ability to work and earn in Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Travel Freedom: </strong>
                          Unlimited travel to and from Australia during the visa
                          period.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Access to Medicare:</strong>
                          Limited access to Medicare for basic healthcare
                          services.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Social Integration:</strong>
                          Opportunity to integrate into Australian society
                          before applying for permanent residency.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h3 className="subclass-189-pt-h1">Application Process</h3>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Ensure you meet all eligibility criteria.
                </li>
                <li className="subclass-189-li">
                  Gather necessary documents, including proof of relationship,
                  health and character certificates.
                </li>
                <li className="subclass-189-li">
                  Lodge your application with the Department of Home Affairs.
                </li>
                <li className="subclass-189-li">
                  Pay the required visa application fee.
                </li>
                <li className="subclass-189-li">
                  The processing time can vary.
                </li>
                <li className="subclass-189-li">
                  Complete required health examinations.
                </li>
                <li className="subclass-189-li">
                  If approved, you will receive your visa grant notice.
                </li>
              </ul>
            </p>

            {/* .......point test............. */}
            <h3 className="subclass-189-pt-h1">
              Points Test for Australia Visa 500
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              {/* <ul>
                                                            <li className="subclass-189-li">
                                                              Age: 25-32 years (30 points), 18-24 & 33-39 years (25 points),
                                                              etc.
                                                            </li>
                                                            <li className="subclass-189-li">
                                                              English Language Ability: Superior (20 points), Proficient (10
                                                              points).
                                                            </li>
                                                            <li className="subclass-189-li">
                                                              Skilled Employment: In Australia or overseas, up to 20 points
                                                              depending on years of experience.
                                                            </li>
                                                            <li className="subclass-189-li">
                                                              Educational Qualifications: Doctorate (20 points),
                                                              Bachelor’s/Master’s (15 points),Diploma or trade
                                                              qualification: 10 points
                                                            </li>
                                                            <li className="subclass-189-li">
                                                              Other Factors: Australian study requirement, specialist
                                                              education qualification, accredited community language, etc.
                                                            </li>
                                                          </ul> */}
              There is no points test for the Contributory Parent Visa Subclass
              173. Eligibility is based on meeting specific criteria related to
              family ties, health, and character.
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Related Visas</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="/parent-visa-103"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 103 Parent visa
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="/aged-parent-visa-804"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 804 Aged Parent visa
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/contributory-parent-visa-143"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (Subclass 143) Contributory Parent visa
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/contributory-aged-parent-visa-884"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 884 Contributory Aged Parent (Temporary) visa
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="/contributory-aged-parent-visa-864"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (Subclass 864) Contributory Aged Parent visa
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>

        {/* ................visa qualify............ */}
        <Row lg={12} className="subclass-189-vq-row">
          <h3 className="mt-5">Do you think you qualify for this visa?</h3>
          <p>
            Contact us if you would like to know more about this visa or need
            immigration advice.
          </p>
          <div className="mb-5">
            <Button
              href="/contact"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Contact
            </Button>
            <Button
              href="/appointment"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Book A Consultation
            </Button>
            <Button
              href="/initial-assessment-consultation"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Our Prices
            </Button>
          </div>
        </Row>

        {/* <Row>
                          <Col>
                            <div className="row m-5">
                              <h1 className="home-h1">
                                Offshore Partner Visa (Subclass 309/100) Two Stage:
                              </h1>
                              <div className="col-lg-6 col-md-12">
                                <h5 className="subclass-189-pt-h1">
                                  Partner visa (Temporary) Visa 309
                                </h5>
                                <p className="subclass-189-p subclass-189-pt-col">
                                  
                                  Must be outside of Australia to apply for this visa. Prior to
                                  applying, gather all required paperwork, including health,
                                  character, and sponsorship information. Use the online
                                  application process to apply for the visa when you are away
                                  from home.
                                </p>
                              </div>
                              <div className="col-lg-6 col-md-12">
                                <h5 className="subclass-189-pt-h1">
                                  Partner visa (Permanent) Visa 100
                                </h5>
                                <p className="subclass-189-p subclass-189-pt-col">
                                  
                                  There is no need to provide any more information if Subclass
                                  309 of the Visa comes right after Subclass 100. However, if a
                                  Visa Subclass 100 is not immediately issued, the authorities
                                  must be given the necessary information. Two years following
                                  application lodging, it will be assessed; submit the required
                                  paperwork one month beforehand. Submission of an application
                                  through an online system.
                                </p>
                              </div>
                             
                            </div>
                          </Col>
                        </Row> */}

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h5 className="subclass-189-pt-h1">Conditions and Obligations</h5>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Assurance of Support:</strong>
                  An assurance of support must be provided.
                </li>
                <li className="subclass-189-li">
                  <strong>Health Insurance: </strong>
                  Maintain adequate health insurance.
                </li>
                <li className="subclass-189-li">
                  <strong>Good Character: </strong>
                  Continue to meet character requirements.
                </li>
                <li className="subclass-189-li">
                  <strong>Comply with Australian Laws: </strong>
                  Adhere to all Australian laws and visa conditions.
                </li>
                <li className="subclass-189-li">
                  <strong>Transition to Subclass 143: </strong>
                  Plan to apply for the permanent Contributory Parent Visa
                  (Subclass 143) within the visa period.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">Express Your Interest</h3>
            <p className="subclass-189-p subclass-189-pt-col">
              {/* <ul>
                                                <li className="subclass-189-li">
                                                  <strong>Research: </strong>
                                                  Thoroughly research the visa requirements and benefits.
                                                </li>
                                                <li className="subclass-189-li">
                                                  <strong>Preparation:</strong>
                                                  Prepare all necessary documents and financial proofs.
                                                </li>
                                                <li className="subclass-189-li">
                                                  <strong>Application:</strong>
                                                  Complete and submit the online application.
                                                </li>
                                                <li className="subclass-189-li">
                                                  <strong>Follow-up:</strong>
                                                  Monitor your application status through ImmiAccount.
                                                </li>
                                                <li className="subclass-189-li">
                                                  <strong>Visa Grant:</strong>
                                                  Upon approval, make travel and accommodation arrangements.
                                                </li>
                                              </ul> */}
              To express your interest in the Contributory Parent Visa Subclass
              173, you must submit a complete application and meet all the
              necessary criteria. It is advisable to seek professional guidance
              to ensure your application is correctly submitted.
            </p>

            <h3 className="subclass-189-pt-h1">
              English Language Requirements
            </h3>
            <p>
              To meet the English language requirement, you must demonstrate
              competent English. This can be done through:
            </p>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>IELTS:</strong>A minimum score of 6 in each component
                  (Listening, Reading, Writing, and Speaking).
                </li>
                <li className="subclass-189-li">
                  <strong>TOEFL iBT:</strong>A minimum score of 12 in Listening,
                  13 in Reading, 21 in Writing, and 18 in Speaking.
                </li>
                <li className="subclass-189-li">
                  <strong>PTE Academic:</strong>A minimum score of 50 in each
                  component (Listening, Reading, Writing, and Speaking).
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Google Adds</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>

            <div className="subclass-189-rv-card-shadow mt-5">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Latest Post</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </>
      {/* faqs section */}
      <div className="row testimonial-background visa_question-acc">
        <div className="col-lg-12 cl-sm-12">
          <div className="testimonial-heading">
            {/* <h1 className="home-h1">Success Stories</h1> */}
            <h4 className="about-us-h4">
              Frequently Asked Questions (FAQs) about the Contributory Parent
              Visa (Subclass 173)?
            </h4>
          </div>

          <>
            <Row>
              <Col lg={12} className="">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      How long does it take to process the Contributory Parent
                      Visa Subclass 173?
                    </Accordion.Header>
                    <Accordion.Body>
                      The processing time can vary depending on individual
                      circumstances and demand.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Can I include my spouse in the Contributory Parent Visa
                      Subclass 173 application?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, your spouse can be included in your visa application.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      What is the Assurance of Support?
                    </Accordion.Header>
                    <Accordion.Body>
                      The Assurance of Support is a legal commitment from an
                      Australian resident to provide financial support to the
                      applicant.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Do I need health insurance for the Contributory Parent
                      Visa Subclass 173?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, maintaining adequate health insurance is advisable.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Can I work on a Contributory Parent Visa Subclass 173?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, this visa allows you to work in Australia.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What are the character requirements?
                    </Accordion.Header>
                    <Accordion.Body>
                      Applicants must provide police certificates and meet
                      specific character requirements.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Is there an age limit for the Contributory Parent Visa
                      Subclass 173?
                    </Accordion.Header>
                    <Accordion.Body>
                      There is no age limit for this visa, but applicants must
                      meet other eligibility criteria.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      Can I access Medicare with this visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      You may have limited access to Medicare for basic
                      healthcare services.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      What documents are required for the application?
                    </Accordion.Header>
                    <Accordion.Body>
                      You need proof of relationship, health certificates,
                      character certificates, and other supporting documents.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      Can my application be expedited?
                    </Accordion.Header>
                    <Accordion.Body>
                      Generally, applications cannot be expedited due to the
                      high volume of applications and limited visa places.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </>
        </div>
      </div>
    </Layout>
  );
};

export default CPVisa_173;
