import React, { useState } from "react";
import "./SideBar.css";
import logo from "../../../images/logo.png";
import { NavLink } from "react-router-dom";
// import { Link, NavLink } from "react-router-dom";
// import DashboardIcon from "@mui/icons-material/Dashboard";
// import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
// import BookOnlineOutlinedIcon from "@mui/icons-material/BookOnlineOutlined";
// import AutofpsSelectOutlinedIcon from "@mui/icons-material/AutofpsSelectOutlined";
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
// import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";

const SideBar = () => {
  const [style, setStyle] = useState(
    "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
  );
  const changeStyle = () => {
    if (
      style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };

  return (
    <div id="wrapper">
      {/*  <!-- Sidebar --> */}
      <ul className={style} id="accordionSidebar">
        {/*  <!-- Sidebar - Brand --> */}
        <a
          className="sidebar-brand d-flex align-items-center justify-content-center"
          href="/"
        >
          {/* <div className="sidebar-brand-icon rotate-n-15">
            <i className="fas fa-laugh-wink"></i>
          </div> */}
          <div className="sidebar-brand-text mx-3">
            <img
              src={logo}
              alt="Real Migration Logo"
              style={{ height: "55px", width: "100%" }}
            />
          </div>
          <div className="text-center d-none d-md-inline">
            <button
              className="rounded-circle border-0"
              id="sidebarToggle"
              onClick={changeStyle}
            ></button>
          </div>
        </a>

        {/*   <!-- Divider --> */}
        <hr className="sidebar-divider my-0" />

        {/*  <!-- Nav Item - Dashboard --> */}
        <li className="nav-item active">
          <NavLink className="nav-link" to="/dashboard/admin">
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </NavLink>
        </li>

        {/*  <!-- Divider --> */}
        <hr className="sidebar-divider" />

        {/*   <!-- Heading --> */}
        <div className="sidebar-heading">Interface</div>

        {/*  <!-- Nav Item - Pages Collapse Menu --> */}
        <li className="nav-item">
          <span
            className="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            <i className="fas fa-fw fa-cog"></i>
            <span>Users</span>
          </span>
          <div
            id="collapseTwo"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Custom Components:</h6>
              <NavLink className="collapse-item" to="/dashboard/admin/users">
                No of user's
              </NavLink>
              <NavLink className="collapse-item" to="/dashboard/admin/admins">
                No of Admin's
              </NavLink>
            </div>
          </div>
        </li>

        {/* <!-- Nav Item - Utilities Collapse Menu --> */}
        <li className="nav-item">
          <span
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#collapseUtilities"
            aria-expanded="true"
            aria-controls="collapseUtilities"
          >
            <i className="fas fa-fw fa-wrench"></i>
            <span>Form</span>
          </span>
          <div
            id="collapseUtilities"
            className="collapse"
            aria-labelledby="headingUtilities"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Custom Filled Form:</h6>
              <NavLink
                className="collapse-item"
                to="/dashboard/admin/contact-us"
              >
                Contact Us
              </NavLink>
              <NavLink className="collapse-item" href="utilities-border.html">
                CoE issues
              </NavLink>
              {/* <a className="collapse-item" href="utilities-animation.html">
                Animations
              </a>
              <a className="collapse-item" href="utilities-other.html">
                Other
              </a> */}
            </div>
          </div>
        </li>
        {/* <!-- Nav Item - Appointments --> */}
        <li className="nav-item">
          <span
            className="nav-link collapsed"
            to="#"
            data-toggle="collapse"
            data-target="#collapseUtilities1"
            aria-expanded="true"
            aria-controls="collapseUtilities"
          >
            <i className="fas fa-fw fa-wrench"></i>
            <span>Appointment</span>
          </span>
          <div
            id="collapseUtilities1"
            className="collapse"
            aria-labelledby="headingUtilities"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Appointments Details:</h6>
              <a className="collapse-item" href="/dashboard/admin/addagent">
                Add New Agent
              </a>
              <a
                className="collapse-item"
                href="/dashboard/admin/viewappointment"
              >
                View Appointments
              </a>
              <a className="collapse-item" href="/dashboard/admin/addservice">
                Add New Service
              </a>
              {/* <a className="collapse-item" href="utilities-animation.html">
                Animations
              </a>
              <a className="collapse-item" href="utilities-other.html">
                Other
              </a> */}
            </div>
          </div>
        </li>

        {/*  <!-- Divider --> */}
        <hr className="sidebar-divider" />

        {/* <!-- Heading --> */}
        <div className="sidebar-heading">Addons</div>

        {/*  <!-- Nav Item - Pages Collapse Menu --> */}
        <li className="nav-item">
          <span
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#collapsePages"
            aria-expanded="true"
            aria-controls="collapsePages"
          >
            <i className="fas fa-fw fa-folder"></i>
            <span>Post</span>
          </span>
          <div
            id="collapsePages"
            className="collapse"
            aria-labelledby="headingPages"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Blog's Detail:</h6>
              <NavLink
                className="collapse-item"
                to="/dashboard/admin/writeblog"
              >
                Upload Blog
              </NavLink>
              <NavLink
                className="collapse-item"
                to="/dashboard/admin/Viewblogs"
              >
                View All Blogs
              </NavLink>
              {/* <a className="collapse-item" href="forgot-password.html">
                Forgot Password
              </a>
              <div className="collapse-divider"></div>
              <h6 className="collapse-header">Other Pages:</h6>
              <a className="collapse-item" href="404.html">
                404 Page
              </a>
              <a className="collapse-item" href="blank.html">
                Blank Page
              </a> */}
            </div>
          </div>
        </li>

        {/* <!-- Nav Item - Charts --> */}
        {/* <li className="nav-item">
          <a className="nav-link" href="charts.html">
            <i className="fas fa-fw fa-chart-area"></i>
            <span>Charts</span>
          </a>
        </li> */}

        {/*  <!-- Nav Item - Tables --> */}
        {/* <li className="nav-item">
          <a className="nav-link" href="tables.html">
            <i className="fas fa-fw fa-table"></i>
            <span>Tables</span>
          </a>
        </li> */}

        {/* <!-- Divider --> */}
        {/* <hr className="sidebar-divider d-none d-md-block" /> */}

        {/*   <!-- Sidebar Toggler (Sidebar) --> */}
        {/*   <div className="text-center d-none d-md-inline">
                            <button className="rounded-circle border-0" id="sidebarToggle" onClick={changeStyle}></button>
                        </div> */}

        {/*  <!-- Sidebar Message --> */}
        {/* <div className="sidebar-card d-none d-lg-flex">
          <img
            className="sidebar-card-illustration mb-2"
            src="../../images/banner1.jpg"
            alt="..."
          />
          <p className="text-center mb-2">
            <strong>SB Admin Pro</strong> is packed with premium features,
            components, and more!
          </p>
          <a
            className="btn btn-success btn-sm"
            href="https://startbootstrap.com/theme/sb-admin-pro"
          >
            Upgrade to Pro!
          </a>
        </div> */}
      </ul>
      {/*  <!-- End of Sidebar --> */}
    </div>
  );
};

export default SideBar;
