import React from "react";
import Layout from "../../components/Layout/Layout";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import johnImage from "../../images/shb.jpg";
import johnDoe from "../../images/smr.jpg";

//education cousellor
import ec1 from "../../images/ec1.jpg";
import ec2 from "../../images/ec2.jpg";
import ec3 from "../../images/ec3.jpg";
// tech team
import tech1 from "../../images/tck1.jpg";

const teamMembers = [
  {
    name: "Shahab Ahmed",
    role: "CEO",
    image: johnImage, // replace with the actual image path
  },
  {
    name: "Samra Shahab",
    role: "CEO",
    image: johnDoe, // replace with the actual image path
  },
];

const educationalCounsellor = [
  {
    name: "Samra Zawar",
    role: "Chief Operating Officer",
    image: ec1, // replace with the actual image path
  },
  {
    name: "Saira Saif",
    role: "Education Counsellor",
    image: ec2, // replace with the actual image path
  },
  {
    name: "Neelam",
    role: "Education Counsellor",
    image: ec3, // replace with the actual image path
  },
];

const techTeam = [
  {
    name: "Shahbaz Munir",
    role: "Software Engineer",
    image: tech1, // replace with the actual image path
  },
];

const OurTeam = () => {
  return (
    <Layout
      title="Expert Migration Agents | Personalized Visa Guidance | Real Migration and Education"
      description="Meet our team of experienced migration agents at Real Migration and Education, dedicated to providing personalized guidance for your Australian visa application. We understand the complexities of the visa process and ensure a smooth journey tailored to your specific needs."
      keywords="migration agents, Australian visa assistance, personalized migration help, expert visa guidance, visa application help, migration services Australia, skilled migration Australia, Real Migration and Education team"
    >
      <>
        <h1 className="text-center my-4">Our Team</h1>
        <Row className="justify-content-center mb-4">
          {teamMembers.map((member, index) => (
            <Col
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className="text-center mb-4"
            >
              <Image
                src={member.image}
                roundedCircle
                fluid
                style={{ width: "150px", height: "150px", objectFit: "cover" }}
                alt={member.name}
              />
              <h4 className="mt-3">{member.name}</h4>
              <p>{member.role}</p>
            </Col>
          ))}
        </Row>
        <Row>
          <Col>
            <Card className="m-5">
              <Card.Body>
                <Card.Title>Team Leader</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Shahab Ahmed
                </Card.Subtitle>
                <Card.Subtitle className="mb-2 text-muted">
                  MARN – 2318146
                </Card.Subtitle>
                <Card.Text>
                  Shahab Ahmad, a dedicated professional with a passion for
                  engineering, migration, and mentorship. Shahab’s journey is
                  marked by a profound commitment to guiding individuals towards
                  realizing their full potential and achieving their dreams.
                </Card.Text>
                <Card.Text>
                  In 2023, Shahab embarked on a professional journey driven by
                  his passion for migration. Recognizing the transformative
                  power of migration in individuals’ lives, Shahab committed
                  himself to facilitating this process for others. Whether it’s
                  navigating the complexities of relocation, embracing new
                  opportunities in unfamiliar territories, or building a sense
                  of belonging in a new environment, Shahab is dedicated to
                  supporting individuals every step of the way.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <h1 className="text-center my-4">Education Counsellor</h1>
        <Row className="justify-content-center mb-4">
          {educationalCounsellor.map((member, index) => (
            <Col
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className="text-center mb-4"
            >
              <Image
                src={member.image}
                roundedCircle
                fluid
                style={{ width: "170px", height: "200px", objectFit: "cover" }}
                alt={member.name}
              />
              <h4 className="mt-3">{member.name}</h4>
              <p>{member.role}</p>
            </Col>
          ))}
        </Row>

        <h1 className="text-center my-4">Tech Team </h1>
        <Row className="justify-content-center mb-4">
          {techTeam.map((member, index) => (
            <Col
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className="text-center mb-4"
            >
              <Image
                src={member.image}
                roundedCircle
                fluid
                style={{ width: "150px", height: "200px", objectFit: "cover" }}
                alt={member.name}
              />
              <h4 className="mt-3">{member.name}</h4>
              <p>{member.role}</p>
            </Col>
          ))}
        </Row>
      </>
    </Layout>
  );
};

export default OurTeam;
