import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Layout = ({
  children,
  title = "Real Migration And Education",
  description = "At Real Migration and Education, we recognize that every individual’s migration journey is unique. That’s why we provide personalized guidance tailored to your specific circumstances and goals. Our team of experienced migration agents understands the complexities of the Australian visa process and is committed to ensuring a smooth and successful application process for our clients.",
  keywords = "Real Migration, Australia Agent For visas, Moving Toward Australia, Australia Student Visa",
}) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <Header />
      <ToastContainer position="top-center" />
      <main style={{ minHeight: "30vh" }}>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
