import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Layout from "../../../components/Layout/Layout";
import { useAuth } from "../../../context/Auth";
import "./Login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { auth, setAuth } = useAuth();

  const validateField = (field, setter) => {
    if (!field.trim()) {
      setter(true);
      setFormValid(false);
    } else {
      setter(false);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    validateField(e.target.value, setEmailError);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    validateField(e.target.value, setPasswordError);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formValid) {
      toast.error("Please fill in all fields correctly.");
      return;
    }
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_API}api/auth/login`,
        { email, password }
      );
      if (resp && resp.data.success) {
        toast.success(resp.data.message);
        setAuth({ ...auth, user: resp.data.user, token: resp.data.token });
        localStorage.setItem("auth", JSON.stringify(resp.data));

        const userRole = resp.data.user.role;
        if (userRole === 1) {
          navigate("/dashboard/admin");
        } else {
          navigate(location.state || "/");
        }
      } else {
        toast.error(resp.data.message);
      }
    } catch (err) {
      toast.error(
        err.response
          ? err.response.data.message
          : "Network error or server is down"
      );
    }
  };

  useEffect(() => {
    setFormValid(email && password && !emailError && !passwordError);
  }, [email, password, emailError, passwordError]);

  return (
    <Layout title="Login - Real Migration and Education | Australian Visa Experts">
      <div className="authContainer">
        <div className="form-container sign-in">
          <form onSubmit={handleSubmit}>
            <h1>Login</h1>
            <span>Use your registered email</span>
            <input
              type="email"
              placeholder="Email *"
              className={emailError ? "error" : ""}
              value={email}
              onChange={handleEmailChange}
              onBlur={() => validateField(email, setEmailError)}
            />
            <div className="password-field">
              <input
                type="password"
                placeholder="Password *"
                className={passwordError ? "error" : ""}
                value={password}
                onChange={handlePasswordChange}
                onBlur={() => validateField(password, setPasswordError)}
              />
            </div>
            <button className="signin-button" disabled={!formValid}>
              Sign In
            </button>
            <p className="login-link">
              Don't have an account? <NavLink to="/register">Sign Up</NavLink>
            </p>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
