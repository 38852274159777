import React from "react";
import Layout from "../../../../components/Layout/Layout";
import { Row, Col, Button } from "react-bootstrap";
import visa_462 from "../../../../images/visaImages/visa_462_1.png";

import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import "./WHVisa_462.css";

const WHVisa_462 = () => {
  return (
    <Layout
      title="Comprehensive Guide to the Work and Holiday Visa (Subclass 462) for Australia"
      description="Discover the benefits, eligibility criteria, application process, and processing time for the Work and Holiday Visa (Subclass 462) to Australia. Your ultimate guide to working and traveling in Australia."
      keywords="Work and Holiday Visa 462, Subclass 462 visa, Australia work holiday, apply for Visa 462, benefits of Visa 462, Visa 462 processing time, work and travel Australia"
    >
      <>
        <Row className="m-5">
          <Col lg={6} md={6} sm={12}>
            <img
              src={visa_462}
              alt="skilledindependent-visa-189"
              className="img-fluid subclass-189-image"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <h2 className="subclass-189-h1">
              Work and Holiday Visa
              <span className="subclass-189-h1-span">(Subclass 462)</span>
            </h2>

            <p className="subclass-189-p">
              Are you planning to travel to Australia for work and cultural
              exchange?
              <a href="#" className="subclass-189-a">
                Start your journey by applying for your Visa 462 online today!
              </a>
              Take the first step towards your adventure by applying for your
              Visa 462 online today! This visa, known as the Work and Holiday
              Visa (Subclass 462), is designed for young adults seeking to
              explore Australia while gaining work experience. Whether you're
              immersing yourself in the vibrant culture, engaging in fulfilling
              work opportunities, or forging lasting friendships, this visa
              offers an enriching experience in Australia.
            </p>
            <Button href="/contact" className="mt-3 contact-us-call-button">
              Call Now
            </Button>
          </Col>
        </Row>

        {/* //tab section */}
        <div className="row m-5 ">
          <div className="col-12">
            <div className="choose-us-title-view">
              <h2 className="choose-us-title">
                Work and Holiday Visa
                <b className="span-text">(Subclass 462)</b> Overview
              </h2>
            </div>
            <p>
              The Work and Holiday Visa (Subclass 462) is your ticket to a
              year-long adventure in Australia, blending work and leisure
              seamlessly. Designed to foster cultural exchange and forge strong
              bonds between Australia and eligible countries, this visa offers
              young explorers a chance to immerse themselves in Australia's
              diverse landscapes, vibrant cities, and warm hospitality. It's
              your opportunity to work, travel, and create unforgettable
              memories Down Under.
            </p>
          </div>
          <div className="col-12 ">
            <ul className="nav nav-tabs custom-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active custom-nav-link"
                  id="success-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#success"
                  type="button"
                  role="tab"
                  aria-controls="success"
                  aria-selected="true"
                >
                  <i className="fas fa-clipboard-list fa-2x"></i>
                  <br />
                  Eligibility Criteria
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="permanent-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#permanent"
                  type="button"
                  role="tab"
                  aria-controls="permanent"
                  aria-selected="false"
                >
                  <i className="fas fa-check-circle fa-2x"></i>
                  <br />
                  Why Visa 462?
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="priority-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#priority"
                  type="button"
                  role="tab"
                  aria-controls="priority"
                  aria-selected="false"
                >
                  <i className="fas fa-gift fa-2x"></i>
                  <br />
                  Visa 462 benefits
                </button>
              </li>
            </ul>
            <div className="tab-content custom-tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active custom-tab-pane"
                id="success"
                role="tabpanel"
                aria-labelledby="success-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Eligibility Criteria for the Subclass 462 Work and Holiday
                      Visa
                    </h3>
                    <p>
                      To be eligible for the Work and Holiday Visa (Subclass
                      462), applicants must meet the following criteria:
                    </p>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Age:</strong>
                          Be aged between 18 and 30 years old (inclusive) at the
                          time of application.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Country of Passport:</strong>
                          Hold a passport from an eligible country. Refer to the
                          Australian Department of Home Affairs website for a
                          list of eligible countries.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Education:</strong>
                          Have completed at least two years of undergraduate
                          university study.
                        </li>
                        <li className="subclass-189-li">
                          <strong>English Proficiency:</strong>
                          Demonstrate functional English, which can be proven
                          through various means such as an IELTS test or
                          equivalent.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Financial Requirements:</strong>
                          Have sufficient funds to support yourself (generally
                          around AUD 5,000) and a return airfare or sufficient
                          funds to purchase one.
                        </li>
                        <li className="subclass-189-li">
                          <strong>No Dependent Children</strong>
                          You cannot be accompanied by dependent children during
                          your stay.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="permanent"
                role="tabpanel"
                aria-labelledby="permanent-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Why Choose the Work and Holiday Visa (Subclass 462)?
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Cultural Exchange:</strong>
                          Experience living in a new country, meet new people,
                          and immerse yourself in the Australian way of life.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Work Opportunities:</strong>
                          Gain international work experience that can enhance
                          your resume.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Travel Flexibility:</strong>
                          Explore Australia’s diverse landscapes and iconic
                          destinations.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Financial Independence:</strong>
                          Work to support yourself financially while enjoying
                          your holiday.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="priority"
                role="tabpanel"
                aria-labelledby="priority-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Benefits of the Work and Holiday Visa (Subclass 462)
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Work and Travel:</strong>
                          Allows you to work and travel in Australia for up to
                          12 months.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Multiple Entry:</strong>
                          You can enter and leave Australia as many times as you
                          want while the visa is valid.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Extendable:</strong>
                          Possibility to apply for a second and third Work and
                          Holiday visa if you complete specific types of work in
                          regional Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Study:</strong>
                          You can study for up to four months.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Supplement Income:</strong>
                          Opportunity to earn money to supplement your holiday
                          funds.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h3 className="subclass-189-pt-h1">Application Process</h3>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Ensure you meet all the eligibility criteria.
                </li>
                <li className="subclass-189-li">
                  repare the necessary documentation, including your passport,
                  proof of education, English proficiency, and financial
                  evidence.
                </li>
                <li className="subclass-189-li">
                  Apply online through the official Australian immigration
                  website. Create an ImmiAccount to lodge your application.
                </li>
                <li className="subclass-189-li">
                  Pay the required visa application charge.
                </li>
                <li className="subclass-189-li">
                  Complete any required health examinations and provide police
                  certificates if requested.
                </li>
                <li className="subclass-189-li">
                  After submitting your application and providing all necessary
                  documents, wait for the visa decision.
                </li>
              </ul>
            </p>

            {/* .......point test............. */}
            <h3 className="subclass-189-pt-h1">Points Test</h3>
            <p className="subclass-189-p subclass-189-pt-col">
              {/* <ul>
                    <li className="subclass-189-li">
                      Age: 25-32 years (30 points), 18-24 & 33-39 years (25 points),
                      etc.
                    </li>
                    <li className="subclass-189-li">
                      English Language Ability: Superior (20 points), Proficient (10
                      points).
                    </li>
                    <li className="subclass-189-li">
                      Skilled Employment: In Australia or overseas, up to 20 points
                      depending on years of experience.
                    </li>
                    <li className="subclass-189-li">
                      Educational Qualifications: Doctorate (20 points),
                      Bachelor’s/Master’s (15 points),Diploma or trade
                      qualification: 10 points
                    </li>
                    <li className="subclass-189-li">
                      Other Factors: Australian study requirement, specialist
                      education qualification, accredited community language, etc.
                    </li>
                  </ul> */}
              The Subclass 651 eVisitor Visa does not require a points test
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Related Visas</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="/working-holiday-visa-417"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (Subclass 417) Working Holiday visa
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="
/visitor-visa-600"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 600 Visitor visa
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/electronic-travel-authority-visa-601"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 601 Electronic Travel Authority
                    </a>
                  </li>

                  {/* <li className="subclass-189-li-a">
                        <a
                          href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/temporary-skill-shortage-482"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Temporary Skill Shortage visa Subclass 482
                        </a>
                      </li>
    
                      <li className="subclass-189-li-a">
                        <a
                          href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/skilled-employer-sponsored-regional-494"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Skilled Employer Sponsored Regional (Provisional) visa 494
                        </a>
                      </li> */}
                </ul>
              </p>
            </div>
          </Col>
        </Row>

        {/* ................visa qualify............ */}
        <Row lg={12} className="subclass-189-vq-row">
          <h3 className="mt-5">Do you think you qualify for this visa?</h3>
          <p>
            Contact us if you would like to know more about this visa or need
            immigration advice.
          </p>
          <div className="mb-5">
            <Button
              href="/contact
"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Contact
            </Button>
            <Button
              href="/appointment"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Book A Consultation
            </Button>
            <Button
              href="/initial-assessment-consultation"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Our Prices
            </Button>
          </div>
        </Row>

        {/* <Row>
                  <Col>
                    <div className="row m-5">
                      <h1 className="home-h1">Streams of the Subclass 600 Visa</h1>
                      <div className="col-lg-6 col-md-12">
                        <h5 className="subclass-189-pt-h1">Tourist Stream:</h5>
                        <p className="subclass-189-p subclass-189-pt-col">
                          <ul>
                            <li className="subclass-189-li">
                              For people visiting Australia for holidays, recreation, or
                              to visit family and friends.
                            </li>
                            <li className="subclass-189-li">
                              Can be applied for from inside or outside Australia.
                            </li>
                          </ul>
                        </p>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <h5 className="subclass-189-pt-h1">Business Visitor Stream:</h5>
                        <p className="subclass-189-p subclass-189-pt-col">
                          <ul>
                            <li className="subclass-189-li">
                              For business people making short business visits for
                              reasons such as attending a conference, negotiating, or
                              exploring business opportunities.
                            </li>
                            <li className="subclass-189-li">
                              Must be applied for from outside Australia.
                            </li>
                          </ul>
                        </p>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <h5 className="subclass-189-pt-h1">Sponsored Family Stream:</h5>
                        <p className="subclass-189-p subclass-189-pt-col">
                          <ul>
                            <li className="subclass-189-li">
                              For people visiting family members in Australia. A family
                              member in Australia must sponsor you and provide a bond.
                            </li>
                            <li className="subclass-189-li">
                              Must be applied for from outside Australia.
                            </li>
                          </ul>
                        </p>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <h5 className="subclass-189-pt-h1">
                          Approved Destination Status (ADS) Stream:
                        </h5>
                        <p className="subclass-189-p subclass-189-pt-col">
                          <ul>
                            <li className="subclass-189-li">
                              For people from the People’s Republic of China traveling
                              in an organized tour group.
                            </li>
                            <li className="subclass-189-li">
                              Must be applied for from outside Australia.
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row> */}

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h5 className="subclass-189-pt-h1">
              Conditions and Obligations for Subclass 462 Work and Holiday Visa
            </h5>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Work and Holiday Purpose:</strong>
                  Allows young adults from eligible countries to holiday and
                  work in Australia for up to one year.
                </li>
                <li className="subclass-189-li">
                  <strong>Age Requirement:</strong>
                  Applicants must be between 18 and 30 years old (inclusive) at
                  the time of application.
                </li>
                <li className="subclass-189-li">
                  <strong>Nationality: </strong>
                  Must hold a passport from an eligible country with a
                  reciprocal Work and Holiday arrangement with Australia.
                </li>
                <li className="subclass-189-li">
                  <strong>Education: </strong>
                  Must have completed at least two years of tertiary education
                  (or equivalent).
                </li>
                <li className="subclass-189-li">
                  <strong>Financial Capacity:</strong>
                  Must demonstrate sufficient funds to support themselves during
                  their stay in Australia.
                </li>
                <li className="subclass-189-li">
                  <strong>No Dependent Children:</strong>
                  Generally, applicants should not have dependent children
                  accompanying them to Australia.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">
              Express Your Interest in the Subclass 462 Work and Holiday Visa
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Adventure and Exploration: </strong>
                  Experience Australia's diverse landscapes, from stunning
                  beaches to rugged outback, fostering unforgettable adventures.
                </li>
                <li className="subclass-189-li">
                  <strong>Cultural Exchange:</strong>
                  Engage deeply with Australian culture, enhancing understanding
                  through local traditions, festivals, and community activities.
                </li>
                <li className="subclass-189-li">
                  <strong>Work Opportunities:</strong>
                  Gain valuable work experience through short-term employment,
                  supporting travel and living expenses while exploring
                  Australia.
                </li>
                <li className="subclass-189-li">
                  <strong>Networking and Connections:</strong>
                  Build international networks and friendships, fostering
                  lifelong connections with fellow travelers and locals alike.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">
              English Language Requirements
            </h3>
            <p>
              To meet the English language requirement, you must demonstrate
              competent English. This can be done through:
            </p>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>IELTS:</strong>A minimum score of 6 in each component
                  (Listening, Reading, Writing, and Speaking).
                </li>
                <li className="subclass-189-li">
                  <strong>TOEFL iBT:</strong>A minimum score of 12 in Listening,
                  13 in Reading, 21 in Writing, and 18 in Speaking.
                </li>
                <li className="subclass-189-li">
                  <strong>PTE Academic:</strong>A minimum score of 50 in each
                  component (Listening, Reading, Writing, and Speaking).
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Google Adds</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>

            <div className="subclass-189-rv-card-shadow mt-5">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Latest Post</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </>
      {/* faqs section */}
      <div className="row testimonial-background visa_question-acc">
        <div className="col-lg-12 cl-sm-12">
          <div className="testimonial-heading">
            {/* <h1 className="home-h1">Success Stories</h1> */}
            <h4 className="about-us-h4">
              Frequently Asked Questions (FAQs) about the Subclass 462 Work and
              Holiday Visa
            </h4>
          </div>

          <>
            <Row>
              <Col lg={12} className="">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      What is the Subclass 462 Work and Holiday Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      The Subclass 462 visa is a temporary visa allowing young
                      people from eligible countries to work and holiday in
                      Australia for up to one year.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Who is eligible for the Subclass 462 Work and Holiday
                      Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Eligibility criteria include being aged between 18 and 30
                      years (in some cases 18-35), having a passport from an
                      eligible country, meeting health and character
                      requirements, and having sufficient funds for the initial
                      stay.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      How long can I stay in Australia on a Subclass 462 visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      The visa allows you to stay in Australia for up to 12
                      months from the date of entry.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can I work on a Subclass 462 visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Processing times can vary, but most applications are
                      processed within a few days. It is recommended to apply
                      well in advance of your intended travel date.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Can I study on a Subclass 462 visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can study for up to four months on a Subclass 462
                      visa.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      How can I apply for the Subclass 462 Work and Holiday
                      Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      You can apply online through the Australian Government's
                      immigration portal. Ensure you meet all eligibility
                      requirements and provide necessary documentation.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Are there any specific health requirements for the
                      Subclass 462 visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you must meet health requirements. This typically
                      includes a medical examination and possibly a chest x-ray
                      depending on your circumstances.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      Can I extend my Subclass 462 visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      In some cases, you may be able to apply for a second
                      Subclass 462 visa if you have completed three months of
                      specified work in regional Australia during your first
                      visa.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      What countries are eligible for the Subclass 462 Work and
                      Holiday Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Eligible countries include the United States, Canada,
                      France, Germany, Hong Kong, Italy, Japan, South Korea,
                      Sweden, Taiwan, and more. Each country has specific quotas
                      and conditions.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      What is the purpose of the Subclass 462 Work and Holiday
                      Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      The visa promotes cultural exchange and closer ties
                      between Australia and participating countries, allowing
                      young people to experience life in Australia while
                      undertaking short-term work and travel activities.
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* <Accordion.Item eventKey="10">
                        <Accordion.Header>
                          What happens if I overstay my ETA Visa?
                        </Accordion.Header>
                        <Accordion.Body>
                          Overstaying your ETA Visa can result in penalties,
                          including a potential ban on re-entry to Australia and
                          other legal consequences.
                        </Accordion.Body>
                      </Accordion.Item> */}
                </Accordion>
              </Col>
            </Row>
          </>
        </div>
      </div>
    </Layout>
  );
};

export default WHVisa_462;
