import React from "react";
import Layout from "../../../../components/Layout/Layout";
import { Row, Col, Button } from "react-bootstrap";
import visa_864 from "../../../../images/visaImages/visa_864_1.png";

import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import "./CAPVisa_864.css";

const CAPVisa_864 = () => {
  return (
    <Layout
      title="Complete Guide to Contributory Parent Visa Subclass 173: Eligibility, Benefits, and Application Process"
      description="Learn all about the Contributory Parent Visa Subclass 173. Understand eligibility criteria, benefits, application process, and more to join your family in Australia."
      keywords="Contributory Parent Visa 173, Australian Parent Visa, Subclass 173 Visa, Parent Visa Eligibility, Australia Family Visa, Parent Visa Benefits, Parent Visa Application Process"
    >
      <>
        <Row className="m-5">
          <Col lg={6} md={6} sm={12}>
            <img
              src={visa_864}
              alt="skilledindependent-visa-189"
              className="img-fluid subclass-189-image"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <h2 className="subclass-189-h1">
              Contributory Aged Parent Visa
              <span className="subclass-189-h1-span">(Subclass 864)</span>
            </h2>

            <p className="subclass-189-p">
              Ready to reunite with your children in Australia?
              <a href="#" className="subclass-189-a">
                Begin your application today for the Contributory Aged Parent
                Visa (Subclass 864)!
              </a>
              This visa is ideal for elderly parents wanting to join their
              children who are Australian citizens, permanent residents, or
              eligible New Zealand citizens. Whether you look forward to family
              get-togethers, exploring Australia’s beautiful landscapes, or
              living in a dynamic and welcoming society, this visa helps you
              achieve your dreams. Make cherished memories with your loved ones
              and build a bright future together in Australia. Apply now and
              take the first step towards a joyful reunion!
            </p>
            <Button href="/contact" className="mt-3 contact-us-call-button">
              Call Now
            </Button>
          </Col>
        </Row>

        {/* //tab section */}
        <div className="row m-5 ">
          <div className="col-12">
            <div className="choose-us-title-view">
              <h2 className="choose-us-title">
                The Contributory Aged Parent Visa
                <b className="span-text">(Subclass 864)</b> OverView
              </h2>
            </div>
            <p>
              The Contributory Aged Parent Visa (Subclass 864) allows elderly
              parents of Australian citizens, permanent residents, or eligible
              New Zealand citizens to live permanently in Australia. This visa
              is designed to help families reunite, enabling parents to join
              their children and enjoy the vibrant Australian lifestyle.
            </p>
          </div>
          <div className="col-12 ">
            <ul className="nav nav-tabs custom-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active custom-nav-link"
                  id="success-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#success"
                  type="button"
                  role="tab"
                  aria-controls="success"
                  aria-selected="true"
                >
                  <i className="fas fa-clipboard-list fa-2x"></i>
                  <br />
                  Eligibility Criteria
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="permanent-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#permanent"
                  type="button"
                  role="tab"
                  aria-controls="permanent"
                  aria-selected="false"
                >
                  <i className="fas fa-check-circle fa-2x"></i>
                  <br />
                  Why Visa 864?
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="priority-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#priority"
                  type="button"
                  role="tab"
                  aria-controls="priority"
                  aria-selected="false"
                >
                  <i className="fas fa-gift fa-2x"></i>
                  <br />
                  Visa 864 benefits
                </button>
              </li>
            </ul>
            <div className="tab-content custom-tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active custom-tab-pane"
                id="success"
                role="tabpanel"
                aria-labelledby="success-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Eligibility Criteria for Contributory Aged Parent Visa
                      (Subclass 864)
                    </h3>
                    <p>
                      To be eligible for the Contributory Aged Parent Visa
                      (Subclass 864), applicants must meet the following
                      criteria:
                    </p>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Age Requirement:</strong>
                          The applicant must be of pensionable age as defined by
                          the Australian government.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Relationship:</strong>
                          Must be the parent of an Australian citizen, permanent
                          resident, or eligible New Zealand citizen.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Sponsorship: </strong> The applicant's child
                          must sponsor them.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Balance of Family Test: </strong>
                          At least half of the applicant's children must reside
                          in Australia, or more of their children must live in
                          Australia than in any other single country.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Assurance of Support: </strong>
                          The applicant must have an assurance of support from a
                          sponsor.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Health and Character: </strong>
                          The applicant must meet health and character
                          requirements.
                        </li>
                        <li className="subclass-189-li">
                          <strong>No Debts: </strong>
                          The applicant must have no outstanding debts to the
                          Australian government.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="permanent"
                role="tabpanel"
                aria-labelledby="permanent-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Why Choose the Contributory Aged Parent Visa (Subclass
                      864)?
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Permanent Residency: </strong>
                          Grants the right to live in Australia indefinitely.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Access to Healthcare:</strong>
                          Provides access to Medicare, Australia's public health
                          care system.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Work and Study:</strong>
                          Allows the visa holder to work and study in Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Pathway to Citizenship:</strong>
                          Offers a pathway to Australian citizenship after
                          meeting residency requirements.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Family Reunification: </strong>
                          Enables parents to live with their children and
                          grandchildren, fostering family unity.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Social Security:</strong>
                          Grants eligibility for certain social security
                          payments after a waiting period.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Travel:</strong>
                          Allows travel to and from Australia for five years
                          from the date the visa is granted.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="priority"
                role="tabpanel"
                aria-labelledby="priority-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Benefits of Contributory aged Parent Visa Subclass 864
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Live Permanently: </strong>
                          Reside in Australia indefinitely with your family.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Healthcare Access:</strong>
                          Enjoy the benefits of Medicare.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Work Rights:</strong>
                          Freedom to work and study in Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Pathway to Citizenship:</strong>
                          Opportunity to become an Australian citizen.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Family Reunion: </strong>
                          Reconnect with your children and grandchildren.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Social Security:</strong>
                          Eligibility for social security benefits after a
                          qualifying period.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Travel Flexibility:</strong>
                          Travel in and out of Australia for five years.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h3 className="subclass-189-pt-h1">Application Process</h3>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Ensure you meet all the eligibility criteria.
                </li>
                <li className="subclass-189-li">
                  Collect necessary documents, including proof of relationship,
                  sponsorship, and health assessments.
                </li>
                <li className="subclass-189-li">
                  Submit your visa application online or via paper.
                </li>
                <li className="subclass-189-li">
                  Pay the required visa application fee.
                </li>
                <li className="subclass-189-li">
                  Undergo health and character checks as part of the application
                  process.
                </li>
                <li className="subclass-189-li">
                  Wait for your visa application to be processed.
                </li>
                <li className="subclass-189-li">
                  Receive a decision on your visa application.
                </li>
              </ul>
            </p>

            {/* .......point test............. */}
            <h3 className="subclass-189-pt-h1">
              Points Test for Australia Visa 864
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              {/* <ul>
                                                                <li className="subclass-189-li">
                                                                  Age: 25-32 years (30 points), 18-24 & 33-39 years (25 points),
                                                                  etc.
                                                                </li>
                                                                <li className="subclass-189-li">
                                                                  English Language Ability: Superior (20 points), Proficient (10
                                                                  points).
                                                                </li>
                                                                <li className="subclass-189-li">
                                                                  Skilled Employment: In Australia or overseas, up to 20 points
                                                                  depending on years of experience.
                                                                </li>
                                                                <li className="subclass-189-li">
                                                                  Educational Qualifications: Doctorate (20 points),
                                                                  Bachelor’s/Master’s (15 points),Diploma or trade
                                                                  qualification: 10 points
                                                                </li>
                                                                <li className="subclass-189-li">
                                                                  Other Factors: Australian study requirement, specialist
                                                                  education qualification, accredited community language, etc.
                                                                </li>
                                                              </ul> */}
              There is no points test for the Contributory aged Parent Visa
              Subclass 864. Eligibility is based on meeting specific criteria
              related to family ties, health, and character.
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Related Visas</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="/parent-visa-103"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 103 Parent visa
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="/aged-parent-visa-804"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 804 Aged Parent visa
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/contributory-parent-visa-143"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (Subclass 143) Contributory Parent visa
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/contributory-aged-parent-visa-884"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 884 Contributory Aged Parent (Temporary) visa
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="/contributory-parent-visa-173"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 173 Contributory Parent (Temporary) visa
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>

        {/* ................visa qualify............ */}
        <Row lg={12} className="subclass-189-vq-row">
          <h3 className="mt-5">Do you think you qualify for this visa?</h3>
          <p>
            Contact us if you would like to know more about this visa or need
            immigration advice.
          </p>
          <div className="mb-5">
            <Button
              href="/contact"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Contact
            </Button>
            <Button
              href="/appointment"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Book A Consultation
            </Button>
            <Button
              href="/initial-assessment-consultation"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Our Prices
            </Button>
          </div>
        </Row>

        {/* <Row>
                              <Col>
                                <div className="row m-5">
                                  <h1 className="home-h1">
                                    Offshore Partner Visa (Subclass 309/100) Two Stage:
                                  </h1>
                                  <div className="col-lg-6 col-md-12">
                                    <h5 className="subclass-189-pt-h1">
                                      Partner visa (Temporary) Visa 309
                                    </h5>
                                    <p className="subclass-189-p subclass-189-pt-col">
                                      
                                      Must be outside of Australia to apply for this visa. Prior to
                                      applying, gather all required paperwork, including health,
                                      character, and sponsorship information. Use the online
                                      application process to apply for the visa when you are away
                                      from home.
                                    </p>
                                  </div>
                                  <div className="col-lg-6 col-md-12">
                                    <h5 className="subclass-189-pt-h1">
                                      Partner visa (Permanent) Visa 100
                                    </h5>
                                    <p className="subclass-189-p subclass-189-pt-col">
                                      
                                      There is no need to provide any more information if Subclass
                                      309 of the Visa comes right after Subclass 100. However, if a
                                      Visa Subclass 100 is not immediately issued, the authorities
                                      must be given the necessary information. Two years following
                                      application lodging, it will be assessed; submit the required
                                      paperwork one month beforehand. Submission of an application
                                      through an online system.
                                    </p>
                                  </div>
                                 
                                </div>
                              </Col>
                            </Row> */}

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h5 className="subclass-189-pt-h1">Conditions and Obligations</h5>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Assurance of Support:</strong>
                  Your sponsor must provide an assurance of support, ensuring
                  you do not become a burden on the Australian social security
                  system.
                </li>
                <li className="subclass-189-li">
                  <strong>Compliance with Laws: </strong>
                  You must comply with all Australian laws.
                </li>
                <li className="subclass-189-li">
                  <strong>Notify of Changes: </strong>
                  Inform the Department of Home Affairs of any changes in your
                  circumstances.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">Express Your Interest</h3>
            <p className="subclass-189-p subclass-189-pt-col">
              {/* <ul>
                                                    <li className="subclass-189-li">
                                                      <strong>Research: </strong>
                                                      Thoroughly research the visa requirements and benefits.
                                                    </li>
                                                    <li className="subclass-189-li">
                                                      <strong>Preparation:</strong>
                                                      Prepare all necessary documents and financial proofs.
                                                    </li>
                                                    <li className="subclass-189-li">
                                                      <strong>Application:</strong>
                                                      Complete and submit the online application.
                                                    </li>
                                                    <li className="subclass-189-li">
                                                      <strong>Follow-up:</strong>
                                                      Monitor your application status through ImmiAccount.
                                                    </li>
                                                    <li className="subclass-189-li">
                                                      <strong>Visa Grant:</strong>
                                                      Upon approval, make travel and accommodation arrangements.
                                                    </li>
                                                  </ul> */}
              If you are interested in applying for the Contributory Aged Parent
              Visa (Subclass 864), you can express your interest by contacting
              the Department of Home Affairs or consulting with a registered
              migration agent for assistance.
            </p>

            <h3 className="subclass-189-pt-h1">
              English Language Requirements
            </h3>
            <p>
              To meet the English language requirement, you must demonstrate
              competent English. This can be done through:
            </p>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>IELTS:</strong>A minimum score of 6 in each component
                  (Listening, Reading, Writing, and Speaking).
                </li>
                <li className="subclass-189-li">
                  <strong>TOEFL iBT:</strong>A minimum score of 12 in Listening,
                  13 in Reading, 21 in Writing, and 18 in Speaking.
                </li>
                <li className="subclass-189-li">
                  <strong>PTE Academic:</strong>A minimum score of 50 in each
                  component (Listening, Reading, Writing, and Speaking).
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Google Adds</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>

            <div className="subclass-189-rv-card-shadow mt-5">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Latest Post</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </>
      {/* faqs section */}
      <div className="row testimonial-background visa_question-acc">
        <div className="col-lg-12 cl-sm-12">
          <div className="testimonial-heading">
            {/* <h1 className="home-h1">Success Stories</h1> */}
            <h4 className="about-us-h4">
              Frequently Asked Questions (FAQs) about the Contributory Aged
              Parent Visa (Subclass 864)
            </h4>
          </div>

          <>
            <Row>
              <Col lg={12} className="">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      What is the Contributory Aged Parent Visa (Subclass 864)?
                    </Accordion.Header>
                    <Accordion.Body>
                      The Contributory Aged Parent Visa (Subclass 864) allows
                      elderly parents to live permanently in Australia with
                      their children who are Australian citizens, permanent
                      residents, or eligible New Zealand citizens.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How long does the application process take?
                    </Accordion.Header>
                    <Accordion.Body>
                      The processing time for this visa can vary, but it
                      generally takes several months to a few years, depending
                      on individual circumstances and application backlog.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Can I include my spouse in the visa application?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can include your spouse in the visa application
                      as a secondary applicant.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What are the financial requirements for this visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      You must have an assurance of support, which includes a
                      financial bond to cover any potential social security
                      costs.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Do I need health insurance for this visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      While it is not mandatory, having health insurance is
                      recommended to cover any medical expenses not covered by
                      Medicare.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Can I work in Australia on this visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, this visa allows you to work and study in Australia.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Is there an age limit for the Contributory Aged Parent
                      Visa (Subclass 864)?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, applicants must be of pensionable age as defined by
                      the Australian government.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      What is the Assurance of Support?
                    </Accordion.Header>
                    <Accordion.Body>
                      The Assurance of Support is a financial guarantee provided
                      by your sponsor to cover any social security costs for ten
                      years.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      Can I travel outside Australia on this visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can travel to and from Australia for five years
                      from the date the visa is granted.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      How do I apply for Australian citizenship after obtaining
                      this visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      You can apply for Australian citizenship after meeting the
                      residency requirements, which typically include living in
                      Australia for four years, with at least one year as a
                      permanent resident.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </>
        </div>
      </div>
    </Layout>
  );
};

export default CAPVisa_864;
