import React from "react";
import Layout from "../../../../components/Layout/Layout";
import { Row, Col, Button } from "react-bootstrap";
import aat from "../../../../images/visaImages/AAT_1.png";

import "./AAT.css";

const AAT = () => {
  return (
    <Layout
      title="Complete Guide to Contributory Parent Visa Subclass 173: Eligibility, Benefits, and Application Process"
      description="Learn all about the Contributory Parent Visa Subclass 173. Understand eligibility criteria, benefits, application process, and more to join your family in Australia."
      keywords="Contributory Parent Visa 173, Australian Parent Visa, Subclass 173 Visa, Parent Visa Eligibility, Australia Family Visa, Parent Visa Benefits, Parent Visa Application Process"
    >
      <>
        <Row className="m-5">
          <Col lg={6} md={6} sm={12}>
            <img
              src={aat}
              alt="skilledindependent-visa-189"
              className="img-fluid subclass-189-image"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <h2 className="subclass-189-h1">
              ADMINISTRATIVE APPEALS TRIBUNAL
              <span className="subclass-189-h1-span">(AAT)</span>
            </h2>

            <p className="subclass-189-p">
              Our experienced migration lawyers are here to guide you through
              your visa appeal options and help you meticulously prepare your
              case for the Administrative Appeals Tribunal. Let us provide the
              expertise and support you need to navigate this complex process
              and maximize your chances of a successful outcome.
            </p>
            <Button href="/appointment" className="mt-3 contact-us-call-button">
              Book your appointment
            </Button>
          </Col>
        </Row>

        {/* //tab section */}
        <div className="row m-5 ">
          <div className="col-12">
            <div className="choose-us-title-view">
              <h2 className="choose-us-title">
                Receiving a visa
                <b className="span-text">refusal or cancellation</b> notice is a
                stressful experience.
              </h2>
            </div>
            <p className="choose-us-text subclass-189-page-font">
              Receiving a visa refusal or cancellation notice can be an
              incredibly stressful experience, leaving you uncertain about your
              next steps. However, you don't have to navigate this challenging
              situation alone. Our dedicated migration lawyers are here to
              provide the guidance and support you need.
            </p>
            <p className="choose-us-text subclass-189-page-font">
              By booking an appointment with us, you can explore your appeal
              options and learn the best way to prepare your case for the
              Administrative Appeals Tribunal (AAT). The AAT reviews decisions
              made by the Department of Home Affairs, providing an opportunity
              to present new evidence and arguments to support your case.
            </p>
            <p className="choose-us-text subclass-189-page-font">
              We will work with you to understand the specifics of your
              situation, gather the necessary documentation, and develop a
              strong appeal strategy. Our goal is to help you turn a stressful
              experience into a positive outcome. Don’t wait—book an appointment
              today and take the first step towards resolving your visa issues.
            </p>
          </div>
          {/* <div className="col-12 ">
            <ul className="nav nav-tabs custom-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active custom-nav-link"
                  id="success-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#success"
                  type="button"
                  role="tab"
                  aria-controls="success"
                  aria-selected="true"
                >
                  <i className="fas fa-clipboard-list fa-2x"></i>
                  <br />
                  Eligibility Criteria
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="permanent-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#permanent"
                  type="button"
                  role="tab"
                  aria-controls="permanent"
                  aria-selected="false"
                >
                  <i className="fas fa-check-circle fa-2x"></i>
                  <br />
                  Why Visa 864?
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="priority-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#priority"
                  type="button"
                  role="tab"
                  aria-controls="priority"
                  aria-selected="false"
                >
                  <i className="fas fa-gift fa-2x"></i>
                  <br />
                  Visa 864 benefits
                </button>
              </li>
            </ul>
            <div className="tab-content custom-tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active custom-tab-pane"
                id="success"
                role="tabpanel"
                aria-labelledby="success-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-2">
                    <h3>
                      Eligibility Criteria for Contributory Aged Parent Visa
                      (Subclass 864)
                    </h3>
                    <p>
                      To be eligible for the Contributory Aged Parent Visa
                      (Subclass 864), applicants must meet the following
                      criteria:
                    </p>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Age Requirement:</strong>
                          The applicant must be of pensionable age as defined by
                          the Australian government.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Relationship:</strong>
                          Must be the parent of an Australian citizen, permanent
                          resident, or eligible New Zealand citizen.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Sponsorship: </strong> The applicant's child
                          must sponsor them.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Balance of Family Test: </strong>
                          At least half of the applicant's children must reside
                          in Australia, or more of their children must live in
                          Australia than in any other single country.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Assurance of Support: </strong>
                          The applicant must have an assurance of support from a
                          sponsor.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Health and Character: </strong>
                          The applicant must meet health and character
                          requirements.
                        </li>
                        <li className="subclass-189-li">
                          <strong>No Debts: </strong>
                          The applicant must have no outstanding debts to the
                          Australian government.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="permanent"
                role="tabpanel"
                aria-labelledby="permanent-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-2">
                    <h3>
                      Why Choose the Contributory Aged Parent Visa (Subclass
                      864)?
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Permanent Residency: </strong>
                          Grants the right to live in Australia indefinitely.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Access to Healthcare:</strong>
                          Provides access to Medicare, Australia's public health
                          care system.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Work and Study:</strong>
                          Allows the visa holder to work and study in Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Pathway to Citizenship:</strong>
                          Offers a pathway to Australian citizenship after
                          meeting residency requirements.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Family Reunification: </strong>
                          Enables parents to live with their children and
                          grandchildren, fostering family unity.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Social Security:</strong>
                          Grants eligibility for certain social security
                          payments after a waiting period.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Travel:</strong>
                          Allows travel to and from Australia for five years
                          from the date the visa is granted.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="priority"
                role="tabpanel"
                aria-labelledby="priority-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-2">
                    <h3>
                      Benefits of Contributory aged Parent Visa Subclass 864
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Live Permanently: </strong>
                          Reside in Australia indefinitely with your family.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Healthcare Access:</strong>
                          Enjoy the benefits of Medicare.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Work Rights:</strong>
                          Freedom to work and study in Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Pathway to Citizenship:</strong>
                          Opportunity to become an Australian citizen.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Family Reunion: </strong>
                          Reconnect with your children and grandchildren.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Social Security:</strong>
                          Eligibility for social security benefits after a
                          qualifying period.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Travel Flexibility:</strong>
                          Travel in and out of Australia for five years.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h3 className="subclass-189-pt-h1">
              What Sort of Visa Appeals Go to the AAT?
            </h3>

            <p className="subclass-189-p subclass-189-pt-col">
              <p>
                The Administrative Appeals Tribunal (AAT) handles a wide range
                of visa appeals through its two main divisions:
              </p>
              <ul>
                <li className="subclass-189-li">
                  The Migration and Refugee Division – This division is
                  responsible for reviewing most visa refusal decisions made by
                  the Department of Home Affairs
                </li>
                <li className="subclass-189-li">
                  The General Division – This division is responsible for
                  reviewing most character-related visa decisions or citizenship
                  refusals
                </li>
              </ul>
              <p>
                The AAT has the power to review matters that relate to decisions
                about bridging visas, family visas, nomination/sponsor
                applications, partner visas, permanent business visas, skilled
                visas, student visas, temporary work visas, some visitor visas,
                some humanitarian visa categories and citizenship.
              </p>
            </p>

            {/* .......point test............. */}
            <h3 className="subclass-189-pt-h1">
              What visa-related powers does the AAT have?
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <p>
                  As previously noted, the AAT possesses the authority to assess
                  various migration-related decisions independently.
                </p>
                <li className="subclass-189-li">
                  All documentation, evidence, forms and interview records held
                  by the Department of Home Affairs
                </li>
                <li className="subclass-189-li">
                  All submissions or evidence submitted by you to the AAT
                </li>
              </ul>
              The AAT also wields independent investigative powers. Should the
              AAT uncover any adverse information concerning you or any party
              involved in the visa refusal appeal, they will inform you in
              writing and solicit your comments on this information. It is
              advisable to seek legal counsel in such situations.
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          {/* <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Related Visas</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/parent-103"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 103 Parent visa
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/aged-parent-804"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 804 Aged Parent visa
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/contributory-parent-143"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (Subclass 143) Contributory Parent visa
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/contributory-aged-parent-temporary-884"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 884 Contributory Aged Parent (Temporary) visa
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/contributory-parent-temporary-173"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 173 Contributory Parent (Temporary) visa
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col> */}
        </Row>

        {/* ................visa qualify............ */}
        <Row lg={12} className="subclass-189-vq-row">
          <h3 className="mt-5">Do you think you qualify for this visa?</h3>
          <p>
            Contact us if you would like to know more about this visa or need
            immigration advice.
          </p>
          <div className="mb-5">
            <Button
              href="/contact"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Contact
            </Button>
            <Button
              href="/appointment"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Book A Consultation
            </Button>
            <Button
              href="/initial-assessment-consultation"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Our Prices
            </Button>
          </div>
        </Row>

        {/* <Row>
                                  <Col>
                                    <div className="row m-5">
                                      <h1 className="home-h1">
                                        Offshore Partner Visa (Subclass 309/100) Two Stage:
                                      </h1>
                                      <div className="col-lg-6 col-md-12">
                                        <h5 className="subclass-189-pt-h1">
                                          Partner visa (Temporary) Visa 309
                                        </h5>
                                        <p className="subclass-189-p subclass-189-pt-col">
                                          
                                          Must be outside of Australia to apply for this visa. Prior to
                                          applying, gather all required paperwork, including health,
                                          character, and sponsorship information. Use the online
                                          application process to apply for the visa when you are away
                                          from home.
                                        </p>
                                      </div>
                                      <div className="col-lg-6 col-md-12">
                                        <h5 className="subclass-189-pt-h1">
                                          Partner visa (Permanent) Visa 100
                                        </h5>
                                        <p className="subclass-189-p subclass-189-pt-col">
                                          
                                          There is no need to provide any more information if Subclass
                                          309 of the Visa comes right after Subclass 100. However, if a
                                          Visa Subclass 100 is not immediately issued, the authorities
                                          must be given the necessary information. Two years following
                                          application lodging, it will be assessed; submit the required
                                          paperwork one month beforehand. Submission of an application
                                          through an online system.
                                        </p>
                                      </div>
                                     
                                    </div>
                                  </Col>
                                </Row> */}

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h5 className="subclass-189-pt-h1">
              What is the process for an appeal in the AAT (Migration and
              Refugee Division)?
            </h5>

            <p className="subclass-189-p subclass-189-pt-col">
              <p>
                The general process of an AAT appeal in the Migration and
                Refugee Division is as follows:
              </p>
              <ul>
                <li className="subclass-189-li">
                  <strong>
                    Lodge your appeal application to the Administrative Appeals
                    Tribunal:
                  </strong>
                  It's crucial to lodge your application promptly, often within
                  just 2 days. The preferred method is online submission. When
                  appealing an immigration decision, ensure you upload essential
                  documents right away. Typically, this includes the refusal or
                  cancellation notification and a copy of the decision.
                  Additional documents can be submitted to the AAT later. If
                  applicable, pay the required fee. Some AAT appeals are free or
                  may qualify for a reduced fee.
                </li>
                <li className="subclass-189-li">
                  <strong>
                    {" "}
                    The AAT will then send you a confirmation of application
                    letter{" "}
                  </strong>
                </li>
                <li className="subclass-189-li">
                  <strong>You should start preparing your appeal case: </strong>
                  We suggest carefully reviewing the Department of Home Affairs'
                  decision and reasons for your application's rejection. Your
                  case strategy will depend on your specific circumstances and
                  often involves:
                  <ol>
                    Drafting a statement outlining your perspective on various
                    aspects of your case.
                  </ol>
                  <ol>
                    Gathering supporting evidence, like medical reports or
                    letters of support.
                  </ol>
                  <ol>
                    Identifying potential witnesses who can bolster different
                    aspects of your case.
                  </ol>
                  <ol>
                    Conducting legal research to substantiate why your case
                    should prevail at the AAT.
                  </ol>
                </li>
                <li className="subclass-189-li">
                  <strong>
                    The AAT will send you a hearing invitation letter.
                  </strong>
                  Before your hearing, the AAT may request additional
                  information or specific documents to assist in evaluating your
                  appeal. Even if not requested, it's advisable to provide
                  updated information and documents explaining why the
                  Department of Home Affairs' decision was incorrect.
                  Occasionally, the additional information provided may lead to
                  a positive decision without a hearing, although this is rare.
                  Upon receiving your hearing invitation, complete and return it
                  promptly. Confirm if you need an interpreter and provide
                  details of any witnesses you plan to bring.
                </li>
                <li className="subclass-189-li">
                  <strong>
                    You attend your AAT hearing with your witnesses and
                    migration lawyer (if you are represented).
                  </strong>
                  The AAT hearing is a crucial chance to present your case in
                  person and respond to any questions from the Tribunal member.
                  An interpreter will be provided if needed, and you can bring
                  witnesses to support your case. Inform the AAT in advance if
                  you plan to bring witnesses. Your migration lawyer can also
                  offer legal arguments during the hearing to bolster your case.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">
              What decisions can the AAT make?
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <p>
                  The Administrative Appeals Tribunal member will decide to
                  affirm, vary, set aside, or remit the decision made by the
                  Department of Home Affairs.
                </p>
                <li className="subclass-189-li">
                  If the AAT affirms the decision, it means the decision made by
                  the Department of Home Affairs is not changed. If this happens
                  you will need to immediately consider your next best steps
                  such as appealing the decision to court, applying for another
                  visa or leaving Australia
                </li>
                <li className="subclass-189-li">
                  If the AAT varies a decision, it means the decision made by
                  the Department of Home Affairs is changed or altered in some
                  way
                </li>
                <li className="subclass-189-li">
                  If the AAT sets aside a decision, it means the AAT agrees or
                  partially agrees the original decision made by the Department
                  of Home Affairs was wrong
                </li>
                <li className="subclass-189-li">
                  If the AAT remits a decision, it means it sends the matter
                  back to the Department of Home Affairs to make a new decision
                  in accordance with the AAT’s instructions or recommendations.
                  This means you won your appeal case! Generally if your matter
                  is remitted, you will need to wait to hear from the Department
                  of Home Affairs before your visa is granted.
                </li>
              </ul>
            </p>

            {/* <h3 className="subclass-189-pt-h1">
              English Language Requirements
            </h3>
            <p>
              To meet the English language requirement, you must demonstrate
              competent English. This can be done through:
            </p>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>IELTS:</strong>A minimum score of 6 in each component
                  (Listening, Reading, Writing, and Speaking).
                </li>
                <li className="subclass-189-li">
                  <strong>TOEFL iBT:</strong>A minimum score of 12 in Listening,
                  13 in Reading, 21 in Writing, and 18 in Speaking.
                </li>
                <li className="subclass-189-li">
                  <strong>PTE Academic:</strong>A minimum score of 50 in each
                  component (Listening, Reading, Writing, and Speaking).
                </li>
              </ul>
            </p> */}
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Google Adds</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>

            <div className="subclass-189-rv-card-shadow mt-5">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Latest Post</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </>
    </Layout>
  );
};

export default AAT;
