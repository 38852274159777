import React, { useEffect, useState } from "react";
import AdminLayout from "../../../../components/AdminLayout/AdminLayout";
import axios from "axios";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBBtn,
  MDBIcon,
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import SearchField from "../../../../components/SearchFeild";

const AboutUsForm = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchQueries();
  }, []);

  const fetchQueries = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}admin/view-contact-forms`
      );
      console.log("Full API response:", response.data.contactQueries);

      // Adjust based on actual response structure
      let queries;
      if (response.data && Array.isArray(response.data.contactQueries)) {
        queries = response.data.contactQueries;
      } else {
        throw new Error("Received data is not in expected array format");
      }

      setData(queries);
      setFilteredData(queries);
    } catch (err) {
      console.error("Error fetching queries:", err);
      setError("Failed to fetch queries. Please try again later.");
    }
  };

  const handleSearch = (searchTerm) => {
    if (!searchTerm) {
      setFilteredData(data);
    } else {
      const filtered = data.filter(
        (user) =>
          user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.phone_number.includes(searchTerm)
      );
      setFilteredData(filtered);
    }
    setCurrentPage(1);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
      pageNumbers.push(i);
    }
    return (
      <MDBPagination className="mb-4">
        {pageNumbers.map((number) => (
          <MDBPaginationItem key={number} active={currentPage === number}>
            <MDBPaginationLink onClick={() => setCurrentPage(number)}>
              {number}
            </MDBPaginationLink>
          </MDBPaginationItem>
        ))}
      </MDBPagination>
    );
  };

  const renderTableBody = () => {
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredData.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );

    if (currentRecords.length === 0) {
      return (
        <MDBTableBody className="align-center mb-0">
          <tr>
            <td colSpan={6} className="text-center mb-0">
              No Data Found
            </td>
          </tr>
        </MDBTableBody>
      );
    } else {
      return (
        <MDBTableBody>
          {currentRecords.map((item, index) => (
            <tr key={item._id}>
              <th scope="row">{index + 1 + indexOfFirstRecord}</th>
              <td>{item.name}</td>
              <td>{item.email}</td>
              <td>{item.phone_number}</td>
              <td>{item.visa_type}</td>
              <td>{item.message}</td>
            </tr>
          ))}
        </MDBTableBody>
      );
    }
  };

  return (
    <AdminLayout>
      <MDBContainer>
        <div>
          <MDBRow className="align-items-center mb-4">
            <MDBCol xs="12" md="6" className="text-start">
              <h2>List Of All Contact Queries</h2>
            </MDBCol>
            <MDBCol xs="12" md="6" className="text-end">
              <SearchField
                handleSearch={handleSearch}
                className="w-100 w-md-auto"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="12">
              <MDBTable responsive>
                <MDBTableHead dark>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Visa Type</th>
                    <th scope="col">Message</th>
                  </tr>
                </MDBTableHead>
                {renderTableBody()}
              </MDBTable>
              {renderPagination()}
            </MDBCol>
          </MDBRow>
        </div>
      </MDBContainer>
    </AdminLayout>
  );
};

export default AboutUsForm;
