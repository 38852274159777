import React from "react";
import Layout from "../../../../components/Layout/Layout";
import { Row, Col, Button } from "react-bootstrap";
import visa_300 from "../../../../images/visaImages/visa_300_1.png";

import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import "./PMVisa_300.css";

const PMVisa_300 = () => {
  return (
    <Layout
      title="Australia Prospective Marriage Visa (Subclass 300) - Eligibility, Benefits, and Application Process"
      description="Discover everything you need to know about the Australia Prospective Marriage Visa (Subclass 300). Learn about eligibility criteria, benefits, application process, conditions, and FAQs."
      keywords="Australia Prospective Marriage Visa, Subclass 300, prospective spouse visa, marriage visa benefits, visa application process, Australian partner visa conditions, immigration to Australia"
    >
      <>
        <Row className="m-5">
          <Col lg={6} md={6} sm={12}>
            <img
              src={visa_300}
              alt="skilledindependent-visa-189"
              className="img-fluid subclass-189-image"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <h2 className="subclass-189-h1">
              Prospective Marriage Visa
              <span className="subclass-189-h1-span">(Subclass 300)</span>
            </h2>

            <p className="subclass-189-p">
              Planning to join your partner in Australia?
              <a href="#" className="subclass-189-a">
                Apply online today for your Prospective Marriage Visa (Subclass
                300)!
              </a>
              This visa is for those planning to marry an Australian citizen,
              permanent resident, or eligible New Zealand citizen. Whether you
              aim to start a life together, work in Australia, or eventually
              become a permanent resident, this visa offers a pathway to achieve
              your goals. Enjoy the opportunity to be with your loved one and
              build a future together in Australia.
            </p>
            <Button href="/contact" className="mt-3 contact-us-call-button">
              Call Now
            </Button>
          </Col>
        </Row>

        {/* //tab section */}
        <div className="row m-5 ">
          <div className="col-12">
            <div className="choose-us-title-view">
              <h2 className="choose-us-title">
                Australia Prospective Marriage Visa
                <b className="span-text">(Subclass 300)</b> Overview
              </h2>
            </div>
            <p>
              The Australia Prospective Marriage Visa (Subclass 300) is designed
              for individuals who wish to come to Australia to marry their
              prospective spouse. This visa allows the visa holder to enter
              Australia and marry their intended spouse within nine months of
              visa grant. Upon marriage, the visa holder can apply for a Partner
              visa to remain permanently in Australia.
            </p>
          </div>
          <div className="col-12 ">
            <ul className="nav nav-tabs custom-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active custom-nav-link"
                  id="success-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#success"
                  type="button"
                  role="tab"
                  aria-controls="success"
                  aria-selected="true"
                >
                  <i className="fas fa-clipboard-list fa-2x"></i>
                  <br />
                  Eligibility Criteria
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="permanent-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#permanent"
                  type="button"
                  role="tab"
                  aria-controls="permanent"
                  aria-selected="false"
                >
                  <i className="fas fa-check-circle fa-2x"></i>
                  <br />
                  Why Visa 300?
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="priority-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#priority"
                  type="button"
                  role="tab"
                  aria-controls="priority"
                  aria-selected="false"
                >
                  <i className="fas fa-gift fa-2x"></i>
                  <br />
                  Visa 300 benefits
                </button>
              </li>
            </ul>
            <div className="tab-content custom-tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active custom-tab-pane"
                id="success"
                role="tabpanel"
                aria-labelledby="success-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Eligibility for Prospective Marriage Visa (Subclass 300)
                    </h3>
                    <p>
                      To be eligible for the Prospective Marriage Visa (Subclass
                      300), applicants must meet the following criteria:
                    </p>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Intention to Marry:</strong>
                          Have a genuine intention to marry an Australian
                          citizen, Australian permanent resident, or eligible
                          New Zealand citizen.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Relationship:</strong>
                          Be in a genuine and ongoing relationship with their
                          prospective spouse.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Age:</strong>
                          Be at least 18 years old.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Health Requirements:</strong>
                          Meet the health requirements specified by the
                          Australian government.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Character Requirements: </strong>
                          Meet the character requirements, including providing
                          police clearances.
                        </li>
                        <li className="subclass-189-li">
                          <strong>No Previous Visa Violations:</strong>
                          Have no history of visa cancellations or refusals.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Marriage Within 9 Months:</strong>
                          Intend to marry their prospective spouse within nine
                          months of visa grant.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="permanent"
                role="tabpanel"
                aria-labelledby="permanent-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Why Choose the Prospective Marriage Visa (Subclass 300)?
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Plan Your Wedding: </strong>
                          Opportunity to marry your prospective spouse in
                          Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Pathway to Partner Visa:</strong>
                          After marriage, eligible to apply for a Partner visa
                          (Subclass 820/801) to stay permanently in Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Work Rights:</strong>
                          Permission to work in Australia after entering with
                          the Prospective Marriage Visa.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Study Opportunities:</strong>
                          Access to educational opportunities in Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Healthcare Access:</strong>
                          Enrollment in Medicare, Australia’s public health care
                          system.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Travel Flexibility: </strong>
                          Freedom to travel in and out of Australia while the
                          visa is valid.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Family Inclusion: </strong>
                          Ability to include dependent children in the visa
                          application after marriage.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="priority"
                role="tabpanel"
                aria-labelledby="priority-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Benefits of the Prospective Marriage Visa (Subclass 300)
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Marriage Intent: </strong>
                          Allows applicants to enter Australia to marry their
                          prospective spouse.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Partner Visa Pathway:</strong>
                          Provides a pathway to apply for a Partner visa
                          (Subclass 820/801) after marriage.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Work and Study Rights: </strong>
                          Permission to work and study in Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Healthcare:</strong>
                          Access to Medicare benefits.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Travel Flexibility: </strong>
                          Multiple entries to and from Australia while the visa
                          is valid.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Family Unity:</strong>
                          Opportunity to include dependent children in the
                          future visa applications.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Long-Term Stay:</strong>
                          Potential for permanent residency and citizenship in
                          Australia.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h3 className="subclass-189-pt-h1">Application Process</h3>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Gather evidence of the relationship, including statements from
                  both partners, photos together, and communication records.
                </li>
                <li className="subclass-189-li">
                  Submit the visa application online through the Department of
                  Home Affairs website.
                </li>
                <li className="subclass-189-li">
                  Pay the required visa application fees.
                </li>
                <li className="subclass-189-li">
                  Undergo medical examinations and provide police clearances.
                </li>
                <li className="subclass-189-li">
                  If approved, receive the Prospective Marriage Visa to enter
                  Australia.
                </li>
                <li className="subclass-189-li">
                  Marry your prospective spouse in Australia within nine months
                  of visa grant.
                </li>
              </ul>
            </p>

            {/* .......point test............. */}
            <h3 className="subclass-189-pt-h1">
              Points Test for Australia Visa 500
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              {/* <ul>
                                            <li className="subclass-189-li">
                                              Age: 25-32 years (30 points), 18-24 & 33-39 years (25 points),
                                              etc.
                                            </li>
                                            <li className="subclass-189-li">
                                              English Language Ability: Superior (20 points), Proficient (10
                                              points).
                                            </li>
                                            <li className="subclass-189-li">
                                              Skilled Employment: In Australia or overseas, up to 20 points
                                              depending on years of experience.
                                            </li>
                                            <li className="subclass-189-li">
                                              Educational Qualifications: Doctorate (20 points),
                                              Bachelor’s/Master’s (15 points),Diploma or trade
                                              qualification: 10 points
                                            </li>
                                            <li className="subclass-189-li">
                                              Other Factors: Australian study requirement, specialist
                                              education qualification, accredited community language, etc.
                                            </li>
                                          </ul> */}
              The Prospective Marriage Visa (Subclass 300) does not require a
              points test. Instead, it focuses on the genuineness of the
              relationship and meeting health and character requirements.
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Related Visas</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="/onshore-partner-visa-subclass-820-801"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (Subclasses 820 and 801) Partner visa (apply in Australia)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="/offshore-partner-visa-309-100"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (Subclasses 309 and 100) Partner visa (apply overseas)
                    </a>
                  </li>

                  {/* <li className="subclass-189-li-a">
                            <a
                              href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/electronic-travel-authority-601"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Subclass 601 Electronic Travel Authority
                            </a>
                          </li> */}

                  {/* <li className="subclass-189-li-a">
                                                <a
                                                  href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/temporary-skill-shortage-482"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Temporary Skill Shortage visa Subclass 482
                                                </a>
                                              </li>
                            
                                              <li className="subclass-189-li-a">
                                                <a
                                                  href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/skilled-employer-sponsored-regional-494"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Skilled Employer Sponsored Regional (Provisional) visa 494
                                                </a>
                                              </li> */}
                </ul>
              </p>
            </div>
          </Col>
        </Row>

        {/* ................visa qualify............ */}
        <Row lg={12} className="subclass-189-vq-row">
          <h3 className="mt-5">Do you think you qualify for this visa?</h3>
          <p>
            Contact us if you would like to know more about this visa or need
            immigration advice.
          </p>
          <div className="mb-5">
            <Button
              href="/contact"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Contact
            </Button>
            <Button
              href="/appointment"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Book A Consultation
            </Button>
            <Button
              href="/initial-assessment-consultation"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Our Prices
            </Button>
          </div>
        </Row>

        {/* <Row>
          <Col>
            <div className="row m-5">
              <h1 className="home-h1">
                Offshore Partner Visa (Subclass 309/100) Two Stage:
              </h1>
              <div className="col-lg-6 col-md-12">
                <h5 className="subclass-189-pt-h1">
                  Partner visa (Temporary) Visa 309
                </h5>
                <p className="subclass-189-p subclass-189-pt-col">
                  
                  Must be outside of Australia to apply for this visa. Prior to
                  applying, gather all required paperwork, including health,
                  character, and sponsorship information. Use the online
                  application process to apply for the visa when you are away
                  from home.
                </p>
              </div>
              <div className="col-lg-6 col-md-12">
                <h5 className="subclass-189-pt-h1">
                  Partner visa (Permanent) Visa 100
                </h5>
                <p className="subclass-189-p subclass-189-pt-col">
                  
                  There is no need to provide any more information if Subclass
                  309 of the Visa comes right after Subclass 100. However, if a
                  Visa Subclass 100 is not immediately issued, the authorities
                  must be given the necessary information. Two years following
                  application lodging, it will be assessed; submit the required
                  paperwork one month beforehand. Submission of an application
                  through an online system.
                </p>
              </div>
             
            </div>
          </Col>
        </Row> */}

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h5 className="subclass-189-pt-h1">Conditions and Obligations</h5>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Marry Within 9 Months:</strong>
                  Must marry the prospective spouse within nine months of visa
                  grant.
                </li>
                <li className="subclass-189-li">
                  <strong>Inform Changes:</strong>
                  Inform the Department of Home Affairs of any changes in the
                  relationship status or personal details.
                </li>
                <li className="subclass-189-li">
                  <strong>Compliance: </strong>
                  Comply with Australian laws and visa conditions
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">Express Your Interest</h3>
            <p className="subclass-189-p subclass-189-pt-col">
              {/* <ul>
                                <li className="subclass-189-li">
                                  <strong>Research: </strong>
                                  Thoroughly research the visa requirements and benefits.
                                </li>
                                <li className="subclass-189-li">
                                  <strong>Preparation:</strong>
                                  Prepare all necessary documents and financial proofs.
                                </li>
                                <li className="subclass-189-li">
                                  <strong>Application:</strong>
                                  Complete and submit the online application.
                                </li>
                                <li className="subclass-189-li">
                                  <strong>Follow-up:</strong>
                                  Monitor your application status through ImmiAccount.
                                </li>
                                <li className="subclass-189-li">
                                  <strong>Visa Grant:</strong>
                                  Upon approval, make travel and accommodation arrangements.
                                </li>
                              </ul> */}
              To apply for the Prospective Marriage Visa (Subclass 300), express
              your interest early, gather all required documentation, and ensure
              that you meet all eligibility criteria before submitting your
              application.
            </p>

            <h3 className="subclass-189-pt-h1">
              English Language Requirements
            </h3>
            <p>
              To meet the English language requirement, you must demonstrate
              competent English. This can be done through:
            </p>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>IELTS:</strong>A minimum score of 6 in each component
                  (Listening, Reading, Writing, and Speaking).
                </li>
                <li className="subclass-189-li">
                  <strong>TOEFL iBT:</strong>A minimum score of 12 in Listening,
                  13 in Reading, 21 in Writing, and 18 in Speaking.
                </li>
                <li className="subclass-189-li">
                  <strong>PTE Academic:</strong>A minimum score of 50 in each
                  component (Listening, Reading, Writing, and Speaking).
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Google Adds</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>

            <div className="subclass-189-rv-card-shadow mt-5">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Latest Post</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </>
      {/* faqs section */}
      <div className="row testimonial-background visa_question-acc">
        <div className="col-lg-12 cl-sm-12">
          <div className="testimonial-heading">
            {/* <h1 className="home-h1">Success Stories</h1> */}
            <h4 className="about-us-h4">
              Frequently Asked Questions (FAQs) about the Prospective Marriage
              Visa (Subclass 300)
            </h4>
          </div>

          <>
            <Row>
              <Col lg={12} className="">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Can we marry outside Australia with this visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, the visa requires the marriage to take place in
                      Australia.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      What happens if we don't marry within 9 months?
                    </Accordion.Header>
                    <Accordion.Body>
                      If you do not marry within nine months, the visa may be
                      canceled.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Can my partner work in Australia with this visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, the Prospective Marriage Visa allows the holder to
                      work in Australia.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can I include my children in the visa application?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, this visa is for the applicant and does not include
                      dependent children. They can be added to future visa
                      applications after marriage.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How long does it take to process the Prospective Marriage
                      Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Processing times can vary, but it generally takes several
                      months from application to visa grant.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Do I need to be outside Australia to apply for this visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you must be outside Australia when applying and when
                      the visa is granted.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      What evidence do we need to prove our relationship?
                    </Accordion.Header>
                    <Accordion.Body>
                      Evidence can include photos together, communication
                      records, statements from both partners, and evidence of
                      future marriage plans.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      Can we apply for permanent residency after this visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      After marriage and fulfilling visa conditions, you can
                      apply for a Partner visa (Subclass 820/801) for permanent
                      residency.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      What are the health requirements for this visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      You must undergo a medical examination conducted by a
                      designated Australian panel doctor.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      Can we travel outside Australia with this visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, the visa allows multiple entries to and from
                      Australia while valid.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </>
        </div>
      </div>
    </Layout>
  );
};

export default PMVisa_300;
