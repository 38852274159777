import React from "react";
import Layout from "../../../../components/Layout/Layout";
import { Row, Col, Button } from "react-bootstrap";
import visa_884 from "../../../../images/visaImages/visa-884.png";

import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import "./CAPVisa_884.css";

const CAPVisa_884 = () => {
  return (
    <Layout
      title="Contributory Aged Parent Visa (Subclass 884) - Eligibility, Benefits, Application Process"
      description="Learn all about the Contributory Aged Parent Visa (Subclass 884). Find out about eligibility criteria, benefits, the application process, and essential FAQs."
      keywords="Contributory Aged Parent Visa 884, Subclass 884 visa, Australian aged parent visa, Contributory Parent visa benefits, Subclass 884 eligibility, Parent visa application process, FAQs for Subclass 884 visa"
    >
      <>
        <Row className="m-5">
          <Col lg={6} md={6} sm={12}>
            <img
              src={visa_884}
              alt="skilledindependent-visa-189"
              className="img-fluid subclass-189-image"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <h2 className="subclass-189-h1">
              Contributory Aged Parent Visa
              <span className="subclass-189-h1-span">(Subclass 884)</span>
            </h2>

            <p className="subclass-189-p">
              Ready to reunite with your children in Australia?
              <a href="#" className="subclass-189-a">
                Begin your application today for the Contributory Aged Parent
                Visa (Subclass 884)!
              </a>
              This visa is perfect for elderly parents wanting to join their
              children who are Australian citizens, permanent residents, or
              eligible New Zealand citizens. Whether you look forward to family
              get-togethers, exploring Australia’s beautiful landscapes, or
              living in a dynamic and welcoming society, this visa helps you
              achieve your dreams. Make cherished memories with your loved ones
              and build a bright future together in Australia. Apply now and
              take the first step towards a joyful reunion!
            </p>
            <Button href="/contact" className="mt-3 contact-us-call-button">
              Call Now
            </Button>
          </Col>
        </Row>

        {/* //tab section */}
        <div className="row m-5 ">
          <div className="col-12">
            <div className="choose-us-title-view">
              <h2 className="choose-us-title">
                The Contributory Aged Parent Visa
                <b className="span-text">(Subclass 884)</b> OverView
              </h2>
            </div>
            <p>
              The Contributory Aged Parent Visa (Subclass 884) allows older
              parents of Australian citizens, permanent residents, or eligible
              New Zealand citizens to live in Australia temporarily. This visa
              is a stepping stone towards permanent residency through the
              Contributory Aged Parent Visa (Subclass 864). It is ideal for
              elderly parents who wish to join their children and experience
              life in Australia with the potential to transition to a permanent
              stay.
            </p>
          </div>
          <div className="col-12 ">
            <ul className="nav nav-tabs custom-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active custom-nav-link"
                  id="success-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#success"
                  type="button"
                  role="tab"
                  aria-controls="success"
                  aria-selected="true"
                >
                  <i className="fas fa-clipboard-list fa-2x"></i>
                  <br />
                  Eligibility Criteria
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="permanent-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#permanent"
                  type="button"
                  role="tab"
                  aria-controls="permanent"
                  aria-selected="false"
                >
                  <i className="fas fa-check-circle fa-2x"></i>
                  <br />
                  Why Visa 884?
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="priority-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#priority"
                  type="button"
                  role="tab"
                  aria-controls="priority"
                  aria-selected="false"
                >
                  <i className="fas fa-gift fa-2x"></i>
                  <br />
                  Visa 884 benefits
                </button>
              </li>
            </ul>
            <div className="tab-content custom-tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active custom-tab-pane"
                id="success"
                role="tabpanel"
                aria-labelledby="success-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Eligibility Criteria for Contributory Aged Parent Visa
                      (Subclass 884)
                    </h3>
                    <p>
                      To be eligible for the Contributory Aged Parent Visa
                      (Subclass 884), applicants must meet the following
                      criteria:
                    </p>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Age:</strong>
                          Must be of pension age as defined by Australian law.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Relationship:</strong>
                          Must be the parent of an Australian citizen, permanent
                          resident, or eligible New Zealand citizen.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Sponsorship: </strong> Must be sponsored by
                          their child or another eligible sponsor.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Balance of Family Test: </strong>
                          At least half of the applicant’s children must live
                          permanently in Australia, or more of their children
                          live in Australia than in any other country.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Health and Character: </strong>
                          Must meet health and character requirements.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Assurance of Support: </strong>
                          Must have an Assurance of Support, which is a
                          financial bond provided by the sponsor to cover
                          potential welfare costs.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="permanent"
                role="tabpanel"
                aria-labelledby="permanent-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Why Choose the Contributory Aged Parent Visa (Subclass
                      884)?
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          Want to live with their children in Australia
                          temporarily while preparing for permanent residency.
                        </li>
                        <li className="subclass-189-li">
                          Seek a pathway to eventually obtain a permanent
                          residency visa.
                        </li>
                        <li className="subclass-189-li">
                          Desire to enjoy the high quality of life in Australia.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="priority"
                role="tabpanel"
                aria-labelledby="priority-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>
                      Benefits of Contributory aged Parent Visa Subclass 884
                    </h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Temporary Residency: </strong>
                          Live in Australia for up to two years.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Pathway to Permanent Residency:</strong>
                          Apply for the Contributory Aged Parent Visa (Subclass
                          864) while holding this visa.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Family Reunion:</strong>
                          Reunite and live with your children and grandchildren
                          in Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Access to Healthcare:</strong>
                          Access to Medicare, Australia’s public health care
                          system.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Multiple Entry: </strong>
                          Travel to and from Australia as many times as you want
                          while the visa is valid.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Explore Australia:</strong>
                          Experience Australia’s diverse culture, landscapes,
                          and lifestyle.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h3 className="subclass-189-pt-h1">Application Process</h3>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Gather required documents such as identity, relationship,
                  financial, and health documents.
                </li>
                <li className="subclass-189-li">
                  Apply online or by paper through the Department of Home
                  Affairs.
                </li>
                <li className="subclass-189-li">
                  Pay the visa application charge.
                </li>
                <li className="subclass-189-li">
                  Complete required health examinations and provide police
                  certificates.
                </li>
                <li className="subclass-189-li">
                  Processing times vary; check the Department of Home Affairs
                  website for current times.
                </li>
                <li className="subclass-189-li">
                  Receive a decision from the Department of Home Affairs.
                </li>
                <li className="subclass-189-li">
                  If granted, make arrangements to move to Australia.
                </li>
              </ul>
            </p>

            {/* .......point test............. */}
            <h3 className="subclass-189-pt-h1">
              Points Test for Australia Visa 884
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              {/* <ul>
                                                                    <li className="subclass-189-li">
                                                                      Age: 25-32 years (30 points), 18-24 & 33-39 years (25 points),
                                                                      etc.
                                                                    </li>
                                                                    <li className="subclass-189-li">
                                                                      English Language Ability: Superior (20 points), Proficient (10
                                                                      points).
                                                                    </li>
                                                                    <li className="subclass-189-li">
                                                                      Skilled Employment: In Australia or overseas, up to 20 points
                                                                      depending on years of experience.
                                                                    </li>
                                                                    <li className="subclass-189-li">
                                                                      Educational Qualifications: Doctorate (20 points),
                                                                      Bachelor’s/Master’s (15 points),Diploma or trade
                                                                      qualification: 10 points
                                                                    </li>
                                                                    <li className="subclass-189-li">
                                                                      Other Factors: Australian study requirement, specialist
                                                                      education qualification, accredited community language, etc.
                                                                    </li>
                                                                  </ul> */}
              There is no points test for the Contributory aged Parent Visa
              Subclass 884. Eligibility is based on meeting specific criteria
              related to family ties, health, and character.
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Related Visas</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="/parent-visa-103
"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 103 Parent visa
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="/aged-parent-visa-804
"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 804 Aged Parent visa
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/contributory-parent-visa-143"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (Subclass 143) Contributory Parent visa
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/contributory-aged-parent-visa-864"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (Subclass 864) Contributory Aged Parent visa
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="/contributory-parent-visa-173"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 173 Contributory Parent (Temporary) visa
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>

        {/* ................visa qualify............ */}
        <Row lg={12} className="subclass-189-vq-row">
          <h3 className="mt-5">Do you think you qualify for this visa?</h3>
          <p>
            Contact us if you would like to know more about this visa or need
            immigration advice.
          </p>
          <div className="mb-5">
            <Button
              href="/contact"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Contact
            </Button>
            <Button
              href="/appointment"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Book A Consultation
            </Button>
            <Button
              href="/initial-assessment-consultation"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Our Prices
            </Button>
          </div>
        </Row>

        {/* <Row>
                                  <Col>
                                    <div className="row m-5">
                                      <h1 className="home-h1">
                                        Offshore Partner Visa (Subclass 309/100) Two Stage:
                                      </h1>
                                      <div className="col-lg-6 col-md-12">
                                        <h5 className="subclass-189-pt-h1">
                                          Partner visa (Temporary) Visa 309
                                        </h5>
                                        <p className="subclass-189-p subclass-189-pt-col">
                                          
                                          Must be outside of Australia to apply for this visa. Prior to
                                          applying, gather all required paperwork, including health,
                                          character, and sponsorship information. Use the online
                                          application process to apply for the visa when you are away
                                          from home.
                                        </p>
                                      </div>
                                      <div className="col-lg-6 col-md-12">
                                        <h5 className="subclass-189-pt-h1">
                                          Partner visa (Permanent) Visa 100
                                        </h5>
                                        <p className="subclass-189-p subclass-189-pt-col">
                                          
                                          There is no need to provide any more information if Subclass
                                          309 of the Visa comes right after Subclass 100. However, if a
                                          Visa Subclass 100 is not immediately issued, the authorities
                                          must be given the necessary information. Two years following
                                          application lodging, it will be assessed; submit the required
                                          paperwork one month beforehand. Submission of an application
                                          through an online system.
                                        </p>
                                      </div>
                                     
                                    </div>
                                  </Col>
                                </Row> */}

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h5 className="subclass-189-pt-h1">Conditions and Obligations</h5>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Follow Australian Laws: </strong>
                  Comply with all Australian laws.
                </li>
                <li className="subclass-189-li">
                  <strong>Sponsorship Obligations: </strong>
                  The sponsor must support the applicant for the first two
                  years.
                </li>
                <li className="subclass-189-li">
                  <strong>Health Insurance </strong>
                  Maintain adequate health insurance.
                </li>
                <li className="subclass-189-li">
                  <strong>No Work Restrictions: </strong>
                  No specific work restrictions apply.
                </li>
                <li className="subclass-189-li">
                  <strong>Notify Changes: </strong>
                  Inform the Department of any changes in circumstances.
                </li>
                <li className="subclass-189-li">
                  <strong>Use the Visa: </strong>
                  Enter Australia within the specified time frame.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">Express Your Interest</h3>
            <p className="subclass-189-p subclass-189-pt-col">
              {/* <ul>
                                                        <li className="subclass-189-li">
                                                          <strong>Research: </strong>
                                                          Thoroughly research the visa requirements and benefits.
                                                        </li>
                                                        <li className="subclass-189-li">
                                                          <strong>Preparation:</strong>
                                                          Prepare all necessary documents and financial proofs.
                                                        </li>
                                                        <li className="subclass-189-li">
                                                          <strong>Application:</strong>
                                                          Complete and submit the online application.
                                                        </li>
                                                        <li className="subclass-189-li">
                                                          <strong>Follow-up:</strong>
                                                          Monitor your application status through ImmiAccount.
                                                        </li>
                                                        <li className="subclass-189-li">
                                                          <strong>Visa Grant:</strong>
                                                          Upon approval, make travel and accommodation arrangements.
                                                        </li>
                                                      </ul> */}
              Consult with Migration Agents , Seek advice from registered
              migration agents. Attend Information Sessions, Participate in
              sessions provided by the Department of Home Affairs. Monitor Visa
              Updates, Stay updated on visa changes and processing times.
            </p>

            <h3 className="subclass-189-pt-h1">
              English Language Requirements
            </h3>
            <p>
              To meet the English language requirement, you must demonstrate
              competent English. This can be done through:
            </p>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>IELTS:</strong>A minimum score of 6 in each component
                  (Listening, Reading, Writing, and Speaking).
                </li>
                <li className="subclass-189-li">
                  <strong>TOEFL iBT:</strong>A minimum score of 12 in Listening,
                  13 in Reading, 21 in Writing, and 18 in Speaking.
                </li>
                <li className="subclass-189-li">
                  <strong>PTE Academic:</strong>A minimum score of 50 in each
                  component (Listening, Reading, Writing, and Speaking).
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Google Adds</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>

            <div className="subclass-189-rv-card-shadow mt-5">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Latest Post</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </>
      {/* faqs section */}
      <div className="row testimonial-background visa_question-acc">
        <div className="col-lg-12 cl-sm-12">
          <div className="testimonial-heading">
            {/* <h1 className="home-h1">Success Stories</h1> */}
            <h4 className="about-us-h4">
              Frequently Asked Questions (FAQs) about the Contributory Aged
              Parent Visa (Subclass 884)
            </h4>
          </div>

          <>
            <Row>
              <Col lg={12} className="">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      What is the processing time for the Contributory Aged
                      Parent Visa (Subclass 884)?
                    </Accordion.Header>
                    <Accordion.Body>
                      Processing times can vary; it is recommended to check the
                      latest updates on the Department of Home Affairs website.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Can I work in Australia on the Contributory Aged Parent
                      Visa (Subclass 884)?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, this visa allows full work rights during the visa
                      period.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      What is the Assurance of Support?
                    </Accordion.Header>
                    <Accordion.Body>
                      It is a financial bond provided by the sponsor to cover
                      potential welfare costs.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can I apply for permanent residency on this visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can apply for the Contributory Aged Parent Visa
                      (Subclass 864) to gain permanent residency.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      What documents are required for the application?
                    </Accordion.Header>
                    <Accordion.Body>
                      Documents include identity, relationship proof, health and
                      character documents, and financial records.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Is there a points test for the Contributory Aged Parent
                      Visa (Subclass 884)?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, this visa does not require a points test.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      What is the Balance of Family Test?
                    </Accordion.Header>
                    <Accordion.Body>
                      At least half of your children must live in Australia, or
                      more children must live in Australia than in any other
                      country.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      How much is the visa application fee?
                    </Accordion.Header>
                    <Accordion.Body>
                      The fee varies; check the Department of Home Affairs
                      website for the latest charges.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      Can my sponsor be someone other than my child?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, other eligible sponsors include the child's spouse or
                      de facto partner.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      How do I apply for the Contributory Aged Parent Visa
                      (Subclass 884)?
                    </Accordion.Header>
                    <Accordion.Body>
                      Apply online or by paper through the Department of Home
                      Affairs. or on real migration website.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </>
        </div>
      </div>
    </Layout>
  );
};

export default CAPVisa_884;
