import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./Register.css";
import Layout from "../../../components/Layout/Layout";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateField = (value, setter) => {
    if (!value.trim()) {
      setter(true);
    } else {
      setter(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_API}api/auth/register`,
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone_number: phoneNumber,
          password: password,
        }
      );
      if (resp.data.success) {
        toast.success(resp.data.message, {
          onClose: () => navigate("/login"), // Navigate when the toast closes
          autoClose: 3000, // Adjust timing as needed
        });
      } else {
        toast.error(resp.data.message);
      }
    } catch (err) {
      console.log(err);
      toast.error("something went wrong");
    }
  };

  return (
    <Layout
      title={
        "Register - Start Your Visa Journey with Real Migration and Education"
      }
      description={
        "Create your account with Real Migration and Education to begin your application for Australian visas. Register now to access expert guidance and comprehensive support for all visa types."
      }
      keywords={
        "Register for visa services, Australian visa registration, Migration services signup, Education and visa experts, Visa application support, Create visa account, Student visa assistance, Partner visa help, Skilled migration registration, Family visa consultancy"
      }
    >
      <div className="authContainer">
        <div className="form-container sign-up">
          <form onSubmit={handleSubmit}>
            <h1>Create Account</h1>
            <div className="social-icons">
              <NavLink to="/" className="auth-icon">
                <i className="fa-brands fa-google-plus-g" />
              </NavLink>
              <NavLink to="/" className="auth-icon">
                <i className="fa-brands fa-facebook-f" />
              </NavLink>
              <NavLink to="/" className="auth-icon">
                <i className="fa-brands fa-github" />
              </NavLink>
              <NavLink to="/" className="auth-icon">
                <i className="fa-brands fa-linkedin-in" />
              </NavLink>
            </div>
            <span>or use your email for registration</span>
            <div className="name-fields">
              <input
                type="text"
                placeholder="First Name *"
                className={firstNameError ? "error" : ""}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                onBlur={() => validateField(firstName, setFirstNameError)}
                required
              />
              <input
                type="text"
                placeholder="Last Name *"
                className={lastNameError ? "error" : ""}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                onBlur={() => validateField(lastName, setLastNameError)}
                required
              />
            </div>
            <input
              type="email"
              placeholder="Email *"
              className={emailError ? "error" : ""}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={() => validateField(email, setEmailError)}
              required
            />

            <PhoneInput
              placeholder="Enter phone number *"
              value={phoneNumber}
              onChange={setPhoneNumber}
              onBlur={() => validateField(phoneNumber, setPhoneError)}
              className={phoneError ? "error" : ""}
              defaultCountry="US"
              international
              required
            />
            <div className="password-field">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password *"
                className={passwordError ? "error" : ""}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={() => validateField(password, setPasswordError)}
                required
              />
              <i
                className={`bi ${showPassword ? "bi-eye-slash" : "bi-eye"}`}
                onClick={togglePasswordVisibility}
              ></i>
            </div>

            <button className="signin-button">Sign Up</button>
          </form>
          <p className="login-link">
            Already have an account? <NavLink to="/login">Log in</NavLink>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
