import React, { useEffect, useState } from "react";
import AdminLayout from "../../../../components/AdminLayout/AdminLayout";
import axios from "axios";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import SearchField from "../../../../components/SearchFeild";
import { Modal, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const ViewBlogs = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [blogIdToDelete, setBlogIdToDelete] = useState(null);
  const recordsPerPage = 10; // Define recordsPerPage

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}admin/view-blogs`
      );
      setData(response.data.blogs || []);
      setFilteredData(response.data.blogs || []);
    } catch (err) {
      console.error("Error fetching blogs:", err);
      setError("Failed to fetch blogs. Please try again later.");
    }
  };

  const handleSearch = (searchTerm) => {
    if (!searchTerm) {
      setFilteredData(data);
    } else {
      const filtered = data.filter((blog) =>
        blog.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
    setCurrentPage(1);
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API}admin/delete-blog/${blogIdToDelete}`
      );
      if (response.status === 200) {
        fetchBlogs(); // Refetch blogs to update the list
        setShowDeleteModal(false);
      }
    } catch (err) {
      console.error("Error deleting blog:", err);
      setError("Failed to delete blog. Please try again.");
    }
  };

  const confirmDelete = (blogId) => {
    setBlogIdToDelete(blogId);
    setShowDeleteModal(true);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
      pageNumbers.push(i);
    }
    return (
      <MDBPagination className="mb-4">
        {pageNumbers.map((number) => (
          <MDBPaginationItem key={number} active={currentPage === number}>
            <MDBPaginationLink onClick={() => setCurrentPage(number)}>
              {number}
            </MDBPaginationLink>
          </MDBPaginationItem>
        ))}
      </MDBPagination>
    );
  };

  const renderTableBody = () => {
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredData.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );

    if (currentRecords.length === 0) {
      return (
        <MDBTableBody>
          <tr>
            <td colSpan="4" className="text-center">
              No blogs found
            </td>
          </tr>
        </MDBTableBody>
      );
    }

    return (
      <MDBTableBody>
        {currentRecords.map((blog, index) => (
          <tr key={blog._id}>
            <th scope="row">{index + 1 + indexOfFirstRecord}</th>
            <td>{blog.title}</td>
            <td>{new Date(blog.createdAt).toLocaleDateString()}</td>
            <td>
              <NavLink to={`/dashboard/admin/ViewBlog/${blog._id}`}>
                <Button variant="info" size="sm" className="me-2">
                  <i className="fas fa-eye"></i> View
                </Button>
              </NavLink>
              <Button
                variant="danger"
                size="sm"
                onClick={() => confirmDelete(blog._id)}
              >
                <i className="fas fa-trash"></i> Delete
              </Button>
            </td>
          </tr>
        ))}
      </MDBTableBody>
    );
  };

  return (
    <AdminLayout>
      <MDBContainer>
        <div>
          <MDBRow className="align-items-center mb-4">
            <MDBCol xs="12" md="6" className="text-start">
              <h2>List Of All Uploaded Blogs</h2>
            </MDBCol>
            <MDBCol xs="12" md="6" className="text-end">
              <SearchField
                handleSearch={handleSearch}
                className="w-100 w-md-auto"
              />
            </MDBCol>
          </MDBRow>
          {error && (
            <MDBRow>
              <MDBCol>
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              </MDBCol>
            </MDBRow>
          )}
          <MDBRow>
            <MDBCol size="12">
              <MDBTable responsive>
                <MDBTableHead dark>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Title</th>
                    <th scope="col">Time</th>
                    <th scope="col">Action</th>
                  </tr>
                </MDBTableHead>
                {renderTableBody()}
              </MDBTable>
              {renderPagination()}
            </MDBCol>
          </MDBRow>
        </div>
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this blog?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </MDBContainer>
    </AdminLayout>
  );
};

export default ViewBlogs;
