import React, { useEffect, useState } from "react";
import AdminLayout from "../../../../components/AdminLayout/AdminLayout";
import axios from "axios";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBBtn,
  MDBIcon,
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import SearchFeild from "../../../../components/SearchFeild";
import { Modal, Button } from "react-bootstrap";

const ShowUser = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);
  const [error, setError] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}admin/get-users`
      );
      setData(response.data.users); // Ensure you're accessing the correct property
      setFilteredData(response.data.users);
    } catch (err) {
      console.error("Error fetching users:", err);
      setError("Failed to fetch users. Please try again later.");
    }
  };

  const handleSearch = (searchTerm) => {
    if (searchTerm) {
      const filtered = data.filter(
        (user) =>
          user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.phone_number.includes(searchTerm)
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
    setCurrentPage(1); // Reset to the first page on new search
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API}admin/delete-user/${userIdToDelete}`
      );
      setData(data.filter((user) => user._id !== userIdToDelete));
      setFilteredData(
        filteredData.filter((user) => user._id !== userIdToDelete)
      );
      setShowDeleteModal(false);
    } catch (err) {
      console.error("Error deleting user:", err);
      setError(
        `Failed to delete user: ${
          err.response ? err.response.data : "Unknown error"
        }`
      );
      setShowDeleteModal(false);
    }
  };

  const confirmDelete = (userId) => {
    setUserIdToDelete(userId);
    setShowDeleteModal(true);
  };

  // Logic for displaying current records
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredData.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <AdminLayout>
      <MDBContainer>
        <div>
          <MDBRow className="align-items-center mb-4">
            <MDBCol xs="12" md="6" className="text-start">
              <h2>List Of All Registered Users</h2>
            </MDBCol>
            <MDBCol xs="12" md="6" className="text-end">
              <SearchFeild
                handleSearch={handleSearch}
                className="w-100 w-md-auto"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="12">
              <MDBTable responsive>
                <MDBTableHead dark>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Action</th>
                  </tr>
                </MDBTableHead>
                {currentRecords.length === 0 ? (
                  <MDBTableBody className="align-center mb-0">
                    <tr>
                      <td colSpan={6} className="text-center mb-0">
                        No Data Found
                      </td>
                    </tr>
                  </MDBTableBody>
                ) : (
                  currentRecords.map((item, index) => (
                    <MDBTableBody key={item._id}>
                      <tr>
                        <th scope="row">{index + 1 + indexOfFirstRecord}</th>
                        <td>{item.first_name}</td>
                        <td>{item.last_name}</td>
                        <td>{item.email}</td>
                        <td>{item.phone_number}</td>
                        <td>
                          <MDBBtn
                            color="danger"
                            size="sm"
                            onClick={() => confirmDelete(item._id)}
                          >
                            <MDBIcon fas icon="trash" /> Delete
                          </MDBBtn>
                          {/* <MDBBtn
                            color="success"
                            size="sm"
                            onClick={() => confirmAddAdmin(item._id)}
                            className="ms-2"
                          >
                            <MDBIcon fas icon="user-plus" /> Add Admin
                          </MDBBtn> */}
                        </td>
                      </tr>
                    </MDBTableBody>
                  ))
                )}
              </MDBTable>
              <MDBPagination className="mb-4">
                {pageNumbers.map((number) => (
                  <MDBPaginationItem
                    key={number}
                    active={currentPage === number}
                  >
                    <MDBPaginationLink onClick={() => paginate(number)}>
                      {number}
                    </MDBPaginationLink>
                  </MDBPaginationItem>
                ))}
              </MDBPagination>
            </MDBCol>
          </MDBRow>
        </div>

        {/* Delete Confirmation Modal */}
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Add Admin Confirmation Modal */}
        {/* <Modal
          show={showAddAdminModal}
          onHide={() => setShowAddAdminModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Add Admin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to make this user an admin?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowAddAdminModal(false)}
            >
              Cancel
            </Button>
            <Button variant="success" onClick={handleAddAdmin}>
              Add Admin
            </Button>
          </Modal.Footer>
        </Modal> */}
      </MDBContainer>
    </AdminLayout>
  );
};

export default ShowUser;
