import React, { useState, useContext } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { AgentContext } from '../AgentContext';
import AdminLayout from '../../../../components/AdminLayout/AdminLayout';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

export default function AddAgent() {
  const { setAgentData } = useContext(AgentContext);
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [formData, setFormData] = useState({
    agentName: "",
    email: "",
    age: "",
    experience: "",
    MARN: "",
    languagesSpoken: "",
    image: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setFormData({
        ...formData,
        image: files[0]
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }
    axios.post(`${process.env.REACT_APP_API}agent/createAgent`, data)
      .then(response => {
        console.log("Response from server:", response);
        if (response.data.agent) {
          const { _id, agentName } = response.data.agent; // Ensure this contains _id
          setSuccessMessage('Agent added successfully!');
          setAgentData({ _id, agentName }); // Save agent ID and name
          navigate('/dashboard/admin/addservice');
        } else {
          console.error("Agent data is missing in the response.");
        }
      })
      .catch(error => {
        console.error("There was an error submitting the form!", error);
      });
  };

  return (
    <AdminLayout>
      <Container>
        <Row>
          <Col md={6}>
            {successMessage && (
              <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
                {successMessage}
              </Alert>
            )}
            <Form className="p-4 border rounded" onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Enter Full Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  name="agentName"
                  value={formData.agentName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Enter Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicAge">
                <Form.Label>Enter Age</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Age"
                  name="age"
                  value={formData.age}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicExperience">
                <Form.Label>Enter Experience</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Experience"
                  name="experience"
                  value={formData.experience}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicMARN">
                <Form.Label>Enter MARN</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="MARN"
                  name="MARN"
                  value={formData.MARN}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicLanguage">
                <Form.Label>Enter Languages</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Languages"
                  name="languagesSpoken"
                  value={formData.languagesSpoken}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Add Image</Form.Label>
                <Form.Control
                  type="file"
                  name="image"
                  onChange={handleChange}
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Add Agent
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </AdminLayout>
  );
}
