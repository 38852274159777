import React from "react";

const SearchFeild = ({ handleSearch, className }) => {
  return (
    <form
      style={{ backgroundColor: "#fff" }}
      className={`d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search ${className}`}
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="input-group">
        <input
          type="text"
          className="form-control bg-light border-0 small"
          placeholder="Search for..."
          aria-label="Search"
          aria-describedby="basic-addon2"
          onChange={(e) => handleSearch(e.target.value)}
        />
        <div className="input-group-append">
          <button className="btn btn-primary" type="button">
            <i className="fas fa-search fa-sm"></i>
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchFeild;
