import React from "react";
import Layout from "../../../../components/Layout/Layout";
import { Row, Col, Button } from "react-bootstrap";
import visa_600 from "../../../../images/visaImages/visa_600_1.png";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import "./VisitorVisa_600.css";

const VisitorVisa_600 = () => {
  return (
    <Layout
      title="Complete Guide to the Subclass 600 Visitor Visa - Eligibility, Benefits, and Application Process"
      description="Learn about the Subclass 600 Visitor Visa, including eligibility criteria, benefits, and the step-by-step application process. Discover how this visa allows you to visit Australia for tourism, business, or to visit family and friends."
      keywords="Subclass 600 visa, Visitor visa Australia, 600 visa eligibility, 600 visa benefits, 600 visa application process, visit Australia, tourist visa Australia"
    >
      <>
        <Row className="m-5">
          <Col lg={6} md={6} sm={12}>
            <img
              src={visa_600}
              alt="skilledindependent-visa-189"
              className="img-fluid subclass-189-image"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <h2 className="subclass-189-h1">
              Visitor Visa
              <span className="subclass-189-h1-span">(Subclass 600)</span>
            </h2>

            <p className="subclass-189-p">
              Are you planning to visit Australia for tourism, business, or to
              see family and friends?
              <a href="#" className="subclass-189-a">
                Start your journey by applying for your Visa 600 online today!
              </a>
              This visa, known as the Visitor visa (Subclass 600), is designed
              for people who want to visit Australia temporarily. It offers them
              an opportunity to explore Australia, attend business meetings, or
              visit loved ones.
            </p>
            <Button href="/contact" className="mt-3 contact-us-call-button">
              Call Now
            </Button>
          </Col>
        </Row>

        {/* //tab section */}
        <div className="row m-5 ">
          <div className="col-12">
            <div className="choose-us-title-view">
              <h2 className="choose-us-title">
                Visitor Visa
                <b className="span-text">(Subclass 600)</b> Overview
              </h2>
            </div>
            <p>
              The <b>Subclass 600 Visitor Visa</b> allows people to visit
              Australia for tourism, business visitor activities, or to visit
              family and friends. This visa offers flexibility in terms of the
              length of stay and is suitable for short-term visits.
            </p>
          </div>
          <div className="col-12 ">
            <ul className="nav nav-tabs custom-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active custom-nav-link"
                  id="success-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#success"
                  type="button"
                  role="tab"
                  aria-controls="success"
                  aria-selected="true"
                >
                  <i className="fas fa-clipboard-list fa-2x"></i>
                  <br />
                  Eligibility Criteria
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="permanent-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#permanent"
                  type="button"
                  role="tab"
                  aria-controls="permanent"
                  aria-selected="false"
                >
                  <i className="fas fa-check-circle fa-2x"></i>
                  <br />
                  Why Visa 600?
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-nav-link"
                  id="priority-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#priority"
                  type="button"
                  role="tab"
                  aria-controls="priority"
                  aria-selected="false"
                >
                  <i className="fas fa-gift fa-2x"></i>
                  <br />
                  Visa 600 benefits
                </button>
              </li>
            </ul>
            <div className="tab-content custom-tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active custom-tab-pane"
                id="success"
                role="tabpanel"
                aria-labelledby="success-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>Eligibility Criteria for the Subclass 600 Visa</h3>
                    <p>
                      To be eligible for the Subclass 600 visa, applicants must
                      meet the following criteria:
                    </p>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Genuine Visitor:</strong>
                          You must genuinely intend to stay temporarily in
                          Australia for tourism, business visitor activities, or
                          to visit family and friends.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Sufficient Funds:</strong>
                          You must have sufficient funds to support yourself
                          during your stay in Australia.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Health and Character Requirements:</strong>
                          You must meet Australian health and character
                          requirements. This typically includes undergoing
                          health examinations and providing police certificates
                          if requested.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Health Insurance:</strong>
                          It is advisable to have health insurance for the
                          duration of your stay in Australia to cover any
                          unforeseen medical expenses.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Good Character:</strong>
                          You must be of good character to enter Australia,
                          which includes not having a substantial criminal
                          record.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="permanent"
                role="tabpanel"
                aria-labelledby="permanent-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>Why Choose the Subclass 600 Visitor Visa?</h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Flexibility for Various Purposes:</strong>
                          The Subclass 600 visa caters to different visit
                          purposes, including tourism, business, and family
                          visits.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Multiple Streams Available:</strong>
                          Offers several streams, such as Tourist, Business
                          Visitor, Sponsored Family, and Approved Destination
                          Status (ADS) streams, to meet various visitor needs.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Short-Term and Long-Term Stays:</strong>
                          Allows stays of up to 3, 6, or 12 months, providing
                          flexibility for different visit durations.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Multiple Entry Options:</strong>
                          Offers single or multiple entries, enabling you to
                          visit Australia more than once during the visa's
                          validity.
                        </li>
                        <li className="subclass-189-li">
                          <strong>No Age Limit:</strong>
                          Available to applicants of all ages, making it
                          accessible for family members of different
                          generations.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade custom-tab-pane"
                id="priority"
                role="tabpanel"
                aria-labelledby="priority-tab"
              >
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <h3>Key Benefits of the Subclass 600 Visitor Visa</h3>
                    <p className="mt-4 subclass-189-page-font">
                      <ul>
                        <li className="subclass-189-li">
                          <strong>Tourism:</strong>
                          Explore Australia’s diverse landscapes, cities, and
                          attractions, enhancing your travel experience.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Business Activities:</strong>
                          Participate in business visitor activities, including
                          attending conferences, negotiations, or exploratory
                          business visits.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Visit Family and Friends:</strong>
                          Spend time with family and friends living in
                          Australia, strengthening personal relationships.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Flexible Stay Options:</strong>
                          Choose between different stay durations (3, 6, or 12
                          months) to suit your visit plans.
                        </li>
                        <li className="subclass-189-li">
                          <strong>Single or Multiple Entry:</strong>
                          Opt for single entry or multiple entries to Australia
                          during the visa’s validity period.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact" className="btn btn-enquire m-2">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h3 className="subclass-189-pt-h1">Application Process</h3>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Identify the stream that best fits your intended visit purpose
                  (Tourist, Business Visitor, Sponsored Family, or ADS) and
                  ensure you meet the eligibility criteria.
                </li>
                <li className="subclass-189-li">
                  Passport and personal identification documents.
                </li>
                <li className="subclass-189-li">
                  Bank statements or financial documents showing sufficient
                  funds for your stay.
                </li>
                <li className="subclass-189-li">
                  Documents supporting your purpose of visit (e.g., itinerary,
                  invitation letter, business meeting details).
                </li>
                <li className="subclass-189-li">
                  Evidence of health insurance coverage for the duration of your
                  stay.
                </li>
                <li className="subclass-189-li">
                  Health examination results and police certificates if
                  requested.
                </li>
                <li className="subclass-189-li">
                  The Department of Home Affairs will process your application.
                  Processing times can vary but typically take several weeks.
                  Check the current processing times on the Department of Home
                  Affairs website.
                </li>
              </ul>
            </p>

            {/* .......point test............. */}
            <h3 className="subclass-189-pt-h1">Points Test</h3>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  Age: 25-32 years (30 points), 18-24 & 33-39 years (25 points),
                  etc.
                </li>
                <li className="subclass-189-li">
                  English Language Ability: Superior (20 points), Proficient (10
                  points).
                </li>
                <li className="subclass-189-li">
                  Skilled Employment: In Australia or overseas, up to 20 points
                  depending on years of experience.
                </li>
                <li className="subclass-189-li">
                  Educational Qualifications: Doctorate (20 points),
                  Bachelor’s/Master’s (15 points),Diploma or trade
                  qualification: 10 points
                </li>
                <li className="subclass-189-li">
                  Other Factors: Australian study requirement, specialist
                  education qualification, accredited community language, etc.
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Related Visas</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="/electronic-travel-authority-visa-601"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 601 Electronic Travel Authority
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/evisitor-visa-651"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subclass 651 eVisitor
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="
/skilled-graduate-485-visa"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Temporary Graduate visa subclass 485
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="/temporary-skills-shortage-visa-482"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Temporary Skill Shortage visa Subclass 482
                    </a>
                  </li>

                  <li className="subclass-189-li-a">
                    <a
                      href="/skilled-employer-sponsored-regional-494-visa
"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Employer Sponsored Regional (Provisional) visa 494
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>

        {/* ................visa qualify............ */}
        <Row lg={12} className="subclass-189-vq-row">
          <h3 className="mt-5">Do you think you qualify for this visa?</h3>
          <p>
            Contact us if you would like to know more about this visa or need
            immigration advice.
          </p>
          <div className="mb-5">
            <Button
              href="/contact
"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Contact
            </Button>
            <Button
              href="/appointment"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Book A Consultation
            </Button>
            <Button
              href="/initial-assessment-consultation"
              className="subclass-189-vq-button  subclass-189-vq-btn-margin"
            >
              Our Prices
            </Button>
          </div>
        </Row>

        <Row>
          <Col>
            <div className="row m-5">
              <h1 className="home-h1">Streams of the Subclass 600 Visa</h1>
              <div className="col-lg-6 col-md-12">
                <h5 className="subclass-189-pt-h1">Tourist Stream:</h5>
                <p className="subclass-189-p subclass-189-pt-col">
                  <ul>
                    <li className="subclass-189-li">
                      For people visiting Australia for holidays, recreation, or
                      to visit family and friends.
                    </li>
                    <li className="subclass-189-li">
                      Can be applied for from inside or outside Australia.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="col-lg-6 col-md-12">
                <h5 className="subclass-189-pt-h1">Business Visitor Stream:</h5>
                <p className="subclass-189-p subclass-189-pt-col">
                  <ul>
                    <li className="subclass-189-li">
                      For business people making short business visits for
                      reasons such as attending a conference, negotiating, or
                      exploring business opportunities.
                    </li>
                    <li className="subclass-189-li">
                      Must be applied for from outside Australia.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="col-lg-6 col-md-12">
                <h5 className="subclass-189-pt-h1">Sponsored Family Stream:</h5>
                <p className="subclass-189-p subclass-189-pt-col">
                  <ul>
                    <li className="subclass-189-li">
                      For people visiting family members in Australia. A family
                      member in Australia must sponsor you and provide a bond.
                    </li>
                    <li className="subclass-189-li">
                      Must be applied for from outside Australia.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="col-lg-6 col-md-12">
                <h5 className="subclass-189-pt-h1">
                  Approved Destination Status (ADS) Stream:
                </h5>
                <p className="subclass-189-p subclass-189-pt-col">
                  <ul>
                    <li className="subclass-189-li">
                      For people from the People’s Republic of China traveling
                      in an organized tour group.
                    </li>
                    <li className="subclass-189-li">
                      Must be applied for from outside Australia.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="m-5">
          <Col lg={9} md={8} sm={12}>
            <h5 className="subclass-189-pt-h1">
              Conditions and Obligations for Subclass 600 Visitor Visa
            </h5>

            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Temporary Stay:</strong>
                  The Subclass 600 visa allows for a temporary stay in
                  Australia, typically up to 12 months, depending on the purpose
                  of the visit.
                </li>
                <li className="subclass-189-li">
                  <strong>Purpose of Visit:</strong>
                  Visa holders must adhere to the purpose of their visit,
                  whether it be tourism, business, or visiting family and
                  friends.
                </li>
                <li className="subclass-189-li">
                  <strong>Work Restrictions: </strong>
                  Visa holders are generally not permitted to work in Australia
                  while on this visa. Engaging in short-term, unpaid, voluntary
                  work may be allowed under specific conditions.
                </li>
                <li className="subclass-189-li">
                  <strong>Health Insurance: </strong>
                  It is advisable for visa holders to maintain adequate health
                  insurance coverage for the duration of their stay in
                  Australia.
                </li>
                <li className="subclass-189-li">
                  <strong>Compliance with Laws:</strong>
                  Visa holders must comply with all Australian laws and visa
                  conditions during their stay.
                </li>
                <li className="subclass-189-li">
                  <strong>No Ongoing Employment:</strong>
                  Visa holders are not allowed to engage in any ongoing
                  employment while in Australia.
                </li>
                <li className="subclass-189-li">
                  <strong>No Study Beyond 3 Months:</strong>
                  Visa holders can engage in short-term study for up to three
                  months but cannot undertake longer courses without a different
                  visa.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">
              Express Your Interest in the Subclass 600 Visitor Visa
            </h3>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>Purpose of Visit:</strong>
                  Identify whether your visit is for tourism, business, or
                  visiting family and friends.
                </li>
                <li className="subclass-189-li">
                  <strong>Complete Application Form:</strong>
                  Fill out the necessary forms with detailed travel plans and
                  financial information.
                </li>
                <li className="subclass-189-li">
                  <strong>Documentation:</strong>
                  Gather and submit all required documents, ensuring they are
                  accurate and up-to-date.
                </li>
                <li className="subclass-189-li">
                  <strong>Submit Application:</strong>
                  Send your completed application for review.
                </li>
              </ul>
            </p>

            <h3 className="subclass-189-pt-h1">
              English Language Requirements
            </h3>
            <p>
              To meet the English language requirement, you must demonstrate
              competent English. This can be done through:
            </p>
            <p className="subclass-189-p subclass-189-pt-col">
              <ul>
                <li className="subclass-189-li">
                  <strong>IELTS:</strong>A minimum score of 6 in each component
                  (Listening, Reading, Writing, and Speaking).
                </li>
                <li className="subclass-189-li">
                  <strong>TOEFL iBT:</strong>A minimum score of 12 in Listening,
                  13 in Reading, 21 in Writing, and 18 in Speaking.
                </li>
                <li className="subclass-189-li">
                  <strong>PTE Academic:</strong>A minimum score of 50 in each
                  component (Listening, Reading, Writing, and Speaking).
                </li>
              </ul>
            </p>
          </Col>
          {/* ..........related visa card ............... */}
          <Col lg={3} md={4} sm={12}>
            <div className="subclass-189-rv-card-shadow">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Google Adds</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>

            <div className="subclass-189-rv-card-shadow mt-5">
              <div className="subclass-189-rv-card-head">
                <h5 className="subclass-189-rv-card-h">Latest Post</h5>
              </div>
              <p className="subclass-189-rv-card-p">
                <ul>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-190"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Nominated (Subclass 190)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-491"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skilled Work Regional (Subclass 491)
                    </a>
                  </li>
                  <li className="subclass-189-li-a">
                    <a
                      href="https://www.homeaffairs.gov.au/visa-subclass-191"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permanent Residence Regional (Subclass 191)
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </>
      {/* faqs section */}
      <div className="row testimonial-background  visa_question-acc">
        <div className="col-lg-12 cl-sm-12">
          <div className="testimonial-heading">
            {/* <h1 className="home-h1">Success Stories</h1> */}
            <h4 className="about-us-h4">
              Frequently Asked Questions (FAQs) about the Subclass 600 Visitor
              Visa
            </h4>
          </div>

          <>
            <Row>
              <Col lg={12} className="">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      What is the Subclass 600 Visitor Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      The Subclass 600 Visitor Visa allows you to visit
                      Australia for tourism, business, or to visit family and
                      friends.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      What are the eligibility requirements for the Subclass 600
                      Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Eligibility requirements include having sufficient funds,
                      a valid reason for visiting, meeting health and character
                      requirements, and having a genuine intention to visit
                      temporarily.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      How long can I stay in Australia with the Subclass 600
                      Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      The visa can be granted for stays of up to three, six, or
                      twelve months, depending on the purpose of your visit and
                      your personal circumstances.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can I work in Australia on the Subclass 600 Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      No, the Subclass 600 Visa does not allow you to work in
                      Australia. It is intended for tourism, business visits, or
                      visiting family and friends.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How do I apply for the Subclass 600 Visitor Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      You can apply online through the Australian immigration
                      website by filling out the necessary forms and submitting
                      required documentation.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What documents are required for the Subclass 600 Visa
                      application?
                    </Accordion.Header>
                    <Accordion.Body>
                      Required documents typically include a valid passport,
                      proof of sufficient funds, travel itinerary, and evidence
                      of ties to your home country.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      How much does the Subclass 600 Visa cost?
                    </Accordion.Header>
                    <Accordion.Body>
                      The cost varies depending on the type of visitor visa and
                      the duration of stay. You can check the current fees on
                      the Australian immigration website.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      How long does it take to process the Subclass 600 Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      Processing times vary but generally range from several
                      weeks to a few months. It's recommended to apply well in
                      advance of your intended travel date.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      Can I extend my stay in Australia on the Subclass 600
                      Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      In some cases, you may be able to apply for an extension
                      of your stay while in Australia. You will need to meet
                      certain criteria and apply before your current visa
                      expires.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      What are the health and character requirements for the
                      Subclass 600 Visa?
                    </Accordion.Header>
                    <Accordion.Body>
                      You must meet Australia’s health and character
                      requirements, which may include providing a health
                      examination and police clearance certificates.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </>
        </div>
      </div>
    </Layout>
  );
};

export default VisitorVisa_600;
